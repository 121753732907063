import { SelectOptions } from 'core/types/SelectOptions';
import { WebTranslateKey } from 'i18n/types';

export enum ParkingType {
  Motorway = 'motorway',
  Garage = 'garage',
  Lot = 'parking-lot',
  Driveway = 'driveway',
  Street = 'street',
  Underground = 'underground',
}

export const parkingTypes = Object.values(ParkingType);

export const parkingTypesLabels: Record<ParkingType, WebTranslateKey> = {
  [ParkingType.Driveway]: 'chargepoints.parking-types.driveway',
  [ParkingType.Garage]: 'chargepoints.parking-types.garage',
  [ParkingType.Lot]: 'chargepoints.parking-types.lot',
  [ParkingType.Motorway]: 'chargepoints.parking-types.motorway',
  [ParkingType.Street]: 'chargepoints.parking-types.street',
  [ParkingType.Underground]: 'chargepoints.parking-types.underground',
};

export const parkingTypesSelectOptions: SelectOptions[] = parkingTypes.map(
  (type) => ({ label: parkingTypesLabels[type], value: type }),
);
