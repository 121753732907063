import { AuthExceptions, AuthFetches, AuthServices } from '..';

const reAuthorizeUser = () => {
  AuthServices.TokenService.removeSession();
  window.location.href = '/login';
};

export async function authHeader(
  token?: string,
): Promise<Record<string, string>> {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }

  try {
    const accessToken = AuthServices.TokenService.accessToken();
    return { Authorization: `Bearer ${accessToken}` };
  } catch (error) {
    if (error instanceof AuthExceptions.AccessTokenExpiredException) {
      try {
        const session = await AuthFetches.fetchAuthTokenRefresh();
        AuthServices.TokenService.setSession(session);

        return authHeader();
      } catch (error) {
        reAuthorizeUser();
      }
    } else if (
      error instanceof AuthExceptions.SessionNotFoundException &&
      window.location.pathname !== '/login'
    ) {
      reAuthorizeUser();
    }

    throw error;
  }
}
