import { useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { GenerateReportModal } from '../GenerateReportModal';

import style from './style.module.css';

export const GenerateReportSection = () => {
  const { t } = useTranslation();

  const [isGeneratePopupOpen, setIsGeneratePopupOpen] = useState(false);

  return (
    <>
      <div className={style.section}>
        <Row justify="space-between" align="middle" className={style.header}>
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t('reports.titles.generate-report')}
            </Typography.Title>
          </Col>

          <Col>
            <Button type="primary" onClick={() => setIsGeneratePopupOpen(true)}>
              {t('common.actions.create')}
            </Button>
          </Col>
        </Row>
        <Typography.Paragraph
          className={style.description}
          style={{ margin: 0 }}
        >
          {t('reports.text.generating-report')}
        </Typography.Paragraph>
      </div>
      <GenerateReportModal
        isOpen={isGeneratePopupOpen}
        onCancel={() => setIsGeneratePopupOpen(false)}
      />
    </>
  );
};
