import { ReportStatus, ReportTaskPeriod, ReportType } from 'features/reports';
import { WebTranslateKey } from 'i18n/types';

export const reportTypeOptions: {
  translateKey: WebTranslateKey;
  value: string;
}[] = [
  {
    translateKey: 'reports.options.transactions',
    value: 'partner.transactions',
  },
  {
    translateKey: 'reports.options.financial',
    value: 'partner.financial',
  },
];

export const reportPeriodLabels: Record<ReportTaskPeriod, WebTranslateKey> = {
  d: 'reports.options.daily',
  m: 'reports.options.monthly',
};

export const reportPeriodOptions: {
  translateKey: WebTranslateKey;
  value: ReportTaskPeriod;
}[] = [
  {
    translateKey: 'reports.options.daily',
    value: 'd',
  },
  {
    translateKey: 'reports.options.monthly',
    value: 'm',
  },
];

export const reportTypeLabels: Record<ReportType, WebTranslateKey> = {
  'partner.financial': 'reports.options.transactions',
  'partner.transactions': 'reports.options.financial',
};

export const REPORT_STATUS: Record<
  ReportStatus,
  {
    key: ReportStatus;
    translateKey: WebTranslateKey;
    color: string;
  }
> = {
  preparing: {
    key: 'preparing',
    translateKey: 'reports.options.preparing',
    color: '#1677ff',
  },
  ok: {
    key: 'ok',
    translateKey: 'reports.options.ok',
    color: '#2a8522',
  },
  error: {
    key: 'error',
    translateKey: 'reports.options.error',
    color: '#ff4d4f',
  },
};
