import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TariffElement } from 'features/partners-tariffs-groups/types';
import {
  Form,
  Typography,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Checkbox,
  Button,
  Select,
} from 'antd';
import { ModalFooter } from 'components/view/ModalFooter';
import { connectorTypesList } from 'features/charge-points/types';
import { connectorsLabels } from 'components/view/ConnectorLabel/connectors-labels.const';
import { weekDays, getWeekDayLabel } from 'core/types/WeekDay';
import cn from 'clsx';
import {
  FormValues,
  toFormValues,
  fromFormValues,
} from 'pages/Pricing/TariffDetails/ElementForm/ElementForm.utils';
import type { Rule } from 'antd/es/form';
import type { Dayjs } from 'dayjs';
import { timeFormatString, dateFormatString } from 'core/utils';
import { maxTariffPrice } from 'core/utils/tariffUtils';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';

import s from './ElementForm.module.css';

const { RangePicker } = DatePicker;

type Props = {
  element: TariffElement;
  currencySymbol: string;
  onSubmit: (val: TariffElement) => void;
  onClose: () => void;
  isReadOnly: boolean;
};

const ElementForm: FC<Props> = ({
  element,
  currencySymbol,
  onClose,
  onSubmit,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<FormValues>();

  const partnerId = usePartnerId();
  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId,
  });

  const initialValues = toFormValues(element);

  const validateTime: Rule = () => ({
    validator(_, value: [Dayjs, Dayjs]) {
      if (value && value[0] && value[1]) {
        if (value[0].isSame(value[1]) || value[0].isAfter(value[1])) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },
  });

  return (
    <Form
      className={s.form}
      form={form}
      initialValues={initialValues}
      onFinish={(values: FormValues) => {
        onSubmit(fromFormValues(values, element.id));
      }}
      layout={'vertical'}
      disabled={isReadOnly}
    >
      <Row gutter={{ md: 24, lg: 16 }} className={s.topBox}>
        <Col lg={12} xs={24}>
          <Typography.Title level={4}>
            {t('pricing.titles.prices')}
          </Typography.Title>
          <Row gutter={{ lg: 24, xl: 16 }} className={s.prices}>
            <Col span={8}>
              <Row>
                <Typography.Title level={5}>
                  {t('pricing.titles.energy')}
                </Typography.Title>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'energy', 'price']}
                  label={`${t('pricing.labels.price')}:`}
                >
                  <InputNumber
                    min={0}
                    max={maxTariffPrice}
                    type={'number'}
                    addonAfter={currencySymbol}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'energy', 'stepSize']}
                  label={`${t('pricing.labels.step-size')}:`}
                  className={s.wide}
                >
                  <InputNumber
                    className={s.wide}
                    min={0}
                    max={maxTariffPrice}
                    type={'number'}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'energy', 'vatPercent']}
                  label={`${t('pricing.labels.vat')}:`}
                >
                  <InputNumber min={0} max={100} addonAfter={'%'} />
                </Form.Item>
              </Row>
            </Col>

            <Col span={8}>
              <Row>
                <Typography.Title level={5}>
                  {t('pricing.titles.time')}
                </Typography.Title>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'time', 'price']}
                  label={`${t('pricing.labels.price')}:`}
                >
                  <InputNumber
                    min={0}
                    max={maxTariffPrice}
                    type={'number'}
                    addonAfter={currencySymbol}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'time', 'stepSize']}
                  label={`${t('pricing.labels.step-size')}:`}
                  className={s.wide}
                >
                  <InputNumber
                    min={0}
                    max={maxTariffPrice}
                    type={'number'}
                    className={s.wide}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'time', 'vatPercent']}
                  label={`${t('pricing.labels.vat')}:`}
                >
                  <InputNumber min={0} max={100} addonAfter={'%'} />
                </Form.Item>
              </Row>
            </Col>

            <Col span={8}>
              <Row>
                <Typography.Title level={5}>
                  {t('pricing.titles.flat')}
                </Typography.Title>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'flat', 'price']}
                  label={`${t('pricing.labels.price')}:`}
                >
                  <InputNumber min={0} addonAfter={currencySymbol} />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'flat', 'stepSize']}
                  label={'Hidden'}
                  style={{ marginBottom: 4, pointerEvents: 'none', opacity: 0 }}
                >
                  <InputNumber disabled />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={['tariffPrices', 'flat', 'vatPercent']}
                  label={`${t('pricing.labels.vat')}:`}
                >
                  <InputNumber min={0} max={100} addonAfter={'%'} />
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col lg={12} xs={24}>
          <Typography.Title level={4}>&nbsp;</Typography.Title>
          <Row>
            <Col span={8} offset={8}>
              <Typography.Title level={5}>
                {t('pricing.titles.valid-time')}
              </Typography.Title>
              <Form.Item
                name={['restriction', 'startDate']}
                label={`${t('pricing.labels.valid-from')}:`}
              >
                <DatePicker
                  format={`${dateFormatString} ${timeFormatString}`}
                  showTime
                  showNow={false}
                />
              </Form.Item>
              <Form.Item
                name={['restriction', 'endDate']}
                label={`${t('pricing.labels.valid-to')}:`}
              >
                <DatePicker
                  format={`${dateFormatString} ${timeFormatString}`}
                  showTime
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className={s.bottomBox}>
        <Row gutter={16} className={cn(s.bottomBox, s.restrictionsRow)}>
          <Col xl={3} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.connector-types')}
              </Typography.Title>
            </Row>
            <Form.Item name={['restriction', 'connectorTypes']}>
              <Checkbox.Group>
                {connectorTypesList.map((type) => (
                  <Row key={type}>
                    <Checkbox value={type}>{connectorsLabels[type]}</Checkbox>
                  </Row>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>

          <Col xl={3} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.days')}
              </Typography.Title>
            </Row>
            <Form.Item name={['restriction', 'daysOfWeek']}>
              <Checkbox.Group>
                {weekDays.map((day) => (
                  <Row key={day}>
                    <Checkbox value={day}>{getWeekDayLabel(day)}</Checkbox>
                  </Row>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>

          <Col xl={6} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.time')}
              </Typography.Title>
            </Row>
            <Form.Item
              name={['restriction', 'time', 'range']}
              rules={[validateTime]}
            >
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="HH:mm"
                picker="time"
                style={{ maxWidth: 240 }}
              />
            </Form.Item>
          </Col>

          <Col xl={3} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.power')}
              </Typography.Title>
            </Row>
            <Row>{t('pricing.labels.min')}</Row>
            <Row>
              <Form.Item name={['restriction', 'minPower']}>
                <InputNumber
                  addonAfter={t('pricing.terms.kw')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </Row>
            <Row>{t('pricing.labels.max')}</Row>
            <Row>
              <Form.Item name={['restriction', 'maxPower']}>
                <InputNumber
                  addonAfter={t('pricing.terms.kw')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </Row>
          </Col>

          <Col xl={3} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.current')}
              </Typography.Title>
            </Row>
            <Row>{t('pricing.labels.min')}</Row>
            <Row>
              <Form.Item name={['restriction', 'minCurrent']}>
                <InputNumber addonAfter={'A'} min={0} max={999999} />
              </Form.Item>
            </Row>
            <Row>{t('pricing.labels.max')}</Row>
            <Row>
              <Form.Item name={['restriction', 'maxCurrent']}>
                <InputNumber addonAfter={'A'} min={0} max={999999} />
              </Form.Item>
            </Row>
          </Col>

          <Col lg={0} md={8} xs={0}></Col>

          <Col xl={3} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.energy')}
              </Typography.Title>
            </Row>
            <Row>{t('pricing.labels.min')}</Row>
            <Row>
              <Form.Item name={['restriction', 'minKwh']}>
                <InputNumber
                  addonAfter={t('pricing.terms.kwh')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </Row>
            <Row>{t('pricing.labels.max')}</Row>
            <Row>
              <Form.Item name={['restriction', 'maxKwh']}>
                <InputNumber
                  addonAfter={t('pricing.terms.kwh')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </Row>
          </Col>

          <Col xl={3} lg={6} md={8} xs={12}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.duration')}
              </Typography.Title>
            </Row>
            <Row>{t('pricing.labels.min')}</Row>
            <Row>
              <Form.Item name={['restriction', 'minDuration']}>
                <InputNumber
                  min={0}
                  addonAfter={t('pricing.labels.min')}
                  max={999999}
                />
              </Form.Item>
            </Row>
            <Row>{t('pricing.labels.max')}</Row>
            <Row>
              <Form.Item name={['restriction', 'maxDuration']}>
                <InputNumber
                  min={0}
                  addonAfter={t('pricing.labels.min')}
                  max={999999}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col lg={12} xs={24}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.chargepoints-groups')}
              </Typography.Title>
            </Row>
            <Row>
              <Form.Item
                name={['restriction', 'cpHashTags']}
                style={{ width: '100%' }}
              >
                <Select
                  mode="tags"
                  placeholder={t('pricing.placeholders.select-groups')}
                  options={(hashtagsQuery.data ?? [])
                    .filter(Boolean)
                    .map((hashtag) => ({
                      label: hashtag,
                      value: hashtag,
                    }))}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col lg={12} xs={24}>
            <Row>
              <Typography.Title level={5}>
                {t('pricing.titles.tag-groups')}
              </Typography.Title>
            </Row>
            <Row>
              <Form.Item
                name={['restriction', 'tagHashTags']}
                style={{ width: '100%' }}
              >
                <Select
                  mode="tags"
                  placeholder={t('pricing.placeholders.select-groups')}
                  options={(hashtagsQuery.data ?? [])
                    .filter(Boolean)
                    .map((hashtag) => ({
                      label: hashtag,
                      value: hashtag,
                    }))}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </div>

      <ModalFooter border>
        <Button type={'primary'} htmlType={'submit'}>
          {t('common.actions.save')}
        </Button>
        <Button onClick={onClose} disabled={false}>
          {t('common.actions.cancel')}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default memo(ElementForm);
