import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchSubpartnersInvitations } from '../fetches/fetchSubpartnersInvitations';
import { SubpartnersInvitations } from '../types';

export const USE_SUBPARTNERS_INVITATIONS_QUERY_KEY =
  'partner.subpartners.invitations';

export function useSubpartnersInvitationsQuery(
  partnerId: string,
  options?: UseQueryOptions<
    SubpartnersInvitations,
    Types.HttpError,
    SubpartnersInvitations,
    any[]
  >,
) {
  const query = useQuery(
    [USE_SUBPARTNERS_INVITATIONS_QUERY_KEY, partnerId],
    () => fetchSubpartnersInvitations(partnerId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
  return {
    ...query,
  };
}
