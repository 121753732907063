import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { AuthFetches, AuthTypes } from '..';

export function useAuthRegisterMutation(
  options?: UseMutationOptions<
    AuthTypes.Responses.AuthRegisterResponse,
    Types.HttpError,
    AuthTypes.Requests.AuthRegisterRequest
  >,
) {
  return useMutation(AuthFetches.fetchAuthRegister, {
    retry: 0,
    ...options,
  });
}
