import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table, Typography } from 'antd';
import { Pagination } from 'components/Pagination';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useGetGeneratedReportsQuery } from 'features/reports';
import { reportPeriodLabels } from 'pages/Reports/helpers/formOptions';

import style from './style.module.css';
import { useReportFilesColumns } from './useReportFilesColumns';

const PAGE_SIZE = 15;

export const ReportFilesSection = () => {
  const { t } = useTranslation();
  const partnerId = usePartnerId();

  const [page, setPage] = useState(1);

  const { data, hasNextPage, hasPrevPage } = useGetGeneratedReportsQuery(
    partnerId,
    {},
    { index: page, size: PAGE_SIZE },
  );

  const columns = useReportFilesColumns();

  const dataSource = (data?.items || []).map((item) => ({
    type: item.type,
    period: item.period ? item.period : '',
    fileId: item.fileId,
    fileName: item.fileId.split('/').at(-1) ?? '',
    date: dayjs(item.reportDate).format('DD/MM/YYYY'),
    status: item.status,
    error: item.error,
    key: item.id,
  }));

  return (
    <div className={style.section}>
      <Row justify="space-between" align="middle" className={style.header}>
        <Col>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {t('reports.titles.report-files')}
          </Typography.Title>
        </Col>
      </Row>
      <Table
        className={style.table}
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <div className={style.pagination}>
        <Pagination
          page={page}
          hasNext={hasNextPage}
          hasPrev={hasPrevPage}
          onChange={(_, index) => setPage(index)}
        >
          <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
        </Pagination>
      </div>
    </div>
  );
};
