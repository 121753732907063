import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { HashtagsSelectProps } from './types';

export const HashtagsSelect = ({
  allHashtags,
  selectedHashtags,
  dispatch,
}: HashtagsSelectProps) => {
  const { t } = useTranslation();

  const onHashtagsChange = (currentHashtags: string[]) => {
    dispatch({
      type: 'SET_HASHTAGS',
      payload: {
        hashtags: currentHashtags,
      },
    });
  };

  return (
    <div className={styles.input}>
      <label htmlFor="hashTags">{t('chargepoints.filter.groups')}:</label>
      <Select
        mode="multiple"
        id="hashTags"
        allowClear
        style={{ width: '250px' }}
        placeholder={t('chargepoints.filter.select-groups')}
        maxTagCount="responsive"
        value={selectedHashtags}
        onChange={onHashtagsChange}
        options={allHashtags.map((hashtag) => ({
          label: hashtag,
          value: hashtag,
        }))}
      />
    </div>
  );
};
