import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import Upload from 'antd/es/upload/Upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/es/upload';
import { PlusOutlined } from '@ant-design/icons';
import { getFileExtension, getImageBase64, randString } from 'core/utils';
import { usePartnerFileUploadMutation } from 'features/files/queries';
import { usePartner } from 'core/providers/PartnerProvider';
import { fetchPartnerFileLink } from 'features/files/fetches';

import { useProfileData } from '../Forms/useProfileUpdate';

import styles from './LogoUpload.module.css';

export const LogoUpload = ({
  onLoadError,
  onLoadSuccess,
}: {
  onLoadSuccess(message?: string): void;
  onLoadError(message?: string): void;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [file, setFile] = useState<UploadFile | null>(null);
  const partner = usePartner();
  const upload = usePartnerFileUploadMutation();
  const [updateProfile] = useProfileData({ partner });

  const { t } = useTranslation();

  useEffect(() => {
    if (partner?.logo) {
      setFile({
        uid: randString(),
        url: partner.logo,
        status: 'done',
        name: 'logo',
      });
    }
  }, [partner]);

  const handlePreviewClose = () => setPreviewOpen(false);

  const handlePreviewOpen = async (file: UploadFile) => {
    if (!file.url && !file.preview && file.originFileObj) {
      file.preview = await getImageBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
  };

  const handleUpload = async ({ file }: UploadRequestOption) => {
    if (!(file instanceof File)) {
      onLoadError('Invalid file');
      return;
    }

    const extension = getFileExtension(file.name);
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file.size > maxSize) {
      onLoadError(t('profile.additional_info.size-error'));
      return;
    }

    const uid = randString();
    const partnerId = partner?.id ?? 'me';

    try {
      const formData = new FormData();
      formData.append('image', file);

      setFile({
        uid,
        url: '',
        status: 'uploading',
        name: file.name,
      });

      const { id: fileId } = await upload.mutateAsync([
        partnerId,
        formData,
        {
          bucketName: 'img',
          path: `logo/`,
          extension,
        },
      ]);

      const fileUrl = await fetchPartnerFileLink(partnerId, fileId);
      setFile({
        uid,
        url: fileUrl,
        status: 'done',
        name: file.name,
      });

      await updateProfile({ logo: fileUrl });

      onLoadSuccess(t('profile.additional_info.result.uploaded'));
    } catch (error) {
      setFile(null);
      onLoadError(t('profile.additional_info.result.upload-error'));
    }
  };

  const handleRemove = async () => {
    setFile(null);
    try {
      await updateProfile({ logo: '' });
      onLoadSuccess(t('profile.additional_info.result.removed'));
    } catch (error) {
      onLoadError(t('profile.additional_info.result.remove-error'));
    }
  };

  return (
    <>
      <div className={styles.label}>
        {t('profile.additional_info.logo-upload')}:
      </div>
      <Upload
        listType="picture-card"
        maxCount={1}
        accept=".png,.jpg,.gif,.svg, .avif"
        customRequest={handleUpload}
        onRemove={handleRemove}
        onPreview={handlePreviewOpen}
        fileList={file ? [file] : []}
      >
        <div>
          <PlusOutlined />
          <div className={styles.buttonTitle}>
            {t('profile.additional_info.upload')}
          </div>
        </div>
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handlePreviewClose}
      >
        <img
          alt={`uploaded image: ${previewTitle}`}
          className={styles.image}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
