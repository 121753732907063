import { Descriptions, Table } from 'antd';
import { Line } from '@ant-design/charts';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'features/transactions/types';
import { FC } from 'react';
import dayjs from 'dayjs';
import { ConnectorType } from 'features/charge-points/types';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import { ResTransactionMetrics } from 'features/transactions/types/responses';

import styles from '../TransactionDetails.module.css';

interface Props {
  transaction: Transaction;
  metrics?: ResTransactionMetrics;
}

export const Details: FC<Props> = ({ transaction, metrics }) => {
  const { t } = useTranslation();

  const transactionColumns = [
    {
      title: t('transactions.columns.connector-id'),
      dataIndex: 'connectorId',
      key: 'connectorId',
    },
    {
      title: t('transactions.columns.connector-type'),
      dataIndex: 'connectorType',
      key: 'connectorType',
      render: (type: ConnectorType) => <ConnectorLabel type={type} />,
    },
    {
      title: t('transactions.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('transactions.columns.device-transaction-id'),
      dataIndex: 'deviceTransactionId',
      key: 'deviceTransactionId',
    },
    {
      title: t('transactions.columns.auth-status'),
      dataIndex: 'authStatus',
      key: 'authStatus',
    },
    {
      title: t('transactions.columns.tag'),
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: t('transactions.columns.start-at'),
      dataIndex: 'startAt',
      key: 'startAt',
      render: (date: string) =>
        date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.stop-at'),
      dataIndex: 'stopAt',
      key: 'stopAt',
      render: (date: string) =>
        date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.energy-wh'),
      dataIndex: 'energyWh',
      key: 'energyWh',
    },
    {
      title: t('transactions.columns.started-by'),
      dataIndex: 'startedBy',
      key: 'startedBy',
    },
    {
      title: t('transactions.columns.stopped-by'),
      dataIndex: 'stoppedBy',
      key: 'stoppedBy',
    },
    {
      title: t('transactions.columns.meter-stop'),
      dataIndex: 'meterStop',
      key: 'meterStop',
    },
  ];

  const tarificationColumns = [
    {
      title: t('transactions.columns.currency'),
      dataIndex: 'details',
      key: '__currency',
      render: (details: Transaction['details']) => details.tariff?.currency,
    },
    {
      title: t('transactions.columns.amount'),
      dataIndex: 'details',
      key: '__amount',
      render: (details: Transaction['details']) =>
        details.tariff?.totalAmount?.amount || 0,
    },
    {
      title: t('transactions.columns.vat-amount'),
      dataIndex: 'details',
      key: '__vatAmount',
      render: (details: Transaction['details']) =>
        details.tariff?.totalAmount?.vatAmount || 0,
    },
  ];

  const tarificationDetailsColumns = [
    {
      title: t('transactions.columns.group'),
      key: 'group',
      dataIndex: 'group',
    },
    {
      title: t('transactions.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: number) => amount || 0,
    },
    {
      title: t('transactions.columns.vat-amount'),
      key: 'vatAmount',
      dataIndex: 'vatAmount',
      render: (amount: number) => amount || 0,
    },
  ];

  const invoicingColumns = [
    {
      title: t('transactions.columns.invoice-number'),
      key: '__invoiceNo',
      dataIndex: 'details',
      render: (details: Transaction['details']) => details.invoicing?.invoiceNo,
    },
    {
      title: t('transactions.columns.currency'),
      key: '__currency',
      dataIndex: 'details',
      render: (details: Transaction['details']) => details.invoicing?.currency,
    },
    {
      title: t('transactions.columns.invoice-amount'),
      key: '__invAmount',
      dataIndex: 'details',
      render: (details: Transaction['details']) =>
        details.invoicing?.invAmount || 0,
    },
    {
      title: t('transactions.columns.invoice-amount-eur'),
      key: '__invAmountEur',
      dataIndex: 'details',
      render: (details: Transaction['details']) =>
        details.invoicing?.invAmountEur || 0,
    },
    {
      title: t('transactions.columns.discount'),
      key: '__discount',
      dataIndex: 'details',
      render: (details: Transaction['details']) =>
        details.invoicing?.discount || 0,
    },
    {
      title: t('transactions.columns.discount-eur'),
      key: '__discountEur',
      dataIndex: 'details',
      render: (details: Transaction['details']) =>
        details.invoicing?.discountEur || 0,
    },
  ];

  return (
    <>
      <div className={styles.transaction_container__block}>
        <Descriptions
          title={t('transactions.titles.transaction-info')}
        ></Descriptions>
        <Table
          size={'small'}
          pagination={false}
          scroll={{ x: 'max-content' }}
          dataSource={[transaction]}
          columns={transactionColumns}
        />
      </div>
      {!!transaction.details?.tariff && (
        <>
          <div className={styles.transaction_container__block}>
            <Descriptions
              title={t('transactions.titles.tariffication-info')}
            ></Descriptions>
            <Table
              size={'small'}
              pagination={false}
              scroll={{ x: 'max-content' }}
              dataSource={[transaction]}
              columns={tarificationColumns}
            />
          </div>
          <div className={styles.transaction_container__block}>
            <Descriptions
              title={t('transactions.titles.tariffication-details')}
            ></Descriptions>
            <Table
              size={'small'}
              pagination={false}
              scroll={{ x: 'max-content' }}
              dataSource={['energy', 'flat', 'time', 'parking'].map((group) => {
                if (!transaction.details?.tariff?.totalGrouped) {
                  return {
                    group,
                    amount: 0,
                    vatAmount: 0,
                  };
                }

                return {
                  group,
                  amount:
                    transaction.details?.tariff?.totalGrouped[group]?.amount ||
                    0,
                  vatAmount:
                    transaction.details?.tariff?.totalGrouped[group]
                      ?.vatAmount || 0,
                };
              })}
              columns={tarificationDetailsColumns}
            />
          </div>
        </>
      )}
      {!!transaction.details.invoicing && (
        <div className={styles.transaction_container__block}>
          <Descriptions
            title={t('transactions.titles.invoicing')}
          ></Descriptions>
          <Table
            size={'small'}
            pagination={false}
            scroll={{ x: 'max-content' }}
            dataSource={[transaction]}
            columns={invoicingColumns}
          />
        </div>
      )}
      {!!metrics?.items?.length && (
        <div className={styles.transaction_container__block}>
          <Descriptions
            title={t('transactions.titles.metrics-charts')}
          ></Descriptions>
          <Line
            data={(metrics?.items ?? [])
              .reduce((accum: Record<string, any>[], current) => {
                const accept = [];

                if (!!current.currentImport) {
                  accept.push({
                    value: current.currentImport,
                    timestamp: current.timestamp,
                    category: 'currentImport',
                  });
                }

                if (!!current.powerActiveImport) {
                  accept.push({
                    value: current.powerActiveImport / 1000,
                    timestamp: current.timestamp,
                    category: 'powerActiveImport',
                  });
                }

                if (!!current.soc) {
                  accept.push({
                    value: current.soc,
                    timestamp: current.timestamp,
                    category: 'soc',
                  });
                }

                return [...accum, ...accept];
              }, [])
              .sort((a, b) => a.timestamp.localeCompare(b.timestamp))}
            xField="timestamp"
            yField="value"
            seriesField="category"
            xAxis={{
              label: { formatter: (v) => dayjs(v).format('HH:mm') },
            }}
            height={400}
          />
        </div>
      )}
    </>
  );
};
