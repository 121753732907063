import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import {
  FetchPaymentHistoryParams,
  PaymentHistory,
} from '../types/PaymentHistory';

export async function fetchClientsPaymentHistory(
  params: FetchPaymentHistoryParams,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<PaymentHistory>(
      `/partners/${params.partnerId}/billing/clients/${params.clientId}/payment-orders/search/query`,
      {
        params,
        headers: { ...header },
      },
    );

    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}

