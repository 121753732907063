import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqPartnerTariffGroupUpdateData } from '../types/requests';
import { ResPartnerTariffGroupUpdate } from '../types/responses';

export async function fetchPartnerTariffGroupUpdate<
  TResponse = ResPartnerTariffGroupUpdate,
  TError = Types.HttpError,
>(
  params: { partnerId?: string; groupId?: string },
  data: ReqPartnerTariffGroupUpdateData,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.put<TResponse>(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}`,
      data,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
