import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { Responses } from '../types';

/**
 * Partner info
 */
export async function fetchPartner<
  TResponse = Responses.ResPartner,
  TError = Types.HttpError,
>(partnerId: string) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}`,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
