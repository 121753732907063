import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';

import { ChargePointsTypes } from '..';

export async function fetchChargePointsUpdate(
  data: ChargePointsTypes.Requests.ReqChargePointsUpdate,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response =
      await Services.apiService.patch<ChargePointsTypes.Responses.ResChargePoint>(
        `partners/${data?.partnerId}/charging/charge-points/${data?.id}`,
        data,
        {
          headers: { ...header },
        },
      );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data);
  }
}
