import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchChargePointConfigs } from '../fetches';
import { Responses } from '../types';

export const USE_CHARGE_POINT_CONFIGS_QUERY_KEY = 'charge.point.configs';

export function useChargePointConfigsQuery(
  partnerId: string,
  chargePointId: string,
  options?: UseQueryOptions<
    Responses.ResChargePointConfigs,
    Types.HttpError,
    Responses.ResChargePointConfigs,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_POINT_CONFIGS_QUERY_KEY, chargePointId],
    () => fetchChargePointConfigs(partnerId, chargePointId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
