import { WebTranslateKey } from 'i18n/types';
import { t, exists } from 'i18next';

export const getSelectOptionsFromRecord = (
  rec: Record<string, string>,
): { value: string; label: string }[] => {
  return Object.entries(rec).map(([key, val]) => {
    let label = val;

    if (exists(val as WebTranslateKey)) {
      label = t(val as WebTranslateKey);
    }

    return {
      value: key,
      label,
    };
  });
};

export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] => {
  return Object.keys(obj).filter((k) => !Number.isNaN(k)) as K[];
};
