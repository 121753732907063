import {
  TariffElement,
  Restriction,
  TariffPrice,
} from 'features/partners-tariffs-groups/types';
import dayjs, { Dayjs } from 'dayjs';
import { hasValue, isDefined, isNotNull } from 'core/utils/typeguards';
import { toTimeString, fromTimeString } from 'core/utils';

export type FormValues = {
  tariffPrices: {
    energy?: TariffPrice;
    time?: TariffPrice;
    flat?: TariffPrice;
  };
  restriction: Omit<Restriction, 'startDate' | 'endDate' | 'time'> & {
    startDate?: Dayjs;
    endDate?: Dayjs;
    time?: {
      range: [Dayjs, Dayjs];
    };
  };
};

export const toFormValues = (element: TariffElement): FormValues => {
  const restriction = element?.restrictions?.[0];

  if (!restriction) {
    return { ...element, restriction: {} };
  }

  return {
    ...element,
    restriction: {
      ...restriction,
      startDate: dayjs(restriction.startDate),
      endDate: dayjs(restriction.endDate),
      time: restriction.time && {
        range: [
          fromTimeString(restriction.time.from),
          fromTimeString(restriction.time.to),
        ],
      },
      tagHashTags: restriction.tagHashTags,
      cpHashTags: restriction.cpHashTags,
    },
  };
};

export const fromFormValues = (
  values: FormValues,
  id: string,
): TariffElement => {
  const { restriction, tariffPrices } = values;

  const nonEmptyTariffPrices = Object.fromEntries(
    Object.entries(tariffPrices).filter(([, value]) => isDefined(value.price)),
  );

  const { flat } = nonEmptyTariffPrices;
  if (flat) {
    delete flat.stepSize;
  }

  const { startDate, endDate, time, cpHashTags, tagHashTags } = restriction;

  let safeTime = undefined;

  if (isDefined(time) && hasValue(time.range)) {
    safeTime = {
      from: toTimeString(time.range[0]),
      to: toTimeString(time.range[1]),
    };
  }

  return {
    id,
    tariffPrices: nonEmptyTariffPrices,
    restrictions: [
      {
        ...restriction,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        time: safeTime,
        cpHashTags,
        tagHashTags,
      },
    ],
  };
};
