import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, message, Row, Spin, Table, Typography } from 'antd';
import {
  RegularReportTask,
  USE_GET_REGULAR_REPORT_TASKS_QUERY_KEY,
  useDeleteRegularReportTaskMutation,
  useGetRegularReportTasksQuery,
} from 'features/reports';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import {
  reportTypeLabels,
  reportPeriodLabels,
} from 'pages/Reports/helpers/formOptions';
import { HttpError } from 'core/types';

import { CreateReportTaskModal } from '../CreateReportTaskModal';

import { useRegularReportsColumns } from './useRegularReportsColumns';
import style from './style.module.css';

export const RegularReportsSection = () => {
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const partnerId = usePartnerId();
  const queryClient = useQueryClient();
  const [messageApi, contextHandler] = message.useMessage();

  const [isCreateTaskPopupOpen, setIsCreateTaskPopupOpen] = useState(false);

  const { data, isLoading } = useGetRegularReportTasksQuery(partnerId);
  const { mutateAsync } = useDeleteRegularReportTaskMutation();

  const showError = (error: HttpError) => {
    messageApi.open({
      type: 'error',
      content: errorT(error?.translationKey, error.message),
    });
  };

  const handleDelete = async (itemId: RegularReportTask['id']) => {
    try {
      await mutateAsync([partnerId, itemId]);
      queryClient.setQueryData(
        [USE_GET_REGULAR_REPORT_TASKS_QUERY_KEY, partnerId],
        (oldData: RegularReportTask[] | undefined) => {
          if (!oldData) return [];
          return oldData.filter((i) => i.id !== itemId);
        },
      );
    } catch (error) {
      showError(error as HttpError);
    }
  };

  const columns = useRegularReportsColumns(handleDelete);

  if (isLoading) return <Spin />;

  return (
    <>
      {contextHandler}
      <div className={style.section}>
        <Row justify="space-between" align="middle" className={style.header}>
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t('reports.titles.regular-reports')}
            </Typography.Title>
          </Col>

          <Col>
            <Button
              type="primary"
              onClick={() => setIsCreateTaskPopupOpen(true)}
            >
              {t('common.actions.create')}
            </Button>
          </Col>
        </Row>
        {data && (
          <Table
            className={style.table}
            size="small"
            columns={columns}
            dataSource={data.map((item) => ({
              ...item,
              key: item.id,
            }))}
            pagination={false}
          />
        )}
      </div>
      <CreateReportTaskModal
        isOpen={isCreateTaskPopupOpen}
        onCancel={() => {
          setIsCreateTaskPopupOpen(false);
        }}
        onSuccess={(newReport) => {
          setIsCreateTaskPopupOpen(false);
          queryClient.setQueryData(
            [USE_GET_REGULAR_REPORT_TASKS_QUERY_KEY, partnerId],
            (oldData: RegularReportTask[] | undefined) => {
              if (!oldData) return [newReport];
              return [...oldData, newReport];
            },
          );
        }}
      />
    </>
  );
};
