import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';

const defaultLocale = 'ru';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.locale(defaultLocale);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const { L: dateFormatString, LT: timeFormatString } =
  dayjs.Ls[defaultLocale].formats;

export const formatDateTimeShort = (
  date: string | number | dayjs.Dayjs | Date,
) => {
  return dayjs(date).format('L LT');
};

export const secsToTime = (seconds: number): string => {
  if (!seconds) {
    return '';
  }

  const duration = dayjs.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes().toString().padStart(2, '0');
  const secondsString = duration.seconds().toString().padStart(2, '0');

  return `${
    hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''
  }${minutes}:${secondsString}`;
};

const timePattern = /([0-9]{1,2}):([0-9]{2})/;

export const fromTimeString = (str: string): Dayjs => {
  const matches = str.match(timePattern);
  if (!matches) {
    throw TypeError(`${str} is not correct time`);
  }
  const [, hours, minutes] = matches;
  return dayjs().set('minutes', +minutes).set('hours', +hours);
};

export const toTimeString = (date: Dayjs) => {
  return date.format('HH:mm');
};
