import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';
import * as Helpers from 'core/helpers';

import { Responses } from '../../partners/types';

/**
 * Get all subpartners of a current partner
 */
export async function fetchSubpartners<
  TResponse = Responses.ResPartner[],
  TError = Types.HttpError,
>(partnerId: string, pagination?: Helpers.RequestPagination) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}/sub-partners`,
      {
        params: { ...Helpers.requestPagination(pagination) },
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
