import { Button, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useChargeTagActivateMutation,
  useChargeTagBlockMutation,
  useChargeTagUnblockMutation,
  USE_CHARGE_TAGS_PARTNER_QUERY_KEY,
} from 'features/charge-tags/queries';
import { ChargeTag } from 'features/charge-tags/types';
import { useQueryClient } from '@tanstack/react-query';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { HttpError } from 'core/types';
import { ColumnsType } from 'antd/es/table';

import { TagsTableColumns } from '../types';

export const useTagsColumns = ({
  messageApi,
  partnerId,
  openAssigningModal,
}: TagsTableColumns): ColumnsType<ChargeTag> => {
  const [activating, setActivating] = useState<string[]>([]);
  const [blockingUnblocking, setBlockingUnblocking] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const activateMutation = useChargeTagActivateMutation();
  const blockMutation = useChargeTagBlockMutation();
  const unblockMutation = useChargeTagUnblockMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const activate = (tagId: string) => {
    setActivating([...activating, tagId]);

    activateMutation
      .mutateAsync({ tagId, partnerId })
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_TAGS_PARTNER_QUERY_KEY]);
      })
      .catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message,
        });
      })
      .finally(() => {
        setActivating(activating.filter((id) => id !== tagId));
      });
  };

  const blockUnblock = (type: 'block' | 'unblock', tagId: string) => {
    setBlockingUnblocking([...blockingUnblocking, tagId]);

    let method = blockMutation;

    if (type === 'unblock') {
      method = unblockMutation;
    }

    method
      .mutateAsync({ tagId, partnerId })
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_TAGS_PARTNER_QUERY_KEY]);
      })
      .catch((e: HttpError) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      })
      .finally(() => {
        setBlockingUnblocking(blockingUnblocking.filter((id) => id !== tagId));
      });
  };

  return [
    {
      title: t('rfid_tags.columns.card-id'),
      dataIndex: 'tag',
      key: 'tag',
      render: (tag: string, record: ChargeTag) => (
        <Link to={`${record.id}`}>
          <Typography.Paragraph
            copyable
            style={{ display: 'inline-block', marginBottom: 0 }}
          >
            {tag}
          </Typography.Paragraph>
        </Link>
      ),
    },
    {
      title: t('rfid_tags.columns.client-id'),
      dataIndex: 'clientId',
      key: 'clientId',
      render: (_, transaction: ChargeTag) => {
        const { clientId, clientPhone, clientName } = transaction;

        const renderedValue = clientName
          ? `${clientPhone} (${clientName})`
          : clientPhone;

        if (!clientId) return '-';

        return <Link to={`/clients/${clientId}`}>{renderedValue}</Link>;
      },
    },
    {
      title: t('rfid_tags.columns.expire-at'),
      dataIndex: 'expireAt',
      key: 'expire-at',
    },
    {
      title: t('rfid_tags.columns.groups'),
      dataIndex: 'hashTags',
      key: 'hashTags',
      render: (hashTags: string[]) => {
        return (hashTags ?? []).join(', ');
      },
    },
    {
      title: t('rfid_tags.columns.status'),
      width: '50px',
      align: 'center' as const,
      dataIndex: 'activatedAt',
      key: 'activatedAt',
      render: (activatedAt: string, record: ChargeTag) => {
        if (activatedAt) {
          return t('rfid_tags.text.active');
        }

        return (
          <Button
            type="link"
            onClick={() => activate(record.id)}
            disabled={activating.includes(record.id)}
          >
            {t('rfid_tags.actions.activate')}
          </Button>
        );
      },
    },
    {
      title: '',
      width: '50px',
      align: 'center' as const,
      dataIndex: 'id',
      key: 'assign',
      render: (id: string, record: ChargeTag) => {
        return (
          <Button
            type="default"
            onClick={() => openAssigningModal(id)}
            disabled={!!record.lockedAt || !record.activatedAt}
          >
            {t('rfid_tags.actions.assign-client')}
          </Button>
        );
      },
    },
    {
      title: '',
      width: '50px',
      align: 'center' as const,
      dataIndex: 'lockedAt',
      key: 'lockedAt',
      render: (lockedAt: string, record: ChargeTag) => {
        return (
          <Button
            type="default"
            onClick={() =>
              blockUnblock(lockedAt ? 'unblock' : 'block', record.id)
            }
            disabled={
              !record.activatedAt || blockingUnblocking.includes(record.id)
            }
          >
            {lockedAt
              ? t('rfid_tags.actions.unblock')
              : t('rfid_tags.actions.block')}
          </Button>
        );
      },
    },
  ];
};
