export const Logo = ({ width = 200 }: { width?: number }) => {
  let logoSrc = '';
  switch (process.env.REACT_APP_ENV) {
    case 'kzprod':
      logoSrc = '/logokz.png';
      break;
    case 'rostelekom':
      logoSrc = '/logo_rostelekom.svg';
      break;
    default:
      logoSrc = '/logo.png';
  }
  return <img src={logoSrc} width={width} />;
};

