import { RcFile } from 'antd/es/upload';

export const getImageBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function getImageDimensions(
  file: File,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };

        img.src = e.target.result;
      } else {
        reject(new Error('Failed to read image file.'));
      }
    };

    reader.readAsDataURL(file);
  });
}

export function getFileExtension(filename: string) {
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
}

