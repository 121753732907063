import { Input, InputProps } from 'antd';

type PhoneInputProps = InputProps & {};

export const PhoneInput = ({ ...inputProps }: PhoneInputProps) => {
  return (
    <Input
      {...inputProps}
      onInput={(e) => {
        e.currentTarget.value = e.currentTarget.value.replace(/\D+/g, '');
      }}
      allowClear
    />
  );
};
