import { useTranslation } from 'react-i18next';
import { Descriptions } from 'antd';
import { Transaction } from 'features/transactions/types';
import { FC } from 'react';

interface Props {
  transaction: Transaction;
}

export const Service: FC<Props> = ({ transaction }) => {
  const { t } = useTranslation();

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label={t('transactions.labels.id')}>
          {transaction.id}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.chargepoint-id')}>
          {transaction.cpId}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('transactions.labels.chargepoint-device-id')}
        >
          {transaction.cpDeviceId}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.client-id')}>
          {transaction.clientId}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.tag-id')}>
          {transaction.tagId}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.partner-id')}>
          {transaction.partnerId}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.invoice-id')}>
          {transaction.details.invoicing?.invoiceId}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.tariff-id')}>
          {transaction.details.tariff?.tariffId}
        </Descriptions.Item>
        <Descriptions.Item label={t('transactions.labels.tariff-group-id')}>
          {transaction.details.tariff?.tariffGroupId}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
