import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';

export async function fetchChargePointsDiagnosticFile<
  TResponse = {},
  TError = Types.HttpError,
>(partnerId: string, cpId: string, from: string, to: string) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `partners/${partnerId}/charging/charge-points/${cpId}/diagnostic`,
      {},
      {
        headers: { ...header },
        params: { from, to },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
