import { ExclamationCircleFilled } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, message, Modal } from 'antd';
import {
  useTransactionStopMutation,
  USE_TRANSACTIONS_SEARCH_QUERY_KEY,
  USE_TRANSACTION_QUERY_KEY,
} from 'features/transactions/queries';
import { Transaction } from 'features/transactions/types';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

export interface TransactionActionsProps extends ButtonProps {
  transaction: Transaction;
  onSuccess?: () => void;
}

export function TransactionActions({
  transaction,
  onSuccess,
  ...props
}: TransactionActionsProps) {
  const queryClient = useQueryClient();
  const partnerId = usePartnerId();
  const [messageApi, contextHandler] = message.useMessage();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const stop = useTransactionStopMutation({
    onError: (error) => {
      messageApi.open({
        type: 'error',
        content: errorT(error?.translationKey, error.message),
      });
    },
    onSuccess: (_, values) => {
      // FIXME
      setTimeout(() => {
        queryClient.refetchQueries([USE_TRANSACTIONS_SEARCH_QUERY_KEY]);
        queryClient.refetchQueries([USE_TRANSACTION_QUERY_KEY, values[1]]);
      }, 1000);
      onSuccess?.();
    },
  });

  const handleStop = () => {
    Modal.confirm({
      title: t('transactions.stop_transaction_modal.title'),
      icon: <ExclamationCircleFilled />,
      cancelText: t('common.actions.cancel'),
      async onOk() {
        await stop.mutateAsync([partnerId, transaction.cpId, transaction.id]);
      },
    });
  };

  return (
    <div>
      {contextHandler}
      <Button
        disabled={transaction.status !== 'active'}
        onClick={handleStop}
        {...props}
      >
        {t('common.actions.stop')}
      </Button>
    </div>
  );
}
