import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { Padding } from 'components/Padding';
import { usePartnerId } from 'core/providers/PartnerProvider';
import dayjs, { Dayjs } from 'dayjs';
import {
  useChargeTagUpdateMutation,
  useChargeTagPartnerQuery,
  USE_CHARGE_TAGS_SEARCH_QUERY_KEY,
} from 'features/charge-tags/queries';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const TagsDetails = () => {
  const { tagId = '' } = useParams<{ tagId: string }>();
  const [form] = Form.useForm();
  const partnerId = usePartnerId();
  const queryClient = useQueryClient();
  const update = useChargeTagUpdateMutation();
  const tagQuery = useChargeTagPartnerQuery([partnerId, tagId], {
    enabled: !!partnerId && !!tagId,
  });
  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId,
  });

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  if (tagQuery.isLoading || hashtagsQuery.isLoading) {
    return <Spin />;
  }

  if (tagQuery.isError) {
    return <Padding>{t('common.status.error')}...</Padding>;
  }

  const handleUpdate = (values: { expireAt: Dayjs; hashTags: string[] }) => {
    update
      .mutateAsync([
        partnerId,
        tagId,
        {
          expireAt: values.expireAt.toISOString(),
          hashTags: values.hashTags,
        },
      ])
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_TAGS_SEARCH_QUERY_KEY]);
      });
  };

  return (
    <>
      <Padding bottomBorder>
        <Typography.Title level={4}>{tagQuery.data?.tag}</Typography.Title>
        <Space>
          <Typography.Text>{t('rfid_tags.labels.id')}: </Typography.Text>
          <Typography.Text type="secondary">{tagId}</Typography.Text>
        </Space>
      </Padding>
      <Padding>
        {' '}
        <Form
          form={form}
          layout={'vertical'}
          name="tags-details"
          initialValues={{
            expireAt: dayjs(tagQuery.data?.expireAt),
            hashTags: tagQuery.data?.hashTags ?? [],
          }}
          onFinish={handleUpdate}
        >
          <Form.Item
            name="expireAt"
            label={`${t('rfid_tags.labels.expire-date')}:`}
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="hashTags" label={`${t('rfid_tags.labels.groups')}:`}>
            <Select
              mode="tags"
              placeholder={t('rfid_tags.placeholders.select-groups')}
              options={(hashtagsQuery.data ?? [])
                .filter(Boolean)
                .map((hashtag) => ({
                  label: hashtag,
                  value: hashtag,
                }))}
            />
          </Form.Item>
          {!!update.error && (
            <Form.Item>
              <Alert
                type={'error'}
                description={errorT(
                  update.error?.translationKey,
                  update.error?.message,
                )}
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={update.isLoading}>
              {t('common.actions.save-changes')}
            </Button>
          </Form.Item>
        </Form>
      </Padding>
    </>
  );
};
