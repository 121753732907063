import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { AuthFetches, AuthTypes } from '..';

export function useAuthLoginMutation(
  options?: UseMutationOptions<
    AuthTypes.Responses.AuthLoginResponse,
    Types.HttpError,
    AuthTypes.Requests.AuthLoginRequest
  >,
) {
  return useMutation(AuthFetches.fetchAuthLogin, {
    retry: 0,
    ...options,
  });
}
