import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqPartnersSubscriptionCreateData } from '../types/requests';
import { ResPartnersSubscriptions } from '../types/responses';

export async function fetchPartnersSubscriptionCreate<
  TResponse = ResPartnersSubscriptions,
  TError = Types.HttpError,
>({
  params,
  data,
}: {
  params: { partnerId: string };
  data: ReqPartnersSubscriptionCreateData;
}) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `/partners/${params.partnerId}/subscriptions`,
      data,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
