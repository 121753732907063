import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table, Typography, message } from 'antd';
import { Padding } from 'components/Padding';
import { Pagination } from 'components/Pagination';
import { usePartnerId } from 'core/providers/PartnerProvider';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BalancingProfile,
  USE_PARTNER_BALANCING_PROFILES_QUERY_KEY,
  useLoadBalancingProfileActivateMutation,
  useLoadBalancingProfileDeactivateMutation,
  useLoadBalancingProfileDeleteMutation,
  useLoadBalancingProfilesSearchQuery,
} from 'features/balancing-profiles';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

const PAGE_SIZE = 10;

export const BalancingProfiles = () => {
  const [page, setPage] = useState(1);
  const [messageApi, contextHandler] = message.useMessage();
  const [activating, setActivating] = useState<string[]>([]);
  const [deleting, setDeleting] = useState<string[]>([]);
  const partnerId = usePartnerId();
  const queryClient = useQueryClient();
  const query = useLoadBalancingProfilesSearchQuery([partnerId]);
  const activateMutation = useLoadBalancingProfileActivateMutation();
  const deactivateMutation = useLoadBalancingProfileDeactivateMutation();
  const deleteMutation = useLoadBalancingProfileDeleteMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const activateOrDeactivate = (
    profileId: string,
    type: 'activate' | 'deactivate',
  ) => {
    setActivating([...activating, profileId]);

    let method = type === 'activate' ? activateMutation : deactivateMutation;

    method
      .mutateAsync([partnerId, profileId])
      .then(() => {
        queryClient.invalidateQueries([
          USE_PARTNER_BALANCING_PROFILES_QUERY_KEY,
        ]);
      })
      .catch((e) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      })
      .finally(() => {
        setActivating(activating.filter((id) => id !== profileId));
      });
  };

  const confirmDelete = (profileId: string) => {
    setDeleting([...activating, profileId]);

    deleteMutation
      .mutateAsync([partnerId, profileId])
      .then(() => {
        queryClient.invalidateQueries([
          USE_PARTNER_BALANCING_PROFILES_QUERY_KEY,
        ]);
      })
      .catch((e) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      })
      .finally(() => {
        setDeleting(activating.filter((id) => id !== profileId));
      });
  };

  const openDeleteConfirm = (profileId: string, profileName: string) => {
    Modal.confirm({
      title: t('balancing_profiles.delete_modal.title', {
        profileName,
      }),
      width: '600px',
      okText: t('balancing_profiles.delete_modal.submit'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk: () => confirmDelete(profileId),
      closable: true,
      autoFocusButton: 'cancel',
    });
  };

  const columns = [
    {
      title: t('balancing_profiles.columns.id'),
      dataIndex: 'id',
      key: 'id',
      render: (id: string, record: BalancingProfile) => (
        <Link to={`${record.id}`}>
          <Typography.Paragraph
            copyable={{ text: id }}
            style={{ display: 'inline-block', marginBottom: 0 }}
          >
            {id.substring(0, 8)}-...
          </Typography.Paragraph>
        </Link>
      ),
    },
    {
      title: t('balancing_profiles.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('balancing_profiles.columns.max-current-a'),
      dataIndex: 'maxCurrentA',
      key: 'maxCurrentA',
    },
    {
      title: t('balancing_profiles.columns.max-power-w'),
      dataIndex: 'maxPowerW',
      key: 'maxPowerW',
    },
    {
      title: t('balancing_profiles.columns.tags'),
      dataIndex: 'cpTags',
      key: 'cpTags',
      render: (tags: string[]) => (tags ?? []).join(', '),
    },
    {
      title: t('balancing_profiles.columns.activated-at'),
      dataIndex: 'activatedAt',
      key: 'activatedAt',
      render: (activatedAt: string) =>
        activatedAt ? dayjs(activatedAt).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('balancing_profiles.columns.deactivated-at'),
      dataIndex: 'deactivatedAt',
      key: 'deactivatedAt',
      render: (deactivatedAt: string) =>
        deactivatedAt ? dayjs(deactivatedAt).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: '',
      width: '50px',
      align: 'center' as 'center',
      dataIndex: 'id',
      key: 'activate/deactivate',
      render: (_: string, record: BalancingProfile) => {
        if (!record.deactivatedAt && record.activatedAt) {
          return (
            <Button
              type="link"
              disabled={activating.includes(record.id)}
              onClick={() => activateOrDeactivate(record.id, 'deactivate')}
            >
              {t('balancing_profiles.actions.deactivate')}
            </Button>
          );
        }

        return (
          <Button
            type="link"
            disabled={activating.includes(record.id)}
            onClick={() => activateOrDeactivate(record.id, 'activate')}
          >
            {t('balancing_profiles.actions.activate')}
          </Button>
        );
      },
    },
    {
      title: '',
      width: '50px',
      align: 'center' as 'center',
      dataIndex: 'id',
      key: 'delete',
      render: (_: string, record: BalancingProfile) => {
        return (
          <Button
            type="default"
            disabled={deleting.includes(record.id)}
            onClick={() => openDeleteConfirm(record.id, record.name)}
          >
            {t('common.actions.delete')}
          </Button>
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    const items = query?.data?.items ?? [];

    return items.map((item) => {
      return {
        ...item,
        key: item.id,
      };
    });
  }, [query?.data?.items]);

  return (
    <>
      {contextHandler}
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('balancing_profiles.titles.balancing-profiles')}
        </Typography.Title>

        <Link to="/balancing-profiles/create">
          <Button type={'primary'}>{t('common.actions.create')}</Button>
        </Link>
      </Padding>

      <Padding>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={dataSource}
        />
      </Padding>

      <Padding>
        <Pagination
          page={page}
          hasNext={(query?.data?.items ?? []).length === PAGE_SIZE}
          hasPrev={page > 1}
          onChange={(_, index) => setPage(index)}
        >
          <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
        </Pagination>
      </Padding>
    </>
  );
};
