import { useQueryClient } from '@tanstack/react-query';
import { Button, Form, Select, Modal, message } from 'antd';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useTranslation } from 'react-i18next';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { ChargePointPartnerItem } from 'features/charge-points/types/ChargePointPartnerItem';
import { useChargeTagsPartnerQuery } from 'features/charge-tags/queries';
import {
  USE_TRANSACTIONS_SEARCH_QUERY_KEY,
  useTransactionStartMutation,
} from 'features/transactions/queries';
import { ReqTransactionStartData } from 'features/transactions/types/requests';
import { HttpError } from 'core/types';

export interface ChargePointTransactionFormProps {
  isOpen?: boolean;
  chargePoint: ChargePointPartnerItem;
  onCancel: () => void;
}

export function ChargePointTransactionForm({
  isOpen,
  chargePoint,
  onCancel,
}: ChargePointTransactionFormProps) {
  const [form] = Form.useForm();
  const start = useTransactionStartMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const partnerId = usePartnerId();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const tagsQuery = useChargeTagsPartnerQuery([
    chargePoint.partner.id,
    { size: 1000 },
  ]);

  const handleClose = () => {
    start.reset();
    onCancel();
  };

  const handleStart = (values: ReqTransactionStartData) => {
    start
      .mutateAsync([
        partnerId,
        chargePoint.id,
        { ...values, connectorId: +values.connectorId },
      ])
      .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
      .then(() => {
        queryClient.invalidateQueries([USE_TRANSACTIONS_SEARCH_QUERY_KEY]);
        messageApi.open({
          type: 'success',
          content: t('common.status.success'),
        });
        onCancel();
      })
      .catch((e: HttpError) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title={t('chargepoints.titles.manual-start-transaction')}
        onCancel={handleClose}
        footer={[
          <Button key={'cancel'} onClick={handleClose}>
            {t('common.actions.cancel')}
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            htmlType={'submit'}
            form={'start-transaction-form'}
            loading={start.isLoading}
          >
            {t('chargepoints.actions.start')}
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          layout={'vertical'}
          id={'start-transaction-form'}
          onFinish={handleStart}
        >
          <Form.Item
            label={`${t('chargepoints.labels.connector')}:`}
            name={'connectorId'}
            rules={[
              {
                required: true,
                message: t('chargepoints.rules.choose-connector'),
              },
            ]}
          >
            <Select>
              {!!chargePoint.connectors &&
                Object.entries(chargePoint.connectors).map(
                  ([connector, details]) => (
                    <Select.Option key={connector} value={connector}>
                      {connector} ({details.type})
                    </Select.Option>
                  ),
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label={`${t('chargepoints.labels.tag')}:`}
            name={'tagId'}
            rules={[
              { required: true, message: t('chargepoints.rules.choose-tag') },
            ]}
          >
            <Select loading={tagsQuery.isLoading}>
              {(tagsQuery.data ?? [])
                .filter((tag) => tag.activatedAt && !tag.lockedAt)
                .map((tag) => (
                  <Select.Option key={tag.id}>{tag.tag}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
