import { ReactComponent as Ccs1 } from 'assets/ev-plug-ccs1.svg';
import { ReactComponent as Ccs2 } from 'assets/ev-plug-ccs2.svg';
import { ReactComponent as Chademo } from 'assets/ev-plug-chademo.svg';
import { ReactComponent as Shuko } from 'assets/ev-plug-domestic-f-shuko.svg';
// eslint-disable-next-line import/no-duplicates
import { ReactComponent as GbtAc } from 'assets/ev-plug-iec-62196-t2-gbt.svg';
// eslint-disable-next-line import/no-duplicates
import { ReactComponent as GbtDc } from 'assets/ev-plug-iec-62196-t2-gbt.svg';
import { ReactComponent as Tesla } from 'assets/ev-plug-tesla.svg';
import { ReactComponent as Roadster } from 'assets/ev-plug-tesla-roadster.svg';
import { ReactComponent as Type2 } from 'assets/ev-plug-type2.svg';
import { ReactElement } from 'react';

// prettier-ignore
export const connectorIcons: Record<string, ReactElement> = {
  'ccs': <Ccs1 />,
  'ccs2-combo': <Ccs2 />,
  'chademo': <Chademo />,
  // @deprecated
  'gbt': <GbtAc />,
  'gbt-ac': <GbtAc />,
  'gbt-dc': <GbtDc />,
  'shuko': <Shuko />,
  // @deprecated
  'tesla': <Tesla />,
  'tesla-r': <Roadster />,
  'tesla-s': <Tesla />,
  'type-2': <Type2 />,
};
