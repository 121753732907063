import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnersSubscriptionCreate } from '../fetches/fetchPartnersSubscriptionCreate';
import { ResPartnersSubscriptionsCreate } from '../types/responses';

export function usePartnersSubscriptionCreateMutation(
  options?: UseMutationOptions<
    ResPartnersSubscriptionsCreate,
    Types.HttpError,
    Parameters<typeof fetchPartnersSubscriptionCreate>
  >,
) {
  return useMutation((args) => fetchPartnersSubscriptionCreate(...args), {
    retry: 0,
    ...options,
  });
}
