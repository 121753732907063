import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { RowProps } from './types';

export const ElementsRow = ({ isSortingDisabled, ...rowProps }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: rowProps['data-row-key'],
  });

  const isPlaceholder = rowProps.className === 'ant-table-placeholder';

  const style: React.CSSProperties = {
    ...rowProps.style,
    transition,
    ...(!isSortingDisabled ? { cursor: 'move' } : {}),
    transform: CSS.Translate.toString(transform),
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    outline: 'none',
  };

  if (isPlaceholder) {
    return <tr {...rowProps} />;
  }

  return (
    <tr
      {...rowProps}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};
