import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchTransactionStart } from '../fetches';
import { ResTransactionStart } from '../types/responses';

export function useTransactionStartMutation(
  options?: UseMutationOptions<
    ResTransactionStart,
    Types.HttpError,
    Parameters<typeof fetchTransactionStart>
  >,
) {
  return useMutation((args) => fetchTransactionStart(...args), {
    retry: 0,
    ...options,
  });
}
