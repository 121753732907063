import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Helpers from 'core/helpers';
import * as Types from 'core/types';

import { fetchChargePointsPartnerItems } from '../fetches';
import { Requests, Responses } from '../types';

export const USE_CHARGE_POINTS_PARTNER_ITEMS_QUERY_KEY =
  'charge.points.partner.items';

export function useChargePointsPartnerItemsQuery(
  partnerId?: string,
  params?: Requests.ReqChargePointsPartnerItemsParams,
  pagination?: Helpers.RequestPagination,
  options?: UseQueryOptions<
    Responses.ResChargePointsPartnerItemsParams,
    Types.HttpError,
    Responses.ResChargePointsPartnerItemsParams,
    any[]
  >,
) {
  const queryPagination = { index: 1, size: 20, ...pagination };
  const query = useQuery(
    [
      USE_CHARGE_POINTS_PARTNER_ITEMS_QUERY_KEY,
      partnerId,
      params,
      queryPagination,
    ],
    () => fetchChargePointsPartnerItems(partnerId, params, queryPagination),
    options,
  );

  const hasNextPage =
    !!query.data?.items && query.data.items?.length === queryPagination?.size;
  const hasPrevPage = queryPagination.index > 1;

  return {
    ...query,
    hasNextPage,
    hasPrevPage,
  };
}
