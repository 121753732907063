import { Restriction } from './Restriction';

export enum TariffPriceType {
  Energy = 'energy',
  Time = 'time',
  Flat = 'flat',
}

export type TariffPrice = {
  price?: number;
  stepSize?: number;
  vatPercent?: number;
};

export type TariffElement = {
  id: string;
  restrictions: Restriction[];
  tariffPrices: {
    [TariffPriceType.Energy]?: TariffPrice;
    [TariffPriceType.Flat]?: TariffPrice;
    [TariffPriceType.Time]?: TariffPrice;
  };
};

export interface GroupTariff {
  name: string;
  activatedAt?: string;
  archivedAt?: string;
  currency: string;
  elements: TariffElement[];
  id: string;
  maxPrice: {
    amount?: number;
    vatAmount?: number;
  };
  minPrice: {
    amount?: number;
    vatAmount?: number;
  };
  partnerId: string;
  tariffGroupId: string;
  type: string;
  url: string;
  validFrom?: string;
  validTo?: string;
  purposes: string[];
}
