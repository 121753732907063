import {
  DatePicker,
  DatePickerProps,
  Form,
  InputNumber,
  Select,
  Tag,
} from 'antd';
import debounce from 'lodash-es/debounce';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ConnectorType } from 'features/charge-points/types';
import { connectorsLabels } from 'components/view/ConnectorLabel/connectors-labels.const';

import {
  AMOUNT_FROM,
  AMOUNT_TO,
  AmountFormValues,
  TransactionStatus,
  TransactionStatusGroup,
  TransactionsFilterProps,
} from './types';
import {
  mapTransactionStatusToTranslationKey,
  mapTransactionTagToTranslationKey,
} from './helpers';
import styles from './styles.module.css';

export const TransactionsFilter = ({
  filterState,
  dispatch,
}: TransactionsFilterProps) => {
  const { t } = useTranslation();

  const [amountForm] = Form.useForm();

  const onDateChange = (type: 'START_DATE_FROM' | 'START_DATE_TO') => {
    const handler: DatePickerProps['onChange'] = (value) => {
      if (!value) {
        dispatch({ type, payload: '' });
        return;
      }

      const date = dayjs(value.toDate());
      const selectedDate = date.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      dispatch({
        type,
        payload: selectedDate,
      });
    };

    return handler;
  };

  const debouncedAmountUpdate = debounce((amountValues: AmountFormValues) => {
    dispatch({
      type: 'UPDATE_AMOUNT',
      payload: amountValues,
    });
  }, 1000);

  const onAmountInputChange = () => {
    amountForm
      .validateFields()
      .then((formValues: AmountFormValues) => {
        debouncedAmountUpdate(formValues);
      })
      .catch(() => {
        debouncedAmountUpdate.cancel();
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Tag.CheckableTag
          className={styles.tag}
          key={'not-free'}
          checked={filterState.hideZeroAmounts}
          onChange={() => dispatch({ type: 'HIDE_ZERO_AMOUNTS' })}
        >
          {t('transactions.filter.not-free')}
        </Tag.CheckableTag>
        {Object.keys(filterState.statusGroups).map((statusGroup) => {
          return (
            <Tag.CheckableTag
              className={styles.tag}
              key={statusGroup}
              checked={
                filterState.statusGroups[statusGroup as TransactionStatusGroup]
              }
              onChange={(checked) =>
                dispatch({
                  type: 'SELECT_STATUS_GROUP',
                  payload: {
                    statusGroup: statusGroup as TransactionStatusGroup,
                    isSelected: checked,
                  },
                })
              }
            >
              {t(
                mapTransactionTagToTranslationKey[
                  statusGroup as TransactionStatusGroup
                ],
              )}
            </Tag.CheckableTag>
          );
        })}
        <Select
          mode="multiple"
          allowClear
          style={{ width: '250px' }}
          placeholder={t('transactions.filter.select-statuses')}
          maxTagCount="responsive"
          value={filterState.statuses}
          onChange={(value) => {
            dispatch({
              type: 'SELECT_STATUS',
              payload: { statuses: value },
            });
          }}
          options={Object.values(TransactionStatus).map((status) => ({
            label: t(
              mapTransactionStatusToTranslationKey[status as TransactionStatus],
            ),
            value: status,
          }))}
        />
        <Select
          mode="multiple"
          allowClear
          style={{ width: '250px' }}
          placeholder={t('transactions.filter.connector-types')}
          maxTagCount="responsive"
          value={filterState.connectorTypes}
          onChange={(value) => {
            dispatch({
              type: 'SELECT_CONNECTOR_TYPE',
              payload: { connectorTypes: value },
            });
          }}
          options={Object.keys(connectorsLabels).map((connector) => ({
            label: connectorsLabels[connector as ConnectorType],
            value: connector,
          }))}
        />
      </div>
      <div className={styles.row}>
        <Form layout="vertical" form={amountForm} className={styles.amountForm}>
          <Form.Item
            className={styles.input}
            label={`${t('transactions.filter.amount-from')}:`}
            name={AMOUNT_FROM}
            rules={[
              {
                min: 0,
                type: 'number',
                message: t('transactions.rules.amount-non-negative'),
              },
            ]}
          >
            <InputNumber min={0} onChange={onAmountInputChange} />
          </Form.Item>
          <Form.Item
            className={styles.input}
            label={`${t('transactions.filter.amount-to')}:`}
            name={AMOUNT_TO}
            rules={[
              {
                min: 1,
                type: 'number',
                message: t('transactions.rules.amount-greater-zero'),
              },
              {
                validator: (_, value) => {
                  const amountFrom = amountForm.getFieldValue('amountFrom');
                  if (value && amountFrom && value <= amountFrom) {
                    return Promise.reject(
                      t('transactions.rules.amount-to-higher-from'),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber min={1} onChange={onAmountInputChange} />
          </Form.Item>
        </Form>
        <div className={styles.dateInput}>
          <label htmlFor="start-date-from">
            {t('transactions.filter.started-from')}:
          </label>
          <DatePicker
            id="start-date-from"
            showTime
            allowClear
            onChange={onDateChange('START_DATE_FROM')}
          />
        </div>
        <div className={styles.dateInput}>
          <label htmlFor="start-date-to">
            {t('transactions.filter.started-to')}:
          </label>
          <DatePicker
            id="start-date-to"
            showTime
            allowClear
            minDate={dayjs(filterState?.startDateFrom)}
            onChange={onDateChange('START_DATE_TO')}
          />
        </div>
      </div>
    </div>
  );
};
