import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

export const useSubpartnersTableColumns = (): ColumnsType<
  Record<string, any>
> => {
  const { t } = useTranslation();

  return [
    {
      title: t('subpartners.columns.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('subpartners.columns.email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('subpartners.columns.phone'),
      key: 'phone',
      dataIndex: 'phone',
    },
  ];
};
