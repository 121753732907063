import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchClient } from '../fetches';
import { Responses } from '../types';

export const USE_CLIENT_QUERY_KEY = 'client';

export function useClientQuery(
  clientId?: string,
  options?: UseQueryOptions<
    Responses.ResClient,
    Types.HttpError,
    Responses.ResClient,
    any[]
  >,
) {
  return useQuery(
    [USE_CLIENT_QUERY_KEY, clientId],
    () => fetchClient(clientId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
