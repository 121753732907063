import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchTransactionsSearch } from '../fetches';
import { ReqTransactionsSearchParams } from '../types/requests';
import { ResTransactionsSearch } from '../types/responses';

export const USE_TRANSACTIONS_SEARCH_QUERY_KEY = 'transactions.search';

export function useTransactionsSearchQuery(
  params?: ReqTransactionsSearchParams,
  options?: UseQueryOptions<
    ResTransactionsSearch,
    Types.HttpError,
    ResTransactionsSearch,
    any[]
  >,
) {
  return useQuery(
    [USE_TRANSACTIONS_SEARCH_QUERY_KEY, params],
    () => fetchTransactionsSearch(params),
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
