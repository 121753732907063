import { PropsWithChildren, memo } from 'react';
import cn from 'clsx';

import styles from './Padding.module.css';

interface PaddingProps {
  className?: string;
  isHorizontal?: boolean;
  noStart?: boolean;
  noEnd?: boolean;
  bottomBorder?: boolean;
  topBorder?: boolean;
}

export const Padding = ({
  className,
  isHorizontal = false,
  noStart = false,
  noEnd = false,
  bottomBorder = false,
  topBorder = false,
  children,
}: PropsWithChildren<PaddingProps>) => (
  <div
    className={cn(styles.wrapper, className, {
      [styles.row]: isHorizontal,
      [styles.column]: !isHorizontal,
      [styles.noStart]: noStart,
      [styles.noEnd]: noEnd,
      [styles.bottomBorder]: bottomBorder,
      [styles.topBorder]: topBorder,
    })}
  >
    {children}
  </div>
);

export default memo(Padding);
