import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchChargePointsSendConfigs } from '../fetches';

export function useChargePointsSendConfigsMutation(
  options?: UseMutationOptions<
    {},
    Types.HttpError,
    Parameters<typeof fetchChargePointsSendConfigs>
  >,
) {
  return useMutation((args) => fetchChargePointsSendConfigs(...args), {
    retry: 0,
    ...options,
  });
}
