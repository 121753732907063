import { useQueryClient } from '@tanstack/react-query';
import {
  USE_PARTNER_QUERY_KEY,
  usePartnerLegalUpdateMutation,
  usePartnerNaturalUpdateMutation,
} from 'features/partners/queries';
import {
  ReqPartnerLegalUpdateData,
  ReqPartnerNaturalUpdateData,
} from 'features/partners/types/requests';
import { ResPartner } from 'features/partners/types/responses';

type ProfileData = Partial<
  ReqPartnerNaturalUpdateData | ReqPartnerLegalUpdateData
>;

export const useProfileData = ({ partner }: { partner: ResPartner | null }) => {
  const updateNatural = usePartnerNaturalUpdateMutation();
  const updateLegal = usePartnerLegalUpdateMutation();
  const queryClient = useQueryClient();

  const updateProfile = async (data: ProfileData) => {
    if (!partner) return;

    if (partner.type === 'legal') {
      return updateLegal.mutateAsync(
        [
          partner.id,
          {
            ...partner,
            ...data,
            details:
              (data.details as ReqPartnerLegalUpdateData['details']) ||
              partner.legalDetails,
            ocpiPartyId: data.ocpiPartyId || partner.ocpiDetails?.partyId,
          },
        ],
        {
          onSuccess: (data) => {
            queryClient.setQueryData([USE_PARTNER_QUERY_KEY, 'me'], data);
          },
          onError: (error) => {
            console.error(error);
            throw error;
          },
        },
      );
    } else {
      return updateNatural.mutateAsync(
        [
          partner.id,
          {
            ...partner,
            ...data,
            details:
              (data.details as ReqPartnerNaturalUpdateData['details']) ||
              partner.naturalDetails,
            ocpiPartyId: partner.ocpiDetails?.partyId,
          },
        ],
        {
          onSuccess: (data) => {
            queryClient.setQueryData([USE_PARTNER_QUERY_KEY, 'me'], data);
          },
          onError: (error) => {
            console.error(error);
            throw error;
          },
        },
      );
    }
  };

  const isLoading = updateLegal.isLoading || updateNatural.isLoading;

  const error = updateLegal.error || updateNatural.error;

  return [updateProfile, { isLoading, error }] as const;
};
