import { Empty, Spin, Tabs, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useTransactionMetricsQuery,
  useTransactionQuery,
} from 'features/transactions/queries';
import { TransactionActions } from 'views/Transactions';
import { Padding } from 'components/Padding';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useState } from 'react';

import styles from './TransactionDetails.module.css';
import { Details } from './Tabs/Details';
import { Metrics } from './Tabs/Metrics';
import { Service } from './Tabs/Service';

export function TransactionDetails() {
  const { transactionId } = useParams();
  const partnerId = usePartnerId();

  const { t } = useTranslation();

  const {
    data: transaction,
    isLoading,
    isError,
  } = useTransactionQuery(partnerId, transactionId);

  const { data: transactionMetrics, isLoading: isMetricsLoading } =
    useTransactionMetricsQuery(partnerId, transactionId, 1, 9999999);

  if (isLoading || isMetricsLoading) {
    return <Spin />;
  }

  if (isError) {
    return (
      <div className={styles.transaction_container}>
        {t('common.status.failed')}
      </div>
    );
  }

  if (!transaction) {
    return <Empty />;
  }

  const tabs = [
    {
      label: t('transactions.titles.main-info'),
      key: 'info',
      children: (
        <Details transaction={transaction} metrics={transactionMetrics} />
      ),
    },
    {
      label: t('transactions.titles.service-info'),
      key: 'service',
      children: <Service transaction={transaction} />,
    },
    {
      label: t('transactions.titles.metrics'),
      key: 'metrics',
      children: <Metrics metrics={transactionMetrics} />,
    },
  ];

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('transactions.titles.transaction')}{' '}
        </Typography.Title>
        <TransactionActions transaction={transaction} />
      </Padding>

      <div className={styles.transaction_container}>
        <Tabs type="line" defaultActiveKey="info" size="large" items={tabs} />
      </div>
    </>
  );
}
