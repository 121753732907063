import { Col, Popover, Row, Select, Spin } from 'antd';
import { usePartner, usePartnerId } from 'core/providers/PartnerProvider';
import { useSubpartnersQuery } from 'features/subpartners';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';
import { FilterTwoTone } from '@ant-design/icons';

import styles from './styles.module.css';
import { AvailabilityStatusSwitch } from './ConnectionStatusSwitch';
import { TextSearch } from './TextSearch';
import { ChargePointsFilterProps } from './types';
import { HashtagsSelect } from './HashtagsSelect';
import { OwnersSelect } from './OwnersSelect';

export const ChargePointsFilter = ({
  filterState,
  dispatch,
  isLoading,
}: ChargePointsFilterProps) => {
  const partnerId = usePartnerId();
  const partner = usePartner();
  const { data: subpartners, isLoading: isSubpartnersLoading } =
    useSubpartnersQuery(partnerId, { size: 999 });

  const { data: hashtags, isLoading: isHashTagsLoading } =
    usePartnerHashtagsQuery([partnerId]);

  if (isSubpartnersLoading || isHashTagsLoading) {
    return <Spin />;
  }

  const owners = subpartners && partner && [partner, ...subpartners];

  return (
    <div className={styles.container}>
      <Row
        gutter={16}
        style={{ marginBottom: 8 }}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <TextSearch dispatch={dispatch} isLoading={isLoading} />
        </Col>
        <Col>
          <Popover
            placement={'leftTop'}
            content={
              <>
                {owners && (
                  <OwnersSelect
                    owners={owners}
                    selectedOwnerPartnerId={filterState.ownerPartnerId}
                    dispatch={dispatch}
                  />
                )}
                {hashtags && (
                  <HashtagsSelect
                    allHashtags={hashtags}
                    selectedHashtags={filterState.hashtags}
                    dispatch={dispatch}
                  />
                )}
                <AvailabilityStatusSwitch
                  availabilityStatus={filterState.availabilityStatus}
                  dispatch={dispatch}
                />
              </>
            }
            trigger="click"
          >
            <FilterTwoTone style={{ fontSize: '20px' }} />
          </Popover>
        </Col>
      </Row>
    </div>
  );
};
