import { FC, memo } from 'react';
import { GroupTariff } from 'features/partners-tariffs-groups/types';
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Select,
  FormInstance,
} from 'antd';
import {
  dateFormatString,
  timeFormatString,
  getCurrenciesSelectOptions,
  getPurposesSelectOptions,
} from 'core/utils';
import { Currency } from 'core/types/Currency';
import { TariffStatus } from 'core/utils/tariffUtils';
import { useTranslation } from 'react-i18next';

import s from './TariffForm.module.css';
import {
  toFormValues,
  fromFormValues,
  TariffEditable,
} from './TariffForm.utils';

type Props = {
  tariff?: GroupTariff;
  form: FormInstance;
  currencies: Currency[];
  onSubmit: (tariff: TariffEditable) => void;
  isReadOnly: boolean;
  onCurrencyChange: (value: string) => void;
  status?: string;
};

const TariffForm: FC<Props> = ({
  tariff,
  onSubmit,
  form,
  isReadOnly,
  currencies,
  onCurrencyChange,
  status = '',
}) => {
  const initialValues = toFormValues(tariff);
  const currenciesOptions = getCurrenciesSelectOptions(currencies);
  const options =
    status === TariffStatus.draft || !status
      ? ['gen', 'roaming']
      : tariff?.purposes || [];
  const purposes = getPurposesSelectOptions(options);

  const { t } = useTranslation();

  return (
    <Form
      layout={'vertical'}
      initialValues={initialValues}
      onFinish={(values) => onSubmit(fromFormValues(values, tariff))}
      form={form}
      disabled={isReadOnly}
    >
      <Form.Item name="name" label={`${t('pricing.labels.tariff-name')}:`}>
        <Input
          placeholder={t('pricing.placeholders.tariff-name')}
          maxLength={120}
        />
      </Form.Item>

      <Form.Item name="url" label={`${t('pricing.labels.terms-url')}:`}>
        <Input
          placeholder={t('pricing.placeholders.tariff-terms-url')}
          maxLength={120}
        />
      </Form.Item>

      <Form.Item
        name="description"
        label={`${t('pricing.labels.description')}:`}
      >
        <Input.TextArea
          rows={4}
          maxLength={1024}
          placeholder={t('pricing.placeholders.enter-description')}
        />
      </Form.Item>

      <Row>
        <Col span={8}>
          <Row>
            <Form.Item
              name="validFrom"
              label={`${t('pricing.labels.valid-from')}:`}
              className={s.wide}
            >
              <DatePicker
                showTime={{ showNow: false, showSecond: false }}
                showNow={false}
                format={`${dateFormatString} ${timeFormatString}`}
                className={s.wide}
                allowClear={false}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name="validTo"
              label={`${t('pricing.labels.valid-to')}:`}
              className={s.wide}
            >
              <DatePicker
                showTime={{ showNow: false, showSecond: false }}
                showNow={false}
                format={`${dateFormatString} ${timeFormatString}`}
                className={s.wide}
                allowClear={false}
              />
            </Form.Item>
          </Row>
        </Col>

        <Col span={4} offset={1}>
          <Row>
            <Form.Item
              name={['minPrice', 'amount']}
              label={`${t('pricing.labels.min-price')}:`}
              className={s.wide}
            >
              <InputNumber className={s.wide} min={0} max={999999999} />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name={['maxPrice', 'amount']}
              label={`${t('pricing.labels.max-price')}:`}
              className={s.wide}
            >
              <InputNumber className={s.wide} min={0} max={999999999} />
            </Form.Item>
          </Row>
        </Col>

        <Col span={4} offset={1}>
          <Row>
            <Form.Item
              name="currency"
              label={`${t('pricing.labels.currency')}:`}
              className={s.wide}
            >
              <Select options={currenciesOptions} onChange={onCurrencyChange} />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name="type"
              label={`${t('pricing.labels.tariff-type')}:`}
              className={s.wide}
            >
              <Select disabled />
            </Form.Item>
          </Row>
        </Col>
        <Col span={4} offset={1}>
          <Row>
            <Form.Item
              name="purposes"
              label={`${t('pricing.labels.purposes')}:`}
              className={s.wide}
            >
              <Select mode="multiple" options={purposes} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(TariffForm);
