import { useMemo, useState } from 'react';
import { Button, Table, Typography, Spin, Drawer, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { Pagination } from 'components/Pagination';
import {
  useSubpartnersCancelInviteMutation,
  useSubpartnersInvitationsQuery,
  useSubpartnersQuery,
} from 'features/subpartners';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { HttpError } from 'core/types';

import { usePartnerId } from '../../core/providers/PartnerProvider';

import styles from './Subpartners.module.css';
import { useSubpartnersTableColumns } from './useSubpartnersTableColumns';
import { InviteForm } from './components/InviteForm';
import { PendingInvitations } from './components/PendingInvitations';

const PAGE_SIZE = 15;

export const Subpartners = () => {
  const [index, setIndex] = useState(1);
  const partnerId = usePartnerId();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const closeDrawer = () => setIsDrawerOpen(false);
  const openDrawer = () => setIsDrawerOpen(true);

  const subpartnersTableColumns = useSubpartnersTableColumns();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  
  const {
    data: subpartners,
    isLoading: isSubpartnersLoading,
    nextPage,
    prevPage,
  } = useSubpartnersQuery(partnerId, { index, size: PAGE_SIZE });

  const { data: invitations, refetch: refetchInvitations } =
    useSubpartnersInvitationsQuery(partnerId);

  const cancelInvitation = (token: string) => {
    mutateCancelInvite([partnerId, token])
      .then(() => {
        refetchInvitations();
        message.success(t('subpartners.status.invitation-cancelled'));
      })
      .catch((e: HttpError) => {
        message.error(
          errorT(e?.translationKey, t('subpartners.status.failed-to-cancel')),
        );
      });
  };
  const { mutateAsync: mutateCancelInvite } =
    useSubpartnersCancelInviteMutation();

  const dataSource = useMemo(() => {
    return (subpartners || []).map((subpartner) => ({
      key: subpartner.id,
      name:
        subpartner.type === 'legal'
          ? subpartner?.legalDetails?.name
          : `${subpartner?.naturalDetails?.firstName} ${subpartner?.naturalDetails?.lastName}`,
      email: subpartner?.contacts?.email?.contact,
      phone: subpartner?.contacts?.phone?.contact,
    }));
  }, [subpartners]);

  /** we don't need to show pagination if theres in now data at all */
  const showPagination = dataSource.length > 0 || index > 1;

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('subpartners.titles.subpartners')}
        </Typography.Title>
        <Button type={'primary'} onClick={openDrawer}>
          {t('subpartners.actions.invite-subpartner')}
        </Button>
      </Padding>
      {invitations && invitations.length > 0 && (
        <Padding>
          <PendingInvitations
            invitations={invitations}
            cancelInvitation={cancelInvitation}
          />
        </Padding>
      )}
      <div className={styles.content}>
        {isSubpartnersLoading ? (
          <Spin />
        ) : (
          <>
            <Table
              size="small"
              columns={subpartnersTableColumns}
              dataSource={dataSource}
              scroll={{ x: 500 }}
              pagination={false}
            />
            {showPagination && (
              <div style={{ paddingBlock: '16px' }}>
                <Pagination
                  page={index}
                  hasNext={nextPage}
                  hasPrev={prevPage}
                  onChange={(_, index) => setIndex(index)}
                >
                  <Typography>{`${t(
                    'common.pagination.page',
                  )}: ${index}`}</Typography>
                </Pagination>
              </div>
            )}
          </>
        )}
      </div>
      <Drawer
        title={t('subpartners.actions.invite-subpartner')}
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={isDrawerOpen}
      >
        <InviteForm
          onInvitationSent={() => {
            closeDrawer();
            refetchInvitations();
          }}
        />
      </Drawer>
    </>
  );
};
