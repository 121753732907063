import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { ResLoadBalancingProfileActivate } from '../types/responses';
import { fetchLoadBalancingProfileActivate } from '../fetches';

export function useLoadBalancingProfileActivateMutation(
  options?: UseMutationOptions<
    ResLoadBalancingProfileActivate,
    Types.HttpError,
    Parameters<typeof fetchLoadBalancingProfileActivate>
  >,
) {
  return useMutation((args) => fetchLoadBalancingProfileActivate(...args), {
    retry: 0,
    ...options,
  });
}
