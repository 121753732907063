import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqPartnerSubpartnerInvite } from '../../partners/types/requests';

export async function fetchSubpartnersInvite<
  TResponse = { status: string },
  TError = Types.HttpError,
>(partnerId: string, data: ReqPartnerSubpartnerInvite) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `/partners/${partnerId}/sub-partners/invitations`,
      data,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
