import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagBlock } from '../fetches';
import { ReqChargeTagBlockUnblock } from '../types/requests';
import { ResChargeTagBlockUnblock } from '../types/responses';

export function useChargeTagBlockMutation(
  options?: UseMutationOptions<
    ResChargeTagBlockUnblock,
    Types.HttpError,
    ReqChargeTagBlockUnblock
  >,
) {
  return useMutation(fetchChargeTagBlock, {
    retry: 0,
    ...options,
  });
}
