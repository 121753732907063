import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useTransactionCalculationsQuery } from 'features/transactions/queries';
import { Descriptions, Empty, Spin, Table, Typography } from 'antd';
import { Padding } from 'components/Padding';
import dayjs from 'dayjs';
import { secsToTime } from 'core/utils';
import { TransactionCalculationsItem } from 'features/transactions/types/TransactionCalculations';

import styles from './TransactionCalculations.module.css';

interface Props {}

export function TransactionCalculations(props: PropsWithChildren<Props>) {
  const { transactionId } = useParams<{ transactionId?: string }>();
  const location = useLocation();
  const partnerId = usePartnerId();
  const { isError, isLoading, data } = useTransactionCalculationsQuery(
    partnerId,
    transactionId!,
    true,
    {
      enabled: !!partnerId && !!transactionId,
    },
  );

  const { t } = useTranslation();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div className={styles.container}>{t('common.status.failed')}</div>;
  }

  if (Object.keys(data).length === 0) {
    return (
      <div className={styles.container}>
        <Empty />
      </div>
    );
  }

  const columns = [
    {
      title: t('transactions.columns.mv-id'),
      dataIndex: 'mvId',
      key: 'mvId',
    },
    {
      title: t('transactions.columns.tariff-element-id'),
      dataIndex: 'tariffElementId',
      key: 'tariffElementId',
    },
    {
      title: t('transactions.columns.delta-duration-sec'),
      dataIndex: 'deltaDurationSec',
      key: 'deltaDurationSec',
      render: (secs: number) => secsToTime(secs),
    },
    {
      title: t('transactions.columns.delta-energy-kwh'),
      dataIndex: 'deltaEnergyKwh',
      key: 'deltaEnergyKwh',
    },
    {
      title: t('transactions.columns.energy-amount-eur'),
      dataIndex: 'mvId',
      key: 'energyAmount',
      render: (_: string, record: TransactionCalculationsItem) =>
        record.dimensions?.energy?.amount,
    },
    {
      title: t('transactions.columns.energy-vat-amount-eur'),
      dataIndex: 'mvId',
      key: 'energyVatAmount',
      render: (_: string, record: TransactionCalculationsItem) =>
        record.dimensions?.energy?.vatAmount,
    },
    {
      title: t('transactions.columns.flat-amount-eur'),
      dataIndex: 'mvId',
      key: 'flatAmount',
      render: (_: string, record: TransactionCalculationsItem) =>
        record.dimensions?.flat?.amount,
    },
    {
      title: t('transactions.columns.flat-vat-amount-eur'),
      dataIndex: 'mvId',
      key: 'flatVatAmount',
      render: (_: string, record: TransactionCalculationsItem) =>
        record.dimensions?.flat?.vatAmount,
    },
    {
      title: t('transactions.columns.time-amount-eur'),
      dataIndex: 'mvId',
      key: 'timeAmount',
      render: (_: string, record: TransactionCalculationsItem) =>
        record.dimensions?.time?.amount,
    },
    {
      title: t('transactions.columns.time-vat-amount-eur'),
      dataIndex: 'mvId',
      key: 'timeVatAmount',
      render: (_: string, record: TransactionCalculationsItem) =>
        record.dimensions?.time?.vatAmount,
    },
    {
      title: t('transactions.columns.timestamp'),
      dataIndex: 'ts',
      key: 'ts',
      render: (ts: string) => (ts ? dayjs(ts).format('DD.MM.YYYY HH:mm') : ''),
    },
  ];

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('transactions.titles.transaction-calculation-details')}
        </Typography.Title>
      </Padding>
      <div className={styles.container}>
        <Descriptions layout="vertical">
          <Descriptions.Item label={t('transactions.labels.transaction-id')}>
            <Link to={location.pathname.replace('/calculations', '')}>
              {data.transactionId}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.tariff-id')}>
            {data.tariffId}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.energy-amount-eur')}>
            {data.totalGrouped?.energy?.amount}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('transactions.labels.energy-vat-amount-eur')}
          >
            {data.totalGrouped?.energy?.vatAmount}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.flat-amount-eur')}>
            {data.totalGrouped?.flat?.amount}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('transactions.labels.flat-vat-amount-eur')}
          >
            {data.totalGrouped?.flat?.vatAmount}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.time-amount-eur')}>
            {data.totalGrouped?.time?.amount}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('transactions.labels.time-vat-amount-eur')}
          >
            {data.totalGrouped?.time?.vatAmount}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.total-amount')}>
            {data.totalAmount?.amount}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.total-kwh')}>
            {data.totalKwh}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.total-sec')}>
            {secsToTime(data.totalSec)}
          </Descriptions.Item>
        </Descriptions>
        <Table
          columns={columns}
          size={'small'}
          pagination={false}
          dataSource={data.items}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </>
  );
}
