import { Flex, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { supportedLanguages } from './config';
import { LanguageIcon } from './LanguageIcon';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const onLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <Flex style={{ marginInline: '16px' }}>
      <LanguageIcon />
      <Select
        size="middle"
        onChange={onLanguageChange}
        value={i18n.languages[0]}
        style={{ paddingLeft: 8 }}
      >
        {Object.entries(supportedLanguages).map(([key, value]) => {
          return (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          );
        })}
      </Select>
    </Flex>
  );
};
