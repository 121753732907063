import { CustomDetector } from 'i18next-browser-languagedetector';

export const envLanguageDetector: CustomDetector = {
  name: 'envLanguageDetector',

  lookup() {
    const envLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';
    return envLanguage;
  },
};
