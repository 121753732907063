import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';
import { fetchClientsPaymentHistory } from 'features/clients/fetches';

import {
  FetchPaymentHistoryParams,
  PaymentHistory,
} from '../types/PaymentHistory';

export const USE_CLIENTS_BALANCE_HISTORY = 'clients.payment.history';

export function useClientsPaymentHistoryQuery(
  params: FetchPaymentHistoryParams,
  options?: UseQueryOptions<
    PaymentHistory,
    Types.HttpError,
    PaymentHistory,
    any[]
  >,
) {
  return useQuery(
    [USE_CLIENTS_BALANCE_HISTORY, params],
    () => fetchClientsPaymentHistory(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
