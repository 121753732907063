import { BusinessDetailsKeys } from 'features/charge-points/types/ChargePointPartnerItem';
import { WebTranslateKey } from 'i18n/types';
import { useTranslation } from 'react-i18next';

export const useBusinessDetailsLabels = () => {
  const { t } = useTranslation();

  const businessDetailsTranslations: Record<
    BusinessDetailsKeys,
    WebTranslateKey
  > = {
    buyCost: t('chargepoints.text.buy-cost'),
    installationPrice: t('chargepoints.text.installation-price'),
    kwhCost: t('chargepoints.text.kwh-cost'),
    rentCost: t('chargepoints.text.rent-cost'),
    revenueShare: t('chargepoints.text.revenue-share'),
  };

  return businessDetailsTranslations;
};
