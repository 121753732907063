import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchSubpartnersRegister } from '../fetches';

export function useSubpartnersRegisterMutation(
  options?: UseMutationOptions<
    { status: string },
    Types.HttpError,
    Parameters<typeof fetchSubpartnersRegister>
  >,
) {
  return useMutation((args) => fetchSubpartnersRegister(...args), {
    retry: 0,
    ...options,
  });
}
