import { Button, DatePicker, Spin, Table, Typography } from 'antd';
import clsx from 'clsx';
import { StatCard } from 'components/StatCard';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { secsToTime } from 'core/utils';
import dayjs, { Dayjs } from 'dayjs';
import { usePartnerAnalyticQuery } from 'features/partners/queries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChargePointsStats } from './components/ChargePointsStats';
import styles from './Dashboard.module.css';
import { useTopBySpendColumns } from './hooks/useTopBySpendColumns';
import { useWinnersLoosersColumns } from './hooks/useWinnersLoosersColumns';
import { useConnectorTypesColumns } from './hooks/useConnectorTypesColumns';

export function Dashboard() {
  const [dates, setDates] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(1, 'year'),
    dayjs(),
  ]);
  const partnerId = usePartnerId();
  const isSubpartner = useIsSubpartner();
  const {
    isError,
    isLoading,
    data: dashboard,
    refetch,
  } = usePartnerAnalyticQuery(
    {
      partnerId,
      from: dates[0].toISOString(),
      to: dates[1].toISOString(),
    },
    { enabled: !!partnerId },
  );

  const { t } = useTranslation();

  const topBySpendColumns = useTopBySpendColumns();
  const topWinnersLoosersColumns = useWinnersLoosersColumns();
  const connectorTypeColumns = useConnectorTypesColumns();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div>{t('common.status.failed')}</div>;
  }

  const handleDateFromChange = (date: any) => {
    if (!date) {
      return;
    }

    setDates([date, dates[1]]);
  };

  const handleDateToChange = (date: any) => {
    if (!date) {
      return;
    }

    setDates([dates[0], date]);
  };

  return (
    <div className={styles.dashboard}>
      <ChargePointsStats />
      {/* <ChargePointsStatsCharts /> */}
      <Typography.Title className={styles.dashboard__stats_title} level={4}>
        {t('dashboard.titles.statistics')}
        <div className={styles.dashboard__datepicker}>
          <DatePicker
            placeholder="Date from"
            value={dates[0]}
            onChange={handleDateFromChange}
            disabledDate={(current) => current > dates[1]}
            format="DD-MM-YYYY"
            allowClear={false}
          />
          -
          <DatePicker
            placeholder="Date to"
            value={dates[1]}
            onChange={handleDateToChange}
            disabledDate={(current) => current > dayjs()}
            format="DD-MM-YYYY"
            allowClear={false}
          />
          <Button type="primary" onClick={() => refetch()}>
            {t('common.actions.refresh')}
          </Button>
        </div>
      </Typography.Title>
      <div className={styles.dashboard__stats_grid}>
        <StatCard title={t('dashboard.titles.sessions-count')} size="small">
          {dashboard?.totalSessions.count}
        </StatCard>
        <StatCard title={t('dashboard.titles.payments-received')} size="small">
          {dashboard?.totalSessions.amountEur.toFixed(4)}
        </StatCard>
        <StatCard title={t('dashboard.titles.total-charged')} size="small">
          {dashboard?.totalSessions.kwh}
        </StatCard>
        <StatCard title={t('dashboard.titles.total-time')} size="small">
          {secsToTime(dashboard!.totalSessions.timeSec)}
        </StatCard>
        <StatCard title={t('dashboard.titles.total-sessions-app')} size="small">
          {dashboard?.totalSessions.startByAppCount}
        </StatCard>
        <StatCard title={t('dashboard.titles.total-sessions-tag')} size="small">
          {dashboard?.totalSessions.startTagCount}
        </StatCard>
        <StatCard title={t('dashboard.titles.average-price')} size="small">
          {dashboard?.totalSessions.avgAmount}
        </StatCard>
        <StatCard title={t('dashboard.titles.sessions-user')} size="small">
          {dashboard?.totalSessions.avgCountPerUser}
        </StatCard>
        <StatCard title={t('dashboard.titles.sessions-stations')} size="small">
          {dashboard?.totalSessions.avgCountPerCp}
        </StatCard>
        <StatCard title={t('dashboard.titles.time-session')} size="small">
          {secsToTime(dashboard!.totalSessions.avgTimeSec)}
        </StatCard>
        <StatCard title={t('dashboard.titles.kwh-session')} size="small">
          {dashboard?.totalSessions.avgKwh}
        </StatCard>
      </div>
      {/* We don't want to show this section to subpartners */}
      {!isSubpartner && (
        <div className={clsx(styles.dashboard__gap)}>
          <StatCard title={t('dashboard.titles.top-customers')}>
            <Table
              columns={topBySpendColumns}
              size="small"
              pagination={false}
              dataSource={dashboard.best5clients?.Items?.map((item, index) => ({
                ...item,
                amountEur: item.amountEur.toFixed(4),
                key: index,
              }))}
            />
          </StatCard>
        </div>
      )}
      <div className={styles.dashboard__gap}>
        <StatCard title={t('dashboard.titles.top-chargepoints')}>
          <Table
            columns={topWinnersLoosersColumns}
            size={'small'}
            pagination={false}
            dataSource={dashboard.best5cp?.items?.map((item, index) => ({
              ...item,
              amountEur: item.amountEur.toFixed(4),
              key: index,
            }))}
          />
        </StatCard>
      </div>
      <div className={styles.dashboard__gap}>
        <StatCard title={t('dashboard.titles.worst-chargepoints')}>
          <Table
            columns={topWinnersLoosersColumns}
            size={'small'}
            pagination={false}
            dataSource={dashboard.worst5cp?.items?.map((item, index) => ({
              ...item,
              amountEur: item.amountEur.toFixed(4),
              key: index,
            }))}
          />
        </StatCard>
      </div>
      <div className={styles.dashboard__gap}>
        <StatCard title={t('dashboard.titles.connectors')}>
          <Table
            columns={connectorTypeColumns}
            size={'small'}
            pagination={false}
            dataSource={dashboard.connectors?.items?.map((item, index) => ({
              ...item,
              amountEur: item.amountEur.toFixed(4),
              key: index,
            }))}
          />
        </StatCard>
      </div>
    </div>
  );
}
