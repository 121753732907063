import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ResPartnerTariffGroupDelete } from '../types/responses';

export async function fetchPartnerTariffGroupTariffs<
  TResponse = ResPartnerTariffGroupDelete,
  TError = Types.HttpError,
>(params: { partnerId?: string; groupId?: string }) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}/tariffs`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
