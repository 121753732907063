import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnersSubscriptions } from '../fetches';
import { ResPartnersSubscriptions } from '../types/responses';

export const USE_PARTNER_SUBSCRIPTIONS_QUERY_KEY = 'partners.subscriptions';

export function usePartnersSubscriptionsQuery(
  params: Parameters<typeof fetchPartnersSubscriptions>[0],
  options?: UseQueryOptions<
    ResPartnersSubscriptions,
    Types.HttpError,
    ResPartnersSubscriptions,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_SUBSCRIPTIONS_QUERY_KEY, params],
    () => fetchPartnersSubscriptions(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
