import { Button, message, Typography } from 'antd';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  usePartnerTariffGroupQuery,
  usePartnerTariffGroupTariffCreateMutation,
  usePartnerTariffGroupTariffsQuery,
  usePartnerTariffGroupTariffUpdateMutation,
  usePartnerTariffGroupUpdateMutation,
} from 'features/partners-tariffs-groups/queries';

import GroupForm from './GroupForm/GroupForm';

const EMPTY_RESTRICTION = {
  connectorTypes: [],
  daysOfWeek: [],
  endDate: undefined,
  maxCurrent: undefined,
  maxDuration: undefined,
  maxKwh: undefined,
  maxPower: 22,
  minCurrent: undefined,
  minDuration: undefined,
  minKwh: undefined,
  minPower: undefined,
  reservationType: '',
  startDate: undefined,
  time: {
    from: '00:00',
    to: '23:59',
  },
};

const EMPTY_ELEMENT = {
  tariffPrices: {
    energy: {
      price: 0,
      stepSize: 0,
      vatPercent: 0,
    },
    time: {
      price: 0,
      stepSize: 0,
      vatPercent: 0,
    },
    flat: {
      price: 0,
      vatPercent: 0,
    },
    parking: {
      price: 0,
      stepSize: 0,
      vatPercent: 0,
    },
  },
  restrictions: [EMPTY_RESTRICTION],
};

const EMPTY_ITEM = {
  currency: 'RSD',
  type: 'reg',
  url: 'https://tariffs.com',
  minPrice: {
    amount: 1.0,
    vatAmount: 0.1,
  },
  maxPrice: {
    amount: 100000.0,
    vatAmount: 10000.0,
  },
  elements: [EMPTY_ELEMENT],
};

export const TariffGroupUpdate = () => {
  const { partnerId, groupId } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const showError = (msg: string) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };
  const { data: tariffGroup, isLoading } = usePartnerTariffGroupQuery(
    {
      partnerId: partnerId!,
      groupId: groupId!,
    },
    {
      enabled: !!partnerId && !!groupId,
    },
  );
  const { data: tariffGroupTariffs } = usePartnerTariffGroupTariffsQuery(
    {
      partnerId: partnerId!,
      groupId: groupId!,
    },
    {
      enabled: !!partnerId && !!groupId,
    },
  );

  const updateMutation = usePartnerTariffGroupUpdateMutation();
  const updateTariffMutation = usePartnerTariffGroupTariffUpdateMutation();
  const createTariffMutation = usePartnerTariffGroupTariffCreateMutation();

  const formik = useFormik<any>({
    initialValues: {
      name: tariffGroup?.name || '',
      tariffs: tariffGroupTariffs || [],
    },
    enableReinitialize: true,
    onSubmit: (values: any) => {
      updateMutation.mutate([{ partnerId, groupId }, { name: values.name }], {
        onSuccess: async (res) => {
          try {
            // @ts-ignore
            const mutations = await Promise.all[
              values.tariffs.map((item: any) => {
                if (item.id) {
                  updateTariffMutation.mutate(
                    [
                      {
                        partnerId: partnerId || '',
                        groupId: groupId || '',
                        tariffId: item.id,
                      },
                      item,
                    ],
                    {
                      onError: (err) => showError(err.message),
                    },
                  );
                } else {
                  createTariffMutation.mutate([{ partnerId, groupId }, item], {
                    onError: (err) => showError(err.message),
                  });
                }
              })
            ];
            navigate(`/partners/${partnerId}/tariffs/${res?.id}/details`);
          } catch (err: any) {
            showError(err.message);
          }
        },
        onError: (err) => showError(err.message),
      });
    },
  });

  const addRow = (path: string, items: any) => {
    formik.setFieldValue(path, [...items, EMPTY_ITEM]);
  };
  const addRowRestriction = (path: string, items: any) => {
    formik.setFieldValue(path, [...items, EMPTY_RESTRICTION]);
  };
  const addRowElement = (path: string, items: any) => {
    formik.setFieldValue(path, [...items, EMPTY_ELEMENT]);
  };
  const removeRow = (path: string, items: any) => {
    formik.setFieldValue(path, items);
  };
  const removeRowRestriction = (path: string, items: any) => {
    formik.setFieldValue(path, items);
  };
  const removeRowElement = (path: string, items: any) => {
    formik.setFieldValue(path, items);
  };

  if (isLoading) {
    return <LoadingOutlined />;
  }

  return (
    <div>
      {contextHolder}
      <div
        style={{
          padding: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Update tariff group
          </Typography.Title>
        </div>
        <div>
          {/*
          // @ts-ignore */}
          <Button type={'primary'} onClick={formik.handleSubmit}>
            Save
          </Button>
        </div>
      </div>
      <div style={{ padding: '16px' }}>
        <GroupForm
          isEdit
          showNameField
          name={formik.values.name}
          loading={isLoading}
          items={formik.values.tariffs}
          onChange={(e: any) => formik.setFieldValue(`name`, e.target.value)}
          onChangeItem={(idx: any, name: any, item: any) => {
            formik.setFieldValue(`tariffs.[${idx}][${name}]`, item);
          }}
          onChangeItemPrice={(
            idx: any,
            indexElement: number,
            name: any,
            item: any,
          ) => {
            formik.setFieldValue(
              `tariffs.[${idx}].elements.[${indexElement}].tariffPrices.[${name}]`,
              item,
            );
          }}
          onChangeItemRestriction={(
            idx: any,
            indexElement: number,
            indexRestriction: number,
            name: any,
            item: any,
          ) => {
            formik.setFieldValue(
              `tariffs.[${idx}].elements.[${indexElement}].restrictions.[${indexRestriction}].[${name}]`,
              item,
            );
          }}
          addRow={addRow}
          addRowRestriction={addRowRestriction}
          addRowElement={addRowElement}
          removeRowRestriction={removeRowRestriction}
          removeRow={removeRow}
          removeRowElement={removeRowElement}
        />
      </div>
    </div>
  );
};
