import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqChargePointsDeviceResetParams } from '../types/requests';

export async function fetchChargePointsDeviceReset<
  TResponse = {},
  TError = Types.HttpError,
>(data: ReqChargePointsDeviceResetParams) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `partners/${data.partnerId}/charging/charge-points/${data.chargePointId}/reset`,
      {},
      { headers: { ...header }, params: { resetType: data.resetType } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
