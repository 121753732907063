import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';

import { ReqPartnerFileUpload, ResPartnerFileUpload } from '../types';

export async function fetchPartnerFileUpload(
  partnerId: string,
  formData: FormData,
  params?: ReqPartnerFileUpload,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<ResPartnerFileUpload>(
      `partners/${partnerId}/file/images`,
      formData,
      {
        params: { ...params },
        headers: { ...header, 'Content-Type': 'multipart/form-data' },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data);
  }
}
