import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';
import { fetchPartner } from 'features/partners/fetches/fetchPartner';

import { Responses } from '../types';

export const USE_PARTNER_QUERY_KEY = 'partner.partner';

export function usePartnerQuery(
  partnerId: string,
  options?: UseQueryOptions<
    Responses.ResPartnerUser,
    Types.HttpError,
    Responses.ResPartnerUser,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_QUERY_KEY, partnerId],
    () => fetchPartner(partnerId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
