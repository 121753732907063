import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchGetRegularReportTasks } from '../fetches';
import { RegularReportTask } from '../types';

export const USE_GET_REGULAR_REPORT_TASKS_QUERY_KEY =
  'partner.reports.scheduled';

export function useGetRegularReportTasksQuery(
  partnerId: string,
  options?: UseQueryOptions<
    RegularReportTask[],
    Types.HttpError,
    RegularReportTask[],
    string[]
  >,
) {
  return useQuery(
    [USE_GET_REGULAR_REPORT_TASKS_QUERY_KEY, partnerId],
    () => fetchGetRegularReportTasks(partnerId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
