import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const websiteAddressPattern =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

export const LinkInput = ({
  label,
  name,
  className,
  placeholder = 'example.com',
}: {
  label: string;
  name: string;
  className: string;
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  
  return (
    <Form.Item
      label={label}
      colon={true}
      name={name}
      className={className}
      rules={[
        {
          pattern: websiteAddressPattern,
          message: t('common.rules.website'),
        },
      ]}
    >
      <Input addonBefore="https://" inputMode="url" placeholder={placeholder} />
    </Form.Item>
  );
};
