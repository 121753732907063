import { WarningOutlined } from '@ant-design/icons';

import css from './style.module.css';

type EmergencyIconProps = {
  style?: React.CSSProperties;
  animate?: boolean;
};

export const EmergencyIcon = ({
  style,
  animate = true,
}: EmergencyIconProps) => {
  return (
    <WarningOutlined
      style={{ fontSize: 24, marginRight: 8, color: '#ff4d4f', ...style }}
      className={animate ? css.animated : ''}
    />
  );
};
