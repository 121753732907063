import dayjs, { Dayjs } from 'dayjs';
import { GroupTariff } from 'features/partners-tariffs-groups/types';

export type FormValues = {
  name: string;
  currency: string;
  maxPrice: {
    amount?: number;
    vatAmount?: number;
  };
  minPrice: {
    amount?: number;
    vatAmount?: number;
  };
  type: string;
  url: string;
  validFrom?: Dayjs;
  validTo?: Dayjs;
  purposes: string[];
};

export const toFormValues = (tariff?: GroupTariff): FormValues => {
  return tariff
    ? {
        ...tariff,
        validFrom: dayjs(tariff.validFrom),
        validTo: dayjs(tariff.validTo),
      }
    : getEmptyTariff('RUB');
};

export type TariffEditable = Omit<
  GroupTariff,
  | 'id'
  | 'activatedAt'
  | 'archivedAt'
  | 'elements'
  | 'partnerId'
  | 'tariffGroupId'
>;

export const fromFormValues = (
  values: FormValues,
  tariff?: GroupTariff,
): TariffEditable => {
  return {
    ...tariff,
    ...values,
    validFrom: values.validFrom?.toISOString(),
    validTo: values.validTo?.toISOString(),
  };
};

export const getEmptyTariff = (currency: string): FormValues => {
  return {
    name: '',
    url: '',
    type: 'reg',
    currency,
    validFrom: dayjs(),
    validTo: dayjs(),
    minPrice: {
      amount: 0,
      vatAmount: 0,
    },
    maxPrice: {
      amount: 0,
      vatAmount: 0,
    },
    purposes: ['gen', 'roaming'],
  };
};
