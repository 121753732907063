import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Empty, Flex, Typography, message } from 'antd';
import { useIsSubpartner, usePartner } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import { Subscriptions } from './components/Subscriptions';
import { Tariffs } from './components/Tariffs';
import { ProfileUpdateForm } from './Forms/ProfileUpdateForm';
import { LimitsUpdateForm } from './Forms/LimitsUpdateForm';
import {
  AdditionalInfo,
  AdditionalInfoFormValues,
} from './components/AdditionalInfo';
import {
  SupportContacts,
  SupportContactsFormValues,
} from './components/SupportContacts';
import { InfoDescription } from './components/InfoDescription';
import { ContactsDescription } from './components/ContactsDescription';
import { LimitsDescription } from './components/LimitsDescription';
import styles from './Profile.module.css';
import { useProfileData } from './Forms/useProfileUpdate';
import { ResetPasswordForm } from './Forms/ResetPasswordForm';

const filterHttpOut = (url: string | undefined) => {
  if (!url) {
    return '';
  }
  return url.replace(/^https?:\/\//, '');
};

export function Profile() {
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isLimitsUpdateOpen, setIsLimitsUpdateOpen] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const partner = usePartner();
  const isSubpartner = useIsSubpartner();
  const [updateProfile] = useProfileData({ partner });

  if (!partner) {
    return <Empty className={styles.container} />;
  }

  const onAdditionalInfoSubmit = (data: AdditionalInfoFormValues) => {
    updateProfile({
      website: data.website,
      ocpiPartyId: data.ocpiCode,
    })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: t('profile.feedback.data-updated'),
        });
      })
      .catch((e) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e?.message),
        });
      });
  };

  const onSupportContactsSubmit = (data: SupportContactsFormValues) => {
    let phoneNumber = '';
    if (typeof data.phone === 'object') {
      phoneNumber = `${data.phone.countryCode}${data.phone.areaCode}${data.phone.phoneNumber}`;
    } else {
      phoneNumber = data.phone;
    }

    updateProfile({
      techSupport: {
        email: data.email,
        telegram: data.chat,
        phone: phoneNumber,
      },
    })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: t('profile.feedback.data-updated'),
        });
      })
      .catch((e) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e?.message),
        });
      });
  };

  return (
    <>
      {contextHolder}
      <div className={styles.header}>
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {partner?.contacts?.email?.contact ?? ' '}
          </Typography.Title>
        </div>
        <div>
          <Button type={'primary'} onClick={() => setIsUpdateOpen(true)}>
            {t('common.actions.update')}
          </Button>
        </div>
      </div>
      <div className={styles.container}>
        <Flex gap="large" wrap="wrap">
          <div className={styles.card}>
            <InfoDescription data={partner} />
          </div>
          <div className={styles.card}>
            <ContactsDescription
              data={partner.contacts}
              actions={
                <Button
                  type="default"
                  onClick={() => setIsResetPasswordOpen(true)}
                >
                  {t('profile.actions.reset-password')}
                </Button>
              }
            />
          </div>
          {/* Following sections are not supposed to be shown for subpartners */}
          {!isSubpartner && (
            <>
              <div className={`${styles.card} ${styles.fullCard}`}>
                <div>
                  <Typography.Title level={5}>
                    {t('profile.titles.additional-info')}
                  </Typography.Title>
                  <AdditionalInfo
                    onSubmit={onAdditionalInfoSubmit}
                    initialValues={{
                      website: filterHttpOut(partner?.website),
                      ocpiCode: partner.ocpiDetails?.partyId ?? '',
                    }}
                    onSuccess={(message) => {
                      messageApi.open({
                        type: 'success',
                        content: message ?? t('profile.feedback.data-updated'),
                      });
                    }}
                    onError={(message?: string) => {
                      messageApi.open({
                        type: 'error',
                        content: message ?? t('profile.feedback.went-wrong'),
                      });
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.card} ${styles.fullCard}`}>
                <div>
                  <Typography.Title level={5}>
                    {t('profile.titles.support-contacts')}
                  </Typography.Title>
                  <SupportContacts
                    onSubmit={onSupportContactsSubmit}
                    initialValues={{
                      email: partner.techSupport?.email ?? '',
                      chat: filterHttpOut(partner.techSupport?.telegram),
                      phone: partner.techSupport?.phone ?? '',
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.card} ${styles.fullCard}`}>
                <div>
                  <Typography.Title level={5}>
                    {t('profile.titles.subscriptions')}
                  </Typography.Title>
                  <Subscriptions />
                </div>
              </div>
              <div className={`${styles.card} ${styles.fullCard}`}>
                <div className={styles.tariffs}>
                  <Typography.Title level={5}>
                    {t('profile.titles.tariffs')}
                  </Typography.Title>
                  <Tariffs />
                </div>
              </div>
              <div className={`${styles.card} ${styles.fullCard}`}>
                <div className={styles.limits}>
                  <div className={styles.action}>
                    <Button
                      type="primary"
                      onClick={() => setIsLimitsUpdateOpen(true)}
                    >
                      {t('common.actions.update')}
                    </Button>
                  </div>
                  <LimitsDescription data={partner.limits} />
                </div>
              </div>
            </>
          )}
        </Flex>
      </div>
      <ProfileUpdateForm
        isOpen={isUpdateOpen}
        onCancel={() => setIsUpdateOpen(false)}
        onSuccess={() => {
          setIsUpdateOpen(false);
          messageApi.open({
            type: 'success',
            content: t('profile.feedback.data-updated'),
          });
        }}
        onError={() => {
          setIsUpdateOpen(false);
          messageApi.open({
            type: 'error',
            content: t('profile.feedback.went-wrong'),
          });
        }}
      />
      <LimitsUpdateForm
        isOpen={isLimitsUpdateOpen}
        onCancel={() => setIsLimitsUpdateOpen(false)}
        onSuccess={() => setIsLimitsUpdateOpen(false)}
        onError={() => {
          messageApi.open({
            type: 'error',
            content: t('profile.feedback.went-wrong'),
          });
          setIsLimitsUpdateOpen(false);
        }}
      />
      <ResetPasswordForm
        isOpen={isResetPasswordOpen}
        onCancel={() => setIsResetPasswordOpen(false)}
        onSuccess={() => {
          setIsResetPasswordOpen(false);
          messageApi.open({
            type: 'success',
            content: t('profile.feedback.password-updated'),
          });
        }}
      />
    </>
  );
}
