import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Card, message, Modal, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  usePartnersSubscriptionCreateMutation,
  usePartnersSubscriptionsQuery,
  usePartnersSubscriptionTariffsQuery,
} from 'features/partners-subsciptions/queires';
import { usePartnerId } from 'core/providers/PartnerProvider';

import styles from './Tariffs.module.css';

const TARIFFS_NAMES: Record<string, 'basic' | 'business' | 'pro'> = {
  'partners.tariffs.basic': 'basic',
  'partners.tariffs.business': 'business',
  'partners.tariffs.pro': 'pro',
};

export function Tariffs() {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  const { data: tariffs, isLoading } = usePartnersSubscriptionTariffsQuery({
    active: true,
  });

  const partnerId = usePartnerId();

  const { data: subscriptions, refetch } = usePartnersSubscriptionsQuery({
    partnerId,
  });

  const subscriptionTariffsIds = subscriptions?.map((item) => item.tariff.id);

  const update = usePartnersSubscriptionCreateMutation({
    onError: (error) =>
      messageApi.open({
        type: 'error',
        content: error.message,
      }),
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Subscription created',
      });

      refetch();
    },
  });

  const handleChoose = (tariffId: string) => {
    if (!tariffId || !partnerId) {
      return;
    }

    Modal.confirm({
      title: 'Do you want to change subscription?',
      content: `You change subscription to "${TARIFFS_NAMES[tariffId]}"`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        update.mutate([
          {
            params: { partnerId },
            data: {
              tariffId,
              payPeriod: 'm',
              validityFrom: dayjs().toISOString(),
            },
          },
        ]);
      },
    });
  };

  if (isLoading) return <Spin />;

  return (
    <div className={styles.tariffs_container}>
      {contextHolder}
      {tariffs?.map((item) => (
        <Card
          key={item.id}
          title={t(`profile.tariffs.${TARIFFS_NAMES[item.id]}`) ?? '-'}
          className={styles.tariffs_item}
        >
          <div>
            <Typography.Text type="secondary">
              {t('profile.tariffs.price')}:
            </Typography.Text>{' '}
            {item.price.monthly || '-'} {t('profile.tariffs.monthly')} /{' '}
            {item.price.yearly || '-'} {t('profile.tariffs.yearly')}
          </div>
          <div>
            <Typography.Text type="secondary">
              {t('profile.tariffs.currency')}:
            </Typography.Text>{' '}
            {item.price.currency}
          </div>
          <div>
            <Typography.Text type="secondary">
              {t('profile.tariffs.max-active-cps')}:
            </Typography.Text>{' '}
            {item.limits.maxActiveCps ?? '-'}
          </div>
          <div className={styles.tariffs_item_actions}>
            <Button
              size={'small'}
              type={'primary'}
              disabled={!partnerId || subscriptionTariffsIds?.includes(item.id)}
              onClick={() => handleChoose(item.id)}
            >
              {t('profile.tariffs.choose')}
            </Button>
          </div>
        </Card>
      ))}
    </div>
  );
}
