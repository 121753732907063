import { Card, Spin, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { usePartnersSubscriptionsQuery } from 'features/partners-subsciptions/queires';
import { usePartnerId } from 'core/providers/PartnerProvider';

import styles from './Tariffs.module.css';

const TARIFFS_NAMES: Record<string, 'basic' | 'business' | 'pro' | 'custom'> = {
  'partners.tariffs.basic': 'basic',
  'partners.tariffs.business': 'business',
  'partners.tariffs.pro': 'pro',
  'partners.tariffs.custom': 'custom',
};

export function Subscriptions() {
  const partnerId = usePartnerId();
  const { t } = useTranslation();

  const { data: subscriptions, isLoading } = usePartnersSubscriptionsQuery({
    partnerId,
  });

  if (isLoading) return <Spin />;

  return (
    <div className={styles.tariffs_container}>
      {subscriptions?.map((item) => (
        <Card
          key={item.id}
          title={t(`profile.tariffs.${TARIFFS_NAMES[item.tariff.id]}`) ?? '-'}
          extra={<Tag>{t(`profile.subscriptions.${item.status}`) ?? '-'}</Tag>}
        >
          <div>
            <Typography.Text type="secondary">
              {t('profile.subscriptions.pay-period')}:
            </Typography.Text>{' '}
            {item.payPeriod}
          </div>
          <div>
            <Typography.Text type="secondary">
              {t('profile.subscriptions.price')}:
            </Typography.Text>{' '}
            {item.tariff.price.monthly || '-'}{' '}
            {t('profile.subscriptions.monthly')} /{' '}
            {item.tariff.price.yearly || '-'}{' '}
            {t('profile.subscriptions.yearly')}
          </div>
          <div>
            <Typography.Text type="secondary">
              {t('profile.subscriptions.currency')}:
            </Typography.Text>{' '}
            {item.tariff.price.currency}
          </div>
          <div>
            <Typography.Text type="secondary">
              {t('profile.subscriptions.validity')}:
            </Typography.Text>{' '}
            {dayjs(item.validityFrom).format('DD.MM.YYYY')} -{' '}
            {dayjs(item.validityTo).format('DD.MM.YYYY')}
          </div>
          <div>
            <Typography.Text type="secondary">
              {t('profile.subscriptions.max-active-cps')}:
            </Typography.Text>{' '}
            {item.tariff.limits.maxActiveCps ?? '-'}
          </div>
        </Card>
      ))}
    </div>
  );
}
