import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const useClientsTableColumns = (): ColumnsType<Record<string, any>> => {
  const { t } = useTranslation();

  return [
    {
      title: t('clients.columns.phone'),
      key: 'phone',
      dataIndex: 'phone',
      render: (phone: string, record) => (
        <Link to={`${record.id}`}>{phone}</Link>
      ),
    },
    {
      title: t('clients.columns.full-name'),
      key: 'firstName',
      dataIndex: 'firstName',
      render: (firstName: string, record) => (
        <span>
          {`${firstName || '-'} ${record.lastName} ${record?.middleName}`}
        </span>
      ),
    },
    {
      title: t('clients.columns.status'),
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: t('clients.columns.registration-date'),
      key: 'registration',
      dataIndex: 'createdAt',
      render: (date) => dayjs(date).format('DD.MM.YYYY'),
    },
  ];
};
