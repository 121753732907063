import { useTranslation } from 'react-i18next';
import { Transaction } from 'features/transactions/types';
import {
  TransactionActions,
  TransactionsTable,
  TransactionsTableColumns,
  useTransactionTableColumns,
} from 'views/Transactions';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { usePartnerId } from 'core/providers/PartnerProvider';

import { Actions } from './Actions';

export const TransactionsTab = () => {
  const partnerId = usePartnerId();
  const { chargePoint, isExternalResource } = useChargePointsDetailsContext();

  const { t } = useTranslation();

  const transactionsTableColumns = useTransactionTableColumns();
  const tableColumns = transactionsTableColumns
    .filter((column) => column.key !== 'cpId')
    .reduce((prevValue, currentValue) => {
      if (currentValue.key === 'calculations') {
        return [
          ...prevValue,
          {
            title: t('transactions.columns.actions'),
            dataIndex: 'id',
            key: 'actions',
            render: (_: string, transaction: Transaction) =>
              transaction.status === 'active' ? (
                <TransactionActions size={'small'} transaction={transaction} />
              ) : null,
          },
          currentValue,
        ] as TransactionsTableColumns;
      }

      return [...prevValue, currentValue] as TransactionsTableColumns;
    }, [] as TransactionsTableColumns);

  return (
    <div>
      <div style={{ paddingBottom: 16 }}>
        <Actions chargePoint={chargePoint} disabled={isExternalResource} />
      </div>
      <TransactionsTable
        tableColumns={tableColumns}
        deviceId={chargePoint.device?.deviceId}
        partnerId={partnerId}
      />
    </div>
  );
};
