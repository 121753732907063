import { FC, memo, useState, useEffect } from 'react';
import { Space, Button, Typography, Divider, Input, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChargePointsPartnerAssignMutation } from 'features/charge-points/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';

import s from './ChargePointsAdd.module.css';

type Props = {
  onClose: () => void;
};

const ChargePointsAdd: FC<Props> = ({ onClose }) => {
  const assign = useChargePointsPartnerAssignMutation();

  const [deviceId, setDeviceId] = useState<string>('');
  const [deviceSn, setDeviceSn] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setError(null);
  }, [deviceId, deviceSn]);

  const partnerId = usePartnerId();

  const handleAssignChargePoint = () => {
    assign.mutate(
      {
        deviceId,
        deviceSn,
        partnerId,
      },
      {
        onSuccess: () => setSuccess(true),
        onError: (err) => setError(err.message),
      },
    );
  };

  return (
    <>
      {!success && (
        <Space direction="vertical" size={'large'}>
          <Space style={{ width: '100%' }} direction="vertical" size={'large'}>
            <Typography.Text>
              {t('chargepoints.text.already-connected')}
            </Typography.Text>

            <div>
              <Input
                value={deviceSn}
                onChange={(e) => setDeviceSn(e.target.value)}
                placeholder={t('chargepoints.placeholders.serial-number')}
              />
              <Divider style={{ margin: '4px 0' }}>{t('chargepoints.text.or')}</Divider>
              <Input
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
                placeholder={t('chargepoints.placeholders.device-id')}
              />
            </div>

            <Button
              block
              type="primary"
              size="large"
              onClick={handleAssignChargePoint}
              disabled={!deviceId && !deviceSn}
            >
              {t('chargepoints.actions.add-station')}
            </Button>

            {error && <div className={s.error}>{error}</div>}
          </Space>

          <Divider />

          <Space direction="vertical" size={'large'}>
            <Typography.Text type="secondary">
              {t('chargepoints.text.without-connection')}
            </Typography.Text>

            <Button
              disabled
              block
              type="primary"
              size="large"
              loading={assign.isLoading}
            >
              {t('chargepoints.actions.add-custom')}
            </Button>
          </Space>
        </Space>
      )}

      {success && (
        <Result
          status="success"
          title={t('chargepoints.titles.added-chargepoint')}
          // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
          extra={[
            <Button
              key="close"
              type="primary"
              onClick={() => {
                onClose();
                window.setTimeout(() => setSuccess(false), 500);
              }}
            >
              {t('chargepoints.actions.view-all-points')}
            </Button>,
            <Button key="more" onClick={() => setSuccess(false)}>
              {t('chargepoints.actions.add-more')}
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default memo(ChargePointsAdd);
