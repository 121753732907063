import { createContext, useContext, PropsWithChildren, FC } from 'react';
import { usePartnerQuery } from 'features/partners/queries';
import { ResPartner } from 'features/partners/types/responses';
import { Layout, Spin } from 'antd';

export const mePartnerId = 'me';

const PartnerContext = createContext<ResPartner | undefined>(undefined);

export const usePartnerId = () => {
  let partner = useContext(PartnerContext);
  return partner ? partner.id : mePartnerId;
};

export const useIsSubpartner = () => {
  let partner = useContext(PartnerContext);
  return partner && partner?.parentId ? true : false;
};

export const usePartner = (): ResPartner | null => {
  let partner = useContext(PartnerContext);
  return partner || null;
};

export const PartnerProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: partner, isLoading } = usePartnerQuery(mePartnerId);

  if (isLoading) {
    return (
      <Layout
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#fff',
        }}
      >
        <Spin />
      </Layout>
    );
  }

  return (
    <PartnerContext.Provider value={partner}>
      {children}
    </PartnerContext.Provider>
  );
};
