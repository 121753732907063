import dayjs from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';
import 'dayjs/locale/sr-cyrl';

import { SupportedLanguages } from './config';

export const dateLocales: Record<SupportedLanguages, string> = {
  en: 'en',
  ru: 'ru',
  kk: 'kk',
  sr: 'sr-cyrl',
};

export const setDateLocale = (language: SupportedLanguages) => {
  dayjs.locale(dateLocales[language]);
};
