import * as Services from 'core/services';

import { AuthHelpers } from '..';

export async function fetchAuthLogout() {
  const header = await AuthHelpers.authHeader();
  const response = await Services.apiService.post('/auth/logout', undefined, {
    headers: { ...header },
  });
  return response.data;
}
