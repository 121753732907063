import { PropsWithChildren } from 'react';
import { Button, Modal, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useChargePointsDeviceResetMutation } from 'features/charge-points/queries';
import { HttpError } from 'core/types';

interface Props {
  resetType: 'Hard' | 'Soft' | null;
  chargePointId: string;
  onClose: () => void;
}

export function ResetConfirm({
  resetType,
  chargePointId,
  onClose,
}: PropsWithChildren<Props>) {
  const isOpened = !!resetType;
  const partnerId = usePartnerId();
  const [messageApi, contextHolder] = message.useMessage();

  const reset = useChargePointsDeviceResetMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const submit = () => {
    if (!resetType) {
      messageApi.open({
        type: 'error',
        content: t('chargepoints.errors.reset-unknown-type'),
      });
      return;
    }

    reset
      .mutateAsync({ partnerId, chargePointId, resetType })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: t('common.status.success'),
        });
        onClose();
      })
      .catch((e: HttpError) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={`${resetType} reset`}
        open={isOpened}
        onCancel={onClose}
        footer={[
          <Button key={'cancel'} type={'default'} onClick={onClose}>
            {t('common.actions.cancel')}
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            htmlType={'submit'}
            loading={reset.isLoading}
            onClick={submit}
          >
            {t('common.actions.reset')}
          </Button>,
        ]}
      >
        <Typography.Text>
          {t('chargepoints.reset_station_modal.title')}
        </Typography.Text>
      </Modal>
    </>
  );
}
