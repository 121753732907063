import React from 'react';
import { Button, message, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  usePartnerTariffGroupCreateMutation,
  usePartnerTariffGroupTariffCreateMutation,
} from 'features/partners-tariffs-groups/queries';

import GroupForm from './GroupForm/GroupForm';

const EMPTY_RESTRICTION = {
  connectorTypes: [],
  daysOfWeek: [],
  endDate: undefined,
  maxCurrent: undefined,
  maxDuration: undefined,
  maxKwh: undefined,
  maxPower: 22,
  minCurrent: undefined,
  minDuration: undefined,
  minKwh: undefined,
  minPower: undefined,
  reservationType: '',
  startDate: undefined,
  time: {
    from: '00:00',
    to: '23:59',
  },
};

const EMPTY_ELEMENT = {
  tariffPrices: {
    energy: {
      price: 0,
      stepSize: 0,
      vatPercent: 0,
    },
    time: {
      price: 0,
      stepSize: 0,
      vatPercent: 0,
    },
    flat: {
      price: 0,
      vatPercent: 0,
    },
    parking: {
      price: 0,
      stepSize: 0,
      vatPercent: 0,
    },
  },
  restrictions: [EMPTY_RESTRICTION],
};

const EMPTY_ITEM = {
  currency: 'RSD',
  type: 'reg',
  url: 'https://tariffs.com',
  minPrice: {
    amount: 1.0,
    vatAmount: 0.1,
  },
  maxPrice: {
    amount: 100000.0,
    vatAmount: 10000.0,
  },
  elements: [EMPTY_ELEMENT],
};

export const TariffGroupCreate = () => {
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const showError = (msg: string) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };
  const createMutation = usePartnerTariffGroupCreateMutation();
  const createTariffMutation = usePartnerTariffGroupTariffCreateMutation();

  const formik = useFormik<any>({
    initialValues: {
      name: '',
      tariffs: [EMPTY_ITEM],
    },
    onSubmit: (values: any) => {
      createMutation.mutate([{ partnerId }, { name: values.name }], {
        onSuccess: async (res) => {
          try {
            // @ts-ignore
            const mutations = await Promise.all[
              values.tariffs.map((item: any) =>
                createTariffMutation.mutate(
                  [{ partnerId, groupId: res?.id }, item],
                  {
                    onError: (err) => showError(err.message),
                  },
                ),
              )
            ];
            navigate(`/partners/${partnerId}/tariffs/${res?.id}/details`);
            // redirect('nextPath')
          } catch (err: any) {
            showError(err.message);
          }
        },
        onError: (err) => showError(err.message),
      });
    },
  });

  const addRow = (path: string, items: any) => {
    formik.setFieldValue(path, [...items, EMPTY_ITEM]);
  };
  const addRowRestriction = (path: string, items: any) => {
    formik.setFieldValue(path, [...items, EMPTY_RESTRICTION]);
  };
  const addRowElement = (path: string, items: any) => {
    formik.setFieldValue(path, [...items, EMPTY_ELEMENT]);
  };
  const removeRow = (path: string, items: any) => {
    formik.setFieldValue(path, items);
  };
  const removeRowRestriction = (path: string, items: any) => {
    formik.setFieldValue(path, items);
  };
  const removeRowElement = (path: string, items: any) => {
    formik.setFieldValue(path, items);
  };

  return (
    <div>
      {contextHolder}
      <div
        style={{
          padding: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Create tariff group
          </Typography.Title>
        </div>
        <div>
          {/*
          // @ts-ignore */}
          <Button type={'primary'} onClick={formik.handleSubmit}>
            Save
          </Button>
        </div>
      </div>
      <div style={{ padding: '16px' }}>
        <GroupForm
          isCreate
          name={formik.values.name}
          items={formik.values.tariffs}
          onChange={(e: any) => formik.setFieldValue(`name`, e.target.value)}
          onChangeItem={(idx: any, name: any, item: any) => {
            formik.setFieldValue(`tariffs.[${idx}][${name}]`, item);
          }}
          onChangeItemPrice={(
            idx: any,
            indexElement: number,
            name: any,
            item: any,
          ) => {
            formik.setFieldValue(
              `tariffs.[${idx}].elements.[${indexElement}].tariffPrices.[${name}]`,
              item,
            );
          }}
          onChangeItemRestriction={(
            idx: any,
            indexElement: number,
            indexRestriction: number,
            name: any,
            item: any,
          ) => {
            formik.setFieldValue(
              `tariffs.[${idx}].elements.[${indexElement}].restrictions.[${indexRestriction}].[${name}]`,
              item,
            );
          }}
          addRow={addRow}
          addRowRestriction={addRowRestriction}
          addRowElement={addRowElement}
          removeRowRestriction={removeRowRestriction}
          removeRow={removeRow}
          removeRowElement={removeRowElement}
        />
      </div>
    </div>
  );
};
