import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchLoadBalancingProfileDelete } from '../fetches';

export function useLoadBalancingProfileDeleteMutation(
  options?: UseMutationOptions<
    {},
    Types.HttpError,
    Parameters<typeof fetchLoadBalancingProfileDelete>
  >,
) {
  return useMutation((args) => fetchLoadBalancingProfileDelete(...args), {
    retry: 0,
    ...options,
  });
}
