import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargePointsDevicesLogs } from '../fetches';
import { Requests, Responses } from '../types';

export const USE_CHARGE_POINT_DEVICES_LOGS_QUERY_KEY =
  'charge.point.devices.logs';

export function useChargePointsDevicesLogsQuery(
  partnerId: string,
  chargePointId: string,
  params?: Requests.ReqChargePointsDevicesLogsParams,
  options?: UseQueryOptions<
    Responses.ResChargePointsDevicesLog,
    Types.HttpError,
    Responses.ResChargePointsDevicesLog,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_POINT_DEVICES_LOGS_QUERY_KEY, partnerId, chargePointId, params],
    () => fetchChargePointsDevicesLogs(partnerId, chargePointId, params),
    {
      refetchInterval: false,
      ...options,
    },
  );
}
