import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchTransactionMetrics } from '../fetches';
import { ResTransactionMetrics } from '../types/responses';

export const USE_TRANSACTION_METRICS_QUERY_KEY = 'transaction.metrics';

export function useTransactionMetricsQuery(
  partnerId: string,
  transactionId?: string,
  index?: number,
  size?: number,
  options?: UseQueryOptions<
    ResTransactionMetrics,
    Types.HttpError,
    ResTransactionMetrics,
    any[]
  >,
) {
  return useQuery(
    [USE_TRANSACTION_METRICS_QUERY_KEY, transactionId, index, size],
    () => fetchTransactionMetrics(partnerId, transactionId!, index, size),
    {
      enabled: !!transactionId,
      ...options,
    },
  );
}
