import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Button, Col, Form, Input, Popconfirm, Row, Select } from 'antd';
import {
  useChargePointsUpdateMutation,
  useChargePointUnbindMutation,
} from 'features/charge-points/queries';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';

const MAX_SECONDS = 3600; // 1 hour
const MAX_HANGING_NAME = 'maxHangingActiveSec';
const MAX_PENDING_NAME = 'maxPendingSec';
const OUTGOING_ROAMING = 'outgoingRoaming';

type FormValues = {
  [MAX_HANGING_NAME]: number | '';
  [MAX_PENDING_NAME]: number | '';
  [OUTGOING_ROAMING]: string[];
};

export const SettingsTab = () => {
  const [form] = Form.useForm();
  const mutation = useChargePointsUpdateMutation();
  const unbindMutation = useChargePointUnbindMutation();
  const partnerId = usePartnerId();
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isDisable, setDisabled] = useState(true);

  const isSubpartner = useIsSubpartner();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const { chargePoint } = useChargePointsDetailsContext();

  const formInitialValues: FormValues = {
    [MAX_HANGING_NAME]: chargePoint?.transCfg?.maxHangingActiveSec / 60 || '',
    [MAX_PENDING_NAME]: chargePoint?.transCfg?.maxPendingSec / 60 || '',
    [OUTGOING_ROAMING]: chargePoint?.roamingEnabled ?? [],
  };

  const onFinish = (values: FormValues) => {
    setError('');

    const maxHangingActiveSec = values[MAX_HANGING_NAME]
      ? values[MAX_HANGING_NAME] * 60
      : 0;
    const maxPendingSec = values[MAX_PENDING_NAME]
      ? values[MAX_PENDING_NAME] * 60
      : 0;
    const outgoingRoaming = values[OUTGOING_ROAMING];

    if (maxHangingActiveSec > MAX_SECONDS || maxPendingSec > MAX_SECONDS) {
      setError(t('chargepoints.errors.max-wait-hours'));
      return;
    }

    if (maxHangingActiveSec < 0 || maxPendingSec < 0) {
      setError(t('chargepoints.errors.non-negative-value'));
      return;
    }

    const data = {
      id: chargePoint.id,
      name: chargePoint.name,
      deviceId: chargePoint.device?.deviceId,
      partnerId: chargePoint.partner?.id,
      transCfg: {
        maxHangingActiveSec,
        maxPendingSec,
      },
      roamingEnabled: outgoingRoaming,
      location: chargePoint.location,
    };
    mutation.mutate(data, {
      onError: (error) => {
        setError(errorT(error?.translationKey, error?.message));
      },
    });
    setDisabled(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSelectChange = (value: string | string[]) => {
    if (value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const unbindChargePoint = async () => {
    unbindMutation.mutate([partnerId, chargePoint.id], {
      onError: (error) => {
        setError(errorT(error?.translationKey, error?.message));
      },
      onSuccess: () => {
        navigate('/points');
      },
    });
  };

  return (
    <div>
      {error && <p style={{ color: 'red', marginBottom: 10 }}>{error}</p>}
      <Form
        form={form}
        layout={'vertical'}
        name="charge-point-settings-form"
        onFinish={onFinish}
        initialValues={formInitialValues}
        disabled={isSubpartner}
      >
        <Form.Item
          name={MAX_HANGING_NAME}
          label={`${t('chargepoints.labels.max-hanging-time')}:`}
        >
          <Input
            type="number"
            placeholder={t('chargepoints.placeholders.in-minutes')}
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item
          name={MAX_PENDING_NAME}
          label={`${t('chargepoints.labels.max-pending-time')}:`}
        >
          <Input
            type="number"
            placeholder={`${t('chargepoints.placeholders.in-minutes')}:`}
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item
          name={OUTGOING_ROAMING}
          label={`${t('chargepoints.labels.select-outgoing-roaming')}:`}
        >
          <Select
            placeholder={t('chargepoints.placeholders.select-outgoing-roaming')}
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            options={[{ value: 'ocpi', label: 'OCPI' }]}
            onChange={handleSelectChange}
          ></Select>
        </Form.Item>
        <Row justify="space-between">
          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={mutation.isLoading}
                className="login-form-button"
                disabled={isDisable}
              >
                {t('common.actions.save-changes')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Popconfirm
              title={t('chargepoints.text.unbind-chargepoint')}
              placement="topLeft"
              description={
                <p>
                  {t('chargepoints.text.unbind-chargepoint.warning1')}
                  <br />
                  {t('chargepoints.text.unbind-chargepoint.warning2')}
                </p>
              }
              okText={t('chargepoints.text.yes')}
              cancelText={t('common.actions.cancel')}
              onConfirm={unbindChargePoint}
            >
              <Button
                type="default"
                htmlType="button"
                loading={unbindMutation.isLoading}
                disabled={isSubpartner}
              >
                {t('chargepoints.actions.unbind-chargepoint')}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
