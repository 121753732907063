import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { ResLoadBalancingProfileDeactivate } from '../types/responses';
import { fetchLoadBalancingProfileDeactivate } from '../fetches';

export function useLoadBalancingProfileDeactivateMutation(
  options?: UseMutationOptions<
    ResLoadBalancingProfileDeactivate,
    Types.HttpError,
    Parameters<typeof fetchLoadBalancingProfileDeactivate>
  >,
) {
  return useMutation((args) => fetchLoadBalancingProfileDeactivate(...args), {
    retry: 0,
    ...options,
  });
}
