import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagsSearch } from '../fetches';
import { ReqChargeTagsSearchParams } from '../types/requests';
import { ResChargeTagsSearch } from '../types/responses';

export const USE_CHARGE_TAGS_SEARCH_QUERY_KEY = 'charge.tags.search';

export function useChargeTagsSearchQuery(
  partnerId: string,
  params?: ReqChargeTagsSearchParams,
  options?: UseQueryOptions<
    ResChargeTagsSearch,
    Types.HttpError,
    ResChargeTagsSearch,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_TAGS_SEARCH_QUERY_KEY, params],
    () => fetchChargeTagsSearch(partnerId, params),
    options,
  );
}
