import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Select, Layout } from 'antd';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Logo } from 'views/Logo';
import { useAuth, useAuthLogin } from 'features/auth/hooks';
import { useAuthRegisterMutation } from 'features/auth/queries';
import { getCountriesSelectOptions } from 'core/utils/countryUtils';
import { useCountries } from 'features/catalog/queries';

import styles from './Register.module.css';

const Register = () => {
  const { isAuthed } = useAuth();
  const login = useAuthLogin();
  const register = useAuthRegisterMutation();
  const { data: countries } = useCountries();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const alpha3SelectOptions = getCountriesSelectOptions(countries || []);

  const handleRegister = ({ password, email, countryCode }: any) => {
    register
      .mutateAsync({
        password,
        countryCode,
        contacts: {
          email: {
            contact: email,
          },
        },
      })
      .then(() => {
        login.login({ password, username: email });
      })
      .catch((e) => {
        console.log('Failed to register', e?.message);
      });
  };

  console.log('register error', register.error);

  const hasError = !!register.error || !!login.error;
  const errorMessage = hasError
    ? errorT(
        register.error?.translationKey ?? login.error?.translationKey ?? '',
        register.error?.message ?? login.error?.message,
      )
    : '';

  if (isAuthed) {
    return <Navigate to={'/'} />;
  }

  return (
    <Layout className={styles.register_container}>
      <div className={styles.register_content}>
        <div className={styles.register_logo}>
          <Logo />
        </div>

        <Form
          layout={'vertical'}
          initialValues={{ remember: true }}
          onFinish={handleRegister}
        >
          <Form.Item
            name="email"
            label={`${t('register.labels.email-address')}:`}
            rules={[
              {
                required: true,
                message: t('register.rules.enter-email'),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t('register.placeholders.email')}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={`${t('register.labels.password')}:`}
            rules={[
              {
                required: true,
                message: t('register.rules.enter-password'),
              },
            ]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder={t('register.placeholders.password')}
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item
            name="countryCode"
            label={`${t('register.labels.country')}:`}
            rules={[
              {
                required: true,
                message: t('register.rules.select-country'),
              },
            ]}
          >
            <Select
              options={alpha3SelectOptions}
              placeholder={t('register.placeholders.select-country')}
            />
          </Form.Item>

          {(!!register.error || !!login.error) && (
            <Form.Item>
              <Alert type={'error'} description={errorMessage} />
            </Form.Item>
          )}

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={login.isLoading || register.isLoading}
              className="register-form-button"
            >
              {t('register.actions.register')}
            </Button>
          </Form.Item>
        </Form>

        <Divider />

        <div style={{ textAlign: 'center' }}>
          <Link to="/login">{t('register.actions.login-link')}</Link>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
