export interface IStorageService {
  /**
   * Returns data by key from storage.
   *
   * @param key - Storage key
   */
  get: (key: string) => string | null;

  /**
   * Returns data by key from storage as an object (parsed JSON).
   *
   * @param key - Storage key
   *
   * @template T - The type of data stored in the storage
   */
  getJson: <T extends Record<string, any> = any>(key: string) => T | null;

  /**
   * Saves data by key to storage.
   *
   * @param key - Storage key
   * @param data - Data
   */
  set: (key: string, data: string) => void;

  /**
   * Saves data (object) by key to storage.
   *
   * @param key - Storage key
   * @param data - Data
   *
   * @template T - The type of data stored in the storage
   */
  setJson: <T extends Record<string, any> = any>(key: string, data: T) => void;

  /**
   * Deletes data by key from storage.
   *
   * @param key - Storage key
   */
  remove: (key: string) => void;
}

/**
 * Service for working with data storage.
 */
export class StorageService implements IStorageService {
  public get(key: string) {
    return localStorage.getItem(key);
  }

  public getJson<T = any>(key: string) {
    const data = this.get(key);
    if (data == null || data === 'undefined') {
      return null;
    }
    return JSON.parse(data) as T;
  }

  public set(key: string, data: string | any) {
    if (typeof data !== 'string') {
      return this.setJson(key, data);
    }

    return localStorage.setItem(key, data);
  }

  public setJson<T = any>(key: string, data: T) {
    return localStorage.setItem(key, JSON.stringify(data));
  }

  public remove(key: string) {
    return localStorage.removeItem(key);
  }
}
