import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Typography,
} from 'antd';
import { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useChargeTagCreateMutation,
  USE_CHARGE_TAGS_SEARCH_QUERY_KEY,
} from 'features/charge-tags/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import styles from './TagsCreate.module.css';

export function TagsCreate() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const create = useChargeTagCreateMutation();
  const partnerId = usePartnerId();
  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId,
  });

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const handleCreate = (values: {
    tag: string;
    expiresAt: Dayjs;
    hashTags: string[];
  }) => {
    create
      .mutateAsync({
        tag: values.tag,
        partnerId,
        expireAt: values.expiresAt.toISOString(),
        hashTags: values.hashTags,
      })
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_TAGS_SEARCH_QUERY_KEY]);
        navigate('/tags');
      });
  };

  if (hashtagsQuery.isLoading) {
    return <Spin />;
  }

  return (
    <div>
      <div className={styles.tags_create_header}>
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t('rfid_tags.titles.create-tag')}
          </Typography.Title>
        </div>
        <div></div>
      </div>
      <div className={styles.tags_create_content}>
        <Form
          form={form}
          layout={'vertical'}
          name="control-hooks"
          onFinish={handleCreate}
        >
          <Form.Item
            name="tag"
            label={`${t('rfid_tags.labels.tag')}:`}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="expiresAt"
            label={`${t('rfid_tags.labels.expire-date')}:`}
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="hashTags" label={`${t('rfid_tags.labels.groups')}:`}>
            <Select
              mode="tags"
              placeholder={t('rfid_tags.placeholders.select-groups')}
              options={(hashtagsQuery.data ?? [])
                .filter(Boolean)
                .map((hashtag) => ({
                  label: hashtag,
                  value: hashtag,
                }))}
            />
          </Form.Item>
          {!!create.error && (
            <Form.Item>
              <Alert
                type={'error'}
                description={errorT(
                  create.error?.translationKey,
                  create.error?.message,
                )}
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={create.isLoading}>
              {t('common.actions.submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
