import * as Services from 'core/services';
import * as Types from 'core/types';

import { Translations } from '../types';

const loadWebTranslations = async (language: string) => {
  return Services.apiService.get<Translations['web']>(
    `catalog/translations/category/web`,
    {
      headers: {
        'X-Language': language,
      },
    },
  );
};

const loadErrorTranslations = async (language: string) => {
  return Services.apiService.get<Translations['errors']>(
    `catalog/translations/category/errors`,
    {
      headers: {
        'X-Language': language,
      },
    },
  );
};

export async function fetchTranslations(language: string) {
  try {
    const [webTranslations, errorTranslations] = await Promise.all([
      loadWebTranslations(language),
      loadErrorTranslations(language),
    ]);

    return {
      web: webTranslations.data,
      errors: errorTranslations.data,
    };
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}

