import { Services } from 'core';
import { AuthHelpers } from 'features/auth';
import { fetchPartnerFileLink } from 'features/files/fetches';

type DownloadExcelFileParams = {
  partnerId: string;
  fileId: string;
  fileName?: string;
};

export const downloadExcelFile = async ({
  partnerId,
  fileId,
  fileName = 'report.xlsx',
}: DownloadExcelFileParams) => {
  const header = await AuthHelpers.authHeader();

  const fileLink = await fetchPartnerFileLink(partnerId, fileId);
  const response = await Services.apiService.get<Blob>(fileLink, {
    headers: { ...header },
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
