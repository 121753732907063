import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { GroupTariff, PartnerTariffGroup } from '../types';

export async function fetchPartnerTariffCopy<
  TResponse = PartnerTariffGroup,
  TError = Types.HttpError,
>(params: { partnerId: string; tariffId: GroupTariff['id'] }) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `/partners/${params.partnerId}/tariffs/${params.tariffId}/copy`,
      null,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
