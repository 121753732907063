import { message, Table } from 'antd';

import { useChargePointsTableColumns } from './useChargePointsTableColumns';
import styles from './ChargePointsTable.module.css';
import type { ChargePointsTableProps } from './types';

export const ChargePointsTable = ({
  partnerId,
  chargePoints,
  onSuccess,
  isLoading,
}: ChargePointsTableProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const columns = useChargePointsTableColumns({
    messageApi,
    partnerId,
    onSuccess,
  });

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={false}
        className={styles.charge_point__table}
        dataSource={chargePoints?.map((item) => ({
          key: item.id,
          name: item.name,
          ownerPartnerName: item?.ownerPartnerName,
          vendor: item.device?.chargePointVendor,
          deviceId: item?.device?.deviceId ?? '-',
          tariffGroup: {
            name: item.tariffGroupName ?? '-',
            id: item.tariffGroupId ?? '-',
          },
          chargePointSerialNumber: item.device.chargePointSerialNumber ?? '-',
          chargePointModel: item.device.chargePointModel ?? '-',
          currency: item.currency,
          address: item?.location?.address ?? '-',
          connectors: item?.connectors,
          item: item,
          partnerId: item?.partner?.id,
          status: item?.status,
          hashTags: item?.hashTags ?? [],
          emergencyButtonPressed: Boolean(item?.emergencyButtonTime),
          ocppVersion: item?.ocppVersion ?? '-',
        }))}
        scroll={{ x: 1300 }}
      />
    </>
  );
};
