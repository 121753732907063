import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { useChargePointsUpdateMutation } from 'features/charge-points/queries';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import {
  BusinessDetailsKeys,
  ChargePointPartnerItemBusinessDetails,
} from 'features/charge-points/types/ChargePointPartnerItem';
import { enumKeys } from 'core/utils/selectUtils';

import { BusinessDetailsUpdateFormProps } from './types';
import { useBusinessDetailsLabels } from './useBusinessDetailsLabels';

export function BusinessDetailsUpdateForm({
  isOpen,
  onCancel,
  onSuccess,
}: BusinessDetailsUpdateFormProps) {
  const [form] = Form.useForm();

  const update = useChargePointsUpdateMutation();
  const { chargePoint, updateCache } = useChargePointsDetailsContext();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const initialValues = enumKeys(BusinessDetailsKeys).reduce((values, key) => {
    return {
      ...values,
      [key]: chargePoint?.businessDetails?.[key] ?? undefined,
    };
  }, {} as ChargePointPartnerItemBusinessDetails);

  const formLabels = useBusinessDetailsLabels();

  const handleUpdate = (values: ChargePointPartnerItemBusinessDetails) => {
    update.mutate(
      {
        id: chargePoint?.id,
        deviceId: chargePoint?.device?.deviceId,
        partnerId: chargePoint?.partner?.id,
        businessDetails: values,
      },
      {
        onSuccess: (data) => {
          updateCache(data);
          onSuccess();
        },
      },
    );
  };
  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      title={t('chargepoints.titles.business-details')}
      footer={[
        <Button key={'cancel'} type={'default'} onClick={onCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'save'}
          type={'primary'}
          htmlType={'submit'}
          form={'business-details-form'}
          loading={update.isLoading}
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      <Form
        id={'business-details-form'}
        name="basic"
        form={form}
        layout="vertical"
        style={{ widows: '100%' }}
        initialValues={initialValues}
        onFinish={handleUpdate}
        autoComplete="off"
      >
        {enumKeys(formLabels).map((key) => {
          return (
            <Form.Item
              label={`${formLabels[key]}:`}
              key={key}
              name={key}
              normalize={(value) => (value ? parseFloat(value) : undefined)}
            >
              <Input type="number" step="0.01" min={0} max={9999999} />
            </Form.Item>
          );
        })}
      </Form>
      {!!update.error && (
        <Form.Item>
          <Alert
            type={'error'}
            description={errorT(
              update.error?.translationKey,
              update.error?.message,
            )}
          />
        </Form.Item>
      )}
    </Modal>
  );
}
