import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchCreateRegularReportTask } from '../fetches';
import type {
  CreateRegularReportTaskRequest,
  RegularReportTask,
} from '../types';

export function useCreateRegularReportTaskMutation(
  options?: UseMutationOptions<
    RegularReportTask,
    Types.HttpError,
    CreateRegularReportTaskRequest
  >,
) {
  return useMutation(fetchCreateRegularReportTask, {
    retry: 0,
    ...options,
  });
}
