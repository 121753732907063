import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchChargePointsSearch } from '../fetches';
import { Requests, Responses } from '../types';

export const USE_CHARGE_POINTS_SEARCH_QUERY_KEY = 'charge.points.search';

export function useChargePointsSearchQuery(
  partnerId?: string,
  params?: Requests.ReqChargePointsPartnerItemsParams,
  options?: UseQueryOptions<
    Responses.ResChargePointsPartnerItemsParams,
    Types.HttpError,
    Responses.ResChargePointsPartnerItemsParams,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_POINTS_SEARCH_QUERY_KEY, params],
    () => fetchChargePointsSearch(partnerId, params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
