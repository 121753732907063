import { useMemo } from 'react';
import { Button, Checkbox, Empty, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePartner } from 'core/providers/PartnerProvider';
import {
  getCurrenciesFromCountries,
  getCurrenciesSelectOptions,
} from 'core/utils';
import { useCountries } from 'features/catalog';
import { ResPartner } from 'features/partners/types/responses';

import { useProfileData } from './useProfileUpdate';

export interface LimitsUpdateFormProps {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
}

export function LimitsUpdateForm({
  isOpen,
  onCancel,
  onSuccess,
  onError,
}: LimitsUpdateFormProps) {
  const partner = usePartner();
  const [form] = Form.useForm();
  const [updateProfile, { isLoading }] = useProfileData({ partner });
  const { t } = useTranslation();

  const { data: countries } = useCountries();

  const currenciesOptions = useMemo(() => {
    const currencies = getCurrenciesFromCountries(countries ?? []);

    return getCurrenciesSelectOptions(currencies);
  }, [countries]);

  const handleUpdate = ({
    maxDebtAmount,
    currency,
    enabled,
  }: ResPartner['limits']['clientDebt']) => {
    if (!partner) {
      return;
    }

    updateProfile({
      limits: {
        clientDebt: {
          maxDebtAmount: Number(maxDebtAmount),
          currency,
          enabled,
        },
      },
    })
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const renderForm = () => {
    if (!partner) {
      return <Empty />;
    }

    return (
      <Form
        form={form}
        id={'profile-limits-form'}
        layout={'vertical'}
        initialValues={partner?.limits?.clientDebt}
        onFinish={handleUpdate}
      >
        <Form.Item
          name={'maxDebtAmount'}
          label={t('profile.limits.max-amount')}
          rules={[{ required: true, message: 'Value is required' }]}
        >
          <Input type="number" min={0} max={9999999} />
        </Form.Item>

        <Form.Item
          name={'currency'}
          label={t('profile.limits.currency')}
          rules={[
            {
              required: true,
              message: t('common.rules.currency'),
            },
          ]}
        >
          <Select options={currenciesOptions} />
        </Form.Item>

        <Form.Item name={'enabled'} valuePropName="checked">
          <Checkbox>{t('profile.limits.enabled')}</Checkbox>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={isOpen}
      title={t('profile.limits.update-limits')}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} type={'default'} onClick={onCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          htmlType={'submit'}
          form={'profile-limits-form'}
          loading={isLoading}
          onClick={() => form.submit()}
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      {renderForm()}
    </Modal>
  );
}
