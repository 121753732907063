import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqChargeTagUpdateData } from '../types/requests';
import { ResChargeTagUpdate } from '../types/responses';

export async function fetchChargeTagUpdate<
  TResponse = ResChargeTagUpdate,
  TError = Types.HttpError,
>(partnerId: string, tagId: string, data: ReqChargeTagUpdateData) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.put<TResponse>(
      `partners/${partnerId}/charging/tags/${tagId}`,
      data,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
