import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { Amount, Date, PaymentDate } from './types';

export const usePaymentHistoryTableColumns = (): ColumnsType<
  Record<string, any>
> => {
  const { t } = useTranslation();

  return [
    {
      title: t('clients.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: Amount) =>
        amount ? new Intl.NumberFormat('ru-Ru').format(amount) : '-',
    },
    {
      title: t('clients.columns.vat-amount'),
      dataIndex: 'vatAmount',
      key: 'vatAmount',
    },
    {
      title: t('clients.columns.currency'),
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: t('clients.columns.date'),
      dataIndex: 'date',
      key: 'date',
      render: (date: Date) =>
        date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('clients.columns.payment-date'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (paymentDate: PaymentDate) =>
        paymentDate ? dayjs(paymentDate).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('clients.columns.number'),
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: t('clients.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
  ];
};
