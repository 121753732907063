export interface ChargePoint {
  authorizeAt: string;
  clientId: string;
  expireAt: string;
  id: string;
  tag: string;
  type: string;
}

export const connectorTypesList = [
  'ccs',
  'ccs2-combo',
  'chademo',
  'gbt-ac',
  'gbt-dc',
  'shuko',
  'tesla-r',
  'tesla-s',
  'type-2',
  'type-1',
  'type-3',
  'type-3c',
  'chaoji',
  'plug-a2',
  'plug-b3',
  'plug-c2',
  'plug-d3',
  'plug-e3',
  'plug-g3',
  'plug-h3',
  'plug-i3',
  'plug-j3',
  'plug-k3',
  'plug-l3',
  'plug-m3',
  'plug-n3',
  'plug-o3',
  'iec-309-blue-16',
  'iec-309-red-16',
  'iec-309-red-32',
  'iec-309-blue-64',
  'nema-5-20',
  'nema-6-30',
  'nema-6-50',
  'nema-10-30',
  'nema-10-50',
  'nema-14-30',
  'nema-14-50',
  'bus-up',
  'bus-down',
] as const;

type DeprecatedConnectorTypes = 'tesla' | 'gbt';
export type ConnectorType =
  | (typeof connectorTypesList)[number]
  | DeprecatedConnectorTypes;
