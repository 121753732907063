import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqPartnerSubscriptionsParams } from '../types/requests';
import { ResPartnersSubscriptions } from '../types/responses';

export async function fetchPartnersSubscriptions<
  TResponse = ResPartnersSubscriptions,
  TError = Types.HttpError,
>({
  partnerId,
  ...params
}: { partnerId: string } & ReqPartnerSubscriptionsParams) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `/partners/${partnerId}/subscriptions`,
      { headers: { ...header }, params },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
