import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';
import { omit } from 'lodash-es';

import { GenerateReportResponse, GenerateReportRequest } from '../types';

/**
 * Generate report
 */
export async function fetchGenerateReport(data: GenerateReportRequest) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<GenerateReportResponse>(
      `partners/${data.partnerId}/reports/generate`,
      null,
      {
        headers: { ...header },
        params: omit(data, 'partnerId'),
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
