import { WebTranslateKey } from 'i18n/types';

import { TransactionStatus, TransactionStatusGroup } from './types';

export const mapTransactionGroupToStatus = {
  [TransactionStatusGroup.ACTIVE]: [
    TransactionStatus.ACCEPTED,
    TransactionStatus.ACTIVE,
  ],
  [TransactionStatusGroup.SUCCESSFUL]: [
    TransactionStatus.FINISHED_OK,
    TransactionStatus.STOPPED_BY_CP,
  ],
  [TransactionStatusGroup.CANCELLED]: [
    TransactionStatus.CANCELLED_REQUEST,
    TransactionStatus.CANCELLED_ACTIVE,
  ],
};

export const mapTransactionTagToTranslationKey: Record<
  TransactionStatusGroup,
  WebTranslateKey
> = {
  [TransactionStatusGroup.ACTIVE]: 'transactions.filter.only-active',
  [TransactionStatusGroup.SUCCESSFUL]: 'transactions.filter.successful',
  [TransactionStatusGroup.CANCELLED]: 'transactions.filter.cancelled',
};

export const mapTransactionStatusToTranslationKey: Record<
  TransactionStatus,
  WebTranslateKey
> = {
  [TransactionStatus.REQUEST]: 'transactions.status.request',
  [TransactionStatus.ACCEPTED]: 'transactions.status.accepted',
  [TransactionStatus.REJECTED]: 'transactions.status.rejected',
  [TransactionStatus.ACTIVE]: 'transactions.status.active',
  [TransactionStatus.FINISHED_OK]: 'transactions.status.finished',
  [TransactionStatus.FAILED]: 'transactions.status.failed',
  [TransactionStatus.STOPPED_BY_CP]: 'transactions.status.stopped',
  [TransactionStatus.CANCELLED_REQUEST]:
    'transactions.status.cancelled-request',
  [TransactionStatus.CANCELLED_ACTIVE]: 'transactions.status.cancelled-active',
};
