import { Button, Popconfirm, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { PendingInvitationsProps } from './types';

export const PendingInvitations = ({
  invitations,
  cancelInvitation,
}: PendingInvitationsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: 16 }}>
        {t('subpartners.titles.pending-invitations')}
      </Typography.Title>
      <Table
        dataSource={invitations}
        size="small"
        pagination={false}
        columns={[
          {
            title: t('subpartners.columns.email'),
            dataIndex: 'email',
            key: 'email',
          },
          {
            dataIndex: 'url',
            key: 'url',
            render: (url: string) => (
              <Typography.Link href={url} copyable={{ text: url }} title={url}>
                {t('subpartners.text.invitation-link')}
              </Typography.Link>
            ),
          },
          {
            key: 'action',
            render: (item: any) => (
              <Popconfirm
                title={t('subpartners.text.cancel-invitation-confirmation')}
                onConfirm={() => cancelInvitation(item.token)}
                okText={t('subpartners.text.yes')}
                cancelText={t('subpartners.text.no')}
              >
                <Button type="default">{t('common.actions.cancel')}</Button>
              </Popconfirm>
            ),
          },
        ]}
      />
    </>
  );
};
