import {
  GoogleMap,
  MarkerF as Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useEffect, useRef } from 'react';
import { googleMapsApiOptions } from 'pages/ChargePointsDetails/tabs/LocationTab/google-map-options';

import s from './Map.module.css';

export type Coordinates = { lat: number; lng: number };

export interface MapProps {
  markerCoordinates: Coordinates;
  initialCoordinates: Coordinates;
  onReady?: (map: google.maps.Map) => void;
  onChangeCoordinates: (args: Coordinates) => void;
}

export function Map({
  markerCoordinates,
  initialCoordinates,
  onReady,
  onChangeCoordinates,
}: MapProps) {
  const centerRef = useRef(initialCoordinates);
  const mapRef = useRef<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader(googleMapsApiOptions);

  const handleClick = (event: google.maps.MapMouseEvent) => {
    if (!event.latLng) {
      return;
    }

    const { lat, lng } = event.latLng;
    onChangeCoordinates({ lat: lat(), lng: lng() });
  };

  const handleInit = (map: google.maps.Map) => {
    mapRef.current = map;
    onReady?.(map);
  };

  useEffect(() => {
    try {
      mapRef.current?.panTo(markerCoordinates);
    } catch (e) {
      console.warn(e);
    }
  }, [markerCoordinates]);

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      zoom={12}
      center={centerRef.current}
      mapContainerClassName={s.map}
      onLoad={handleInit}
      onClick={handleClick}
    >
      <Marker position={markerCoordinates} />
    </GoogleMap>
  );
}
