import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagCreate } from '../fetches';
import { ReqChargeTagCreateData } from '../types/requests';
import { ResChargeTagCreate } from '../types/responses';

export function useChargeTagCreateMutation(
  options?: UseMutationOptions<
    ResChargeTagCreate,
    Types.HttpError,
    ReqChargeTagCreateData
  >,
) {
  return useMutation(fetchChargeTagCreate, {
    retry: 0,
    ...options,
  });
}
