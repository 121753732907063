import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';
import * as Helpers from 'core/helpers';

import {
  GetGeneRatedReportsParams,
  GetGeneratedReportsResponse,
} from '../types';

/**
 * Get all generated reports
 */
export async function fetchGetGeneratedReports(
  partnerId: string,
  params?: GetGeneRatedReportsParams,
  pagination?: Helpers.RequestPagination,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<GetGeneratedReportsResponse>(
      `partners/${partnerId}/reports/scheduled/generated/search/query`,
      {
        params: { ...params, ...Helpers.requestPagination(pagination) },
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
