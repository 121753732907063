import { useTranslation } from 'react-i18next';
import { useLoadTranslations } from 'features/translations';

export const useSetupTranslations = () => {
  const { i18n } = useTranslation();
  const selectedLanguage = i18n.languages[0];

  const { data, isLoading } = useLoadTranslations(selectedLanguage);
  if (data) {
    i18n.addResourceBundle(selectedLanguage, 'web', data.web);
    i18n.addResourceBundle(selectedLanguage, 'errors', data.errors);
  }

  return { isLoading, i18n };
};
