import { Button, Form, FormProps, message, Modal, Select } from 'antd';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useTranslation } from 'react-i18next';
import {
  CreateRegularReportTaskRequest,
  useCreateRegularReportTaskMutation,
} from 'features/reports';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { HttpError } from 'core/types';
import {
  reportPeriodOptions,
  reportTypeOptions,
} from 'pages/Reports/helpers/formOptions';

import { CreateReportTaskModalProps, CreateReportTaskFormField } from './types';

export const CreateReportTaskModal = ({
  isOpen,
  onCancel,
  onSuccess,
}: CreateReportTaskModalProps) => {
  const [form] = Form.useForm();
  const partnerId = usePartnerId();

  const { mutateAsync, isLoading } = useCreateRegularReportTaskMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const [messageApi, contextHolder] = message.useMessage();

  const showError = (error: HttpError) => {
    messageApi.open({
      type: 'error',
      content: errorT(error?.translationKey, error.message),
    });
  };

  const submitForm: FormProps<CreateReportTaskFormField>['onFinish'] = async (
    values,
  ) => {
    const data: CreateRegularReportTaskRequest = {
      type: values.reportType,
      format: 'excel',
      period: values.reportPeriod,
      partnerId,
    };

    try {
      const newReport = await mutateAsync(data);
      messageApi.success('Task has been scheduled');

      form.resetFields();
      onSuccess(newReport);
    } catch (error) {
      showError(error as HttpError);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={onCancel}
        title={t('reports.titles.task-regular-report')}
        footer={[
          <Button key={'cancel'} onClick={onCancel}>
            {t('common.actions.cancel')}
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            htmlType={'submit'}
            form={'generate-report-form'}
            loading={isLoading}
          >
            {t('common.actions.create')}
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          id={'generate-report-form'}
          onFinish={submitForm}
        >
          <Form.Item<CreateReportTaskFormField>
            label={`${t('reports.labels.period')}:`}
            name="reportPeriod"
            rules={[{ required: true, message: t('reports.rules.period') }]}
          >
            <Select
              placeholder={t('reports.placeholders.period')}
              options={reportPeriodOptions.map((item) => ({
                label: t(item.translateKey),
                value: item.value,
              }))}
            ></Select>
          </Form.Item>
          <Form.Item<CreateReportTaskFormField>
            label={`${t('reports.labels.report-type')}:`}
            name="reportType"
            rules={[
              { required: true, message: t('reports.rules.report-type') },
            ]}
          >
            <Select
              placeholder={t('reports.placeholders.report-type')}
              options={reportTypeOptions.map((item) => ({
                label: t(item.translateKey),
                value: item.value,
              }))}
            ></Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
