import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnersSubscriptionTariffs } from '../fetches';
import { ResPartnersSubscriptionTariffs } from '../types/responses';

export const USE_PARTNER_SUBSCRIPTION_TARIFFS_QUERY_KEY =
  'partners.subscriptions.tariffs';

export function usePartnersSubscriptionTariffsQuery(
  params: Parameters<typeof fetchPartnersSubscriptionTariffs>[0],
  options?: UseQueryOptions<
    ResPartnersSubscriptionTariffs,
    Types.HttpError,
    ResPartnersSubscriptionTariffs,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_SUBSCRIPTION_TARIFFS_QUERY_KEY, params],
    () => fetchPartnersSubscriptionTariffs(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
