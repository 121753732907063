import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { ChargePointsTypes } from '..';
import { fetchChargePointsUpdate } from '../fetches';

export function useChargePointsUpdateMutation(
  options?: UseMutationOptions<
    ChargePointsTypes.Responses.ResChargePoint,
    Types.HttpError,
    ChargePointsTypes.Requests.ReqChargePointsUpdate
  >,
) {
  return useMutation(fetchChargePointsUpdate, {
    retry: 0,
    ...options,
  });
}
