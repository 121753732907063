import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthServices } from 'features/auth';
import i18next from 'i18next';

/**
 * Axios instance.
 */
export type ApiService = AxiosInstance;
/**
 * Api service config - ApiService.
 */
export type ApiServiceConfig = AxiosRequestConfig;
/**
 * Api service response.
 *
 * @template T - Response data.
 */
export type ApiServiceResponse<T = any> = Promise<AxiosResponse<T>>;

export const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    ApiKey: process.env.REACT_APP_API_KEY,
  },
});

/* Set language header for all api requests */
apiService.interceptors.request.use((config) => {
  const language = i18next.languages[0] ?? 'en';
  config.headers['X-Language'] = language;

  return config;
});

apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      AuthServices.TokenService.removeSession();
      window.location.href = '/login';
    }
    throw error;
  },
);
