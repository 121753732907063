import styles from './Description.module.css';

type DescriptionProps = {
  title: string;
  data: {
    label: string;
    value: string | number | JSX.Element;
  }[];
  actions?: JSX.Element;
};

export const Description = ({ title, data, actions }: DescriptionProps) => {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.row}>
        {data.map((item) => (
          <div key={item.label} className={styles.item}>
            <div className={styles.label}>{item.label}:</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        ))}
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

