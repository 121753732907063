import { FC, memo, useState, useEffect } from 'react';
import cn from 'classnames';
import {
  Spin,
  Typography,
  Modal,
  Button,
  Form,
  Space,
  Alert,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Padding } from 'components/Padding';
import {
  usePartnerTariffGroupTariffUpdateMutation,
  usePartnerTariffGroupTariffCreateMutation,
} from 'features/partners-tariffs-groups/queries';
import { useParams, useNavigate } from 'react-router-dom';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerTariffGroupTariffQuery } from 'features/partners-tariffs-groups/queries/usePartnerTariffGroupTariffQuery';
import { TariffForm } from 'pages/Pricing/TariffDetails/TariffForm';
import { ElementsList } from 'pages/Pricing/TariffDetails/ElementsList';
import { TariffElement } from 'features/partners-tariffs-groups/types';
import { ElementForm } from 'pages/Pricing/TariffDetails/ElementForm';
import { isNotNull, isDefined, hasValue } from 'core/utils/typeguards';
import { TariffEditable } from 'pages/Pricing/TariffDetails/TariffForm/TariffForm.utils';
import {
  createTariffElement,
  updateElementsList,
  temporaryElementPrefix,
} from 'pages/Pricing/TariffDetails/TariffDetails.utils';
import { PricesView } from 'pages/Pricing/TariffDetails/ElementsList/PricesView';
import { RestrictionView } from 'pages/Pricing/TariffDetails/ElementsList/RestrictionView';
import { getTariffStatus, TariffStatus } from 'core/utils/tariffUtils';
import { useCountries } from 'features/catalog';
import { getCurrenciesFromCountries, defaultCurrency } from 'core/utils';
import {
  PlusOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';

import s from './TariffDetails.module.css';

type Props = {};

const { Title, Text } = Typography;

const TariffDetails: FC<Props> = ({}) => {
  const partnerId = usePartnerId();
  const { groupId, tariffId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const updateMutation = usePartnerTariffGroupTariffUpdateMutation();
  const createMutation = usePartnerTariffGroupTariffCreateMutation();

  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [elements, setElements] = useState<TariffElement[]>([]);
  const [editedElement, setEditedElement] = useState<TariffElement | null>(
    null,
  );

  const { data: countries } = useCountries();
  const currencies = countries ? getCurrenciesFromCountries(countries) : [];

  const isLoadData = !!tariffId && tariffId !== 'new';

  const { data: tariff, isLoading: isFormLoading } =
    usePartnerTariffGroupTariffQuery(
      { partnerId, tariffId: tariffId || '', groupId: groupId || '' },
      { enabled: isLoadData },
    );

  const isActive =
    hasValue(tariff) && getTariffStatus(tariff) === TariffStatus.active;
  const isArchived =
    hasValue(tariff) && getTariffStatus(tariff) === TariffStatus.archived;
  const isReadOnly = isActive || isArchived;
  const status = hasValue(tariff) ? getTariffStatus(tariff) : '';

  useEffect(() => {
    if (tariff) {
      setElements(tariff.elements || []);
      setCurrency(tariff.currency || defaultCurrency);
    }
  }, [tariff]);

  const [form] = Form.useForm();

  if (!isDefined(groupId)) {
    return null;
  }

  const handleElementSubmit = (updated: TariffElement) => {
    const nextElements = updateElementsList(elements, updated);
    setElements(nextElements);
    setEditedElement(null);
  };

  const handleSubmit = (partialTariff: TariffEditable) => {
    const elementsNoId = elements.map((el) => {
      if (el.id.startsWith(temporaryElementPrefix)) {
        return { ...el, id: undefined };
      }
      return el;
    });
    const fullTariff = { ...partialTariff, elements: elementsNoId };

    if (isLoadData) {
      updateMutation.mutate([{ partnerId, tariffId, groupId }, fullTariff]);
    } else {
      createMutation.mutate([{ partnerId, groupId }, fullTariff], {
        onSuccess: ({ id }) =>
          navigate(`../${id}`, { relative: 'path', replace: true }),
      });
    }
  };

  const handleElementDelete = (element: TariffElement) =>
    Modal.confirm({
      title: t('pricing.delete_element_modal.title'),
      content: (
        <Space direction={'vertical'} size={'small'}>
          <PricesView prices={element.tariffPrices} currencySymbol={currency} />
          {element.restrictions.map((r, idx) => (
            <RestrictionView key={idx} restriction={r} />
          ))}
        </Space>
      ),
      width: '600px',
      okText: t('pricing.delete_element_modal.submit'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk: () => setElements([...elements.filter((e) => e.id !== element.id)]),
      closable: true,
      autoFocusButton: 'cancel',
    });

  return (
    <div className={cn(s.wrapper)}>
      {isLoadData && isFormLoading ? (
        <Spin />
      ) : (
        <>
          {(isActive || isArchived) && (
            <Padding>
              <Alert
                message={
                  isActive
                    ? t('pricing.text.tariff-active')
                    : t('pricing.text.tariff-archived')
                }
                type="info"
                showIcon
              />
            </Padding>
          )}
          <Padding>
            <TariffForm
              tariff={tariff}
              onSubmit={handleSubmit}
              form={form}
              isReadOnly={isReadOnly}
              currencies={currencies}
              onCurrencyChange={setCurrency}
              status={status}
            />

            <div className={cn(s.box, s.spaceBetween)}>
              <Title level={5}>
                {t('pricing.titles.elements')}
                <Tooltip title={t('pricing.text.sort-elements')}>
                  <InfoCircleOutlined
                    style={{ marginLeft: 8, cursor: 'pointer' }}
                  />
                </Tooltip>
              </Title>
              <Button
                disabled={isReadOnly}
                onClick={() => setEditedElement(createTariffElement())}
              >
                <PlusOutlined /> {t('pricing.actions.add-element')}
              </Button>
            </div>

            <ElementsList
              list={elements}
              updateList={setElements}
              onElementClick={setEditedElement}
              onDelete={handleElementDelete}
              isReadOnly={isReadOnly}
              currencySymbol={currency}
            />

            <Space className={s.box}>
              <Button
                type={'primary'}
                disabled={isReadOnly}
                onClick={() => form.submit()}
                loading={updateMutation.isLoading || createMutation.isLoading}
              >
                {t('pricing.actions.save-draft')}
              </Button>
              {(createMutation.isSuccess || updateMutation.isSuccess) && (
                <Text type={'success'}>
                  <CheckCircleFilled /> {t('pricing.labels.draft-saved')}
                </Text>
              )}
              {createMutation.isError && (
                <Text type={'danger'}>
                  <ExclamationCircleFilled />
                  {errorT(
                    createMutation.error?.translationKey,
                    createMutation.error.message,
                  )}
                </Text>
              )}
              {updateMutation.isError && (
                <Text type={'danger'}>
                  <ExclamationCircleFilled />{' '}
                  {errorT(
                    updateMutation.error?.translationKey,
                    updateMutation.error.message,
                  )}
                </Text>
              )}
            </Space>
          </Padding>

          <Modal
            open={isNotNull(editedElement)}
            onCancel={() => setEditedElement(null)}
            footer={null}
            width={'90%'}
            destroyOnClose
          >
            {editedElement && (
              <ElementForm
                element={editedElement}
                currencySymbol={currency}
                onSubmit={handleElementSubmit}
                onClose={() => setEditedElement(null)}
                isReadOnly={isReadOnly}
              />
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default memo(TariffDetails);
