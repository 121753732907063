import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ResTransactionCalculations } from '../types/responses';

export async function fetchTransactionCalculations<
  TResponse = ResTransactionCalculations,
  TError = Types.HttpError,
>(partnerId: string, transactionId: string, withItems: boolean) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}/charging/transactions/${transactionId}/calculations`,
      {
        headers: { ...header },
        params: { withItems },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
