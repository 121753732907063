import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchTransaction } from '../fetches';
import { ResTransaction } from '../types/responses';

export const USE_TRANSACTION_QUERY_KEY = 'transaction';

export function useTransactionQuery(
  partnerId: string,
  transactionId?: string,
  options?: UseQueryOptions<
    ResTransaction,
    Types.HttpError,
    ResTransaction,
    any[]
  >,
) {
  return useQuery(
    [USE_TRANSACTION_QUERY_KEY, transactionId],
    () => fetchTransaction(partnerId, transactionId!),
    {
      enabled: !!transactionId,
      ...options,
    },
  );
}
