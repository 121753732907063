import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchSubpartnersInvite } from '../fetches/fetchSubpartnersInvite';

export function useSubpartnersInviteMutation(
  options?: UseMutationOptions<
    { status: string },
    Types.HttpError,
    Parameters<typeof fetchSubpartnersInvite>
  >,
) {
  return useMutation((args) => fetchSubpartnersInvite(...args), {
    retry: 0,
    ...options,
  });
}
