import { ColumnsType } from 'antd/es/table';
import { secsToTime } from 'core/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export const useWinnersLoosersColumns = (): ColumnsType<Record<string, any>> => {
  const { t } = useTranslation();

  return [
    {
      title: t('dashboard.columns.serial-number'),
      dataIndex: 'serialNumber',
      fixed: 'left',
      render: (sn: string, record) => {
        return <Link to={`/points/${record.cpId}/details`}>{sn}</Link>;
      },
    },
    {
      title: t('dashboard.columns.sessions-count'),
      dataIndex: 'count',
      fixed: 'left',
    },
    {
      title: t('dashboard.columns.payments-received'),
      dataIndex: 'amountEur',
    },
    {
      title: t('dashboard.columns.max-time'),
      dataIndex: 'maxTimeSec',
      render: (v: number) => secsToTime(v),
    },
    {
      title: t('dashboard.columns.min-time'),
      dataIndex: 'minTimeSec',
      render: (v: number) => secsToTime(v),
    },
    {
      title: t('dashboard.columns.total-time'),
      dataIndex: 'totalTimeSec',
      render: (v: number) => secsToTime(v),
    },
    {
      title: t('dashboard.columns.max-kwh'),
      dataIndex: 'maxKwh',
      key: 'maxKwh',
    },
    {
      title: t('dashboard.columns.min-kwh'),
      dataIndex: 'minKwh',
      key: 'minKwh',
    },
    {
      title: t('dashboard.columns.total-kwh'),
      dataIndex: 'totalKwh',
    },
  ];
};
