import { useTranslation } from 'react-i18next';
import { Table, Descriptions, Empty } from 'antd';
import dayjs from 'dayjs';
import { ResTransactionMetrics } from 'features/transactions/types/responses';
import { use } from 'i18next';
import { FC } from 'react';

interface Props {
  metrics?: ResTransactionMetrics;
}

export const Metrics: FC<Props> = ({ metrics }) => {
  const { t } = useTranslation();

  const metricsColumns = [
    {
      title: t('transactions.columns.metric-id'),
      dataIndex: 'id',
      key: 'key',
    },
    {
      title: t('transactions.columns.connector-id'),
      dataIndex: 'connectorId',
      key: 'connectorId',
    },
    {
      title: t('transactions.columns.timestamp'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: string) =>
        timestamp ? dayjs(timestamp).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.current-import'),
      dataIndex: 'currentImport',
      key: 'currentImport',
    },
    {
      title: t('transactions.columns.energy-active-import-reg'),
      dataIndex: 'energyActiveImportReg',
      key: 'energyActiveImportReg',
    },
    {
      title: t('transactions.columns.power-active-import'),
      dataIndex: 'powerActiveImport',
      key: 'powerActiveImport',
    },
    {
      title: t('transactions.columns.power-offered'),
      dataIndex: 'powerOffered',
      key: 'powerOffered',
    },
    {
      title: t('transactions.columns.soc'),
      dataIndex: 'soc',
      key: 'soc',
    },
    {
      title: t('transactions.columns.voltage'),
      dataIndex: 'voltage',
      key: 'voltage',
    },
  ];

  return (
    <>
      {!!metrics?.items?.length ? (
        <>
          <Descriptions title={t('transactions.titles.metrics')}></Descriptions>
          <Table
            size={'small'}
            pagination={false}
            scroll={{ x: 'max-content' }}
            dataSource={metrics.items ?? []}
            columns={metricsColumns}
          />
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};
