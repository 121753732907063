import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { Responses, Requests } from '../types';

/**
 * Partner analytic dashboard
 */
export async function fetchPartnerAnalytic<
  TResponse = Responses.ResPartnerAnalytic,
  TError = Types.HttpError,
>({ partnerId, from, to }: Requests.ReqPartnerAnalytic) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}/analytics/dashboards`,
      {
        headers: { ...header },
        params: {
          from,
          to,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
