import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { OwnersSelectProps } from './types';
import styles from './styles.module.css';

export const OwnersSelect = ({
  selectedOwnerPartnerId,
  owners,
  dispatch,
}: OwnersSelectProps) => {
  const { t } = useTranslation();

  const onOwnersChange = (currentOwner: string) => {
    dispatch({
      type: 'SET_OWNER_PARTNER_ID',
      payload: { ownerPartnerId: currentOwner },
    });
  };

  return (
    <div className={styles.input}>
      <label htmlFor="owners-select">{t('chargepoints.filter.owners')}:</label>
      <Select
        id="owners-select"
        allowClear
        style={{ width: '250px' }}
        placeholder={t('chargepoints.filter.select-owners')}
        maxTagCount="responsive"
        value={selectedOwnerPartnerId}
        onChange={onOwnersChange}
        options={owners.map((owner) => ({
          label: owner.name,
          value: owner.id,
        }))}
      />
    </div>
  );
};
