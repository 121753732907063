import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnerPasswordChange } from '../fetches';
import { ResPartnerUpdate } from '../types/responses/ResPartnerUpdate';

export function usePartnerChangePasswordMutation(
  options?: UseMutationOptions<
    ResPartnerUpdate,
    Types.HttpError,
    Parameters<typeof fetchPartnerPasswordChange>
  >,
) {
  return useMutation((args) => fetchPartnerPasswordChange(...args), {
    retry: 0,
    ...options,
  });
}

