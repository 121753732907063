import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AntdProvider } from 'AntdProvider';

import App from './App';
import { store } from './store';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AntdProvider>
            <App />
          </AntdProvider>
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
export { getConnectorsSelectOptions as connectorsSelectOptions } from 'components/view/ConnectorLabel/connectors-labels.const';
export { connectorStatusColor } from 'components/view/ConnectorLabel/connectors-labels.const';

