import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchTransactionCalculations } from '../fetches';
import { ResTransactionCalculations } from '../types/responses';

export const USE_TRANSACTION_CALC_QUERY_KEY = 'transaction.calculations';

export function useTransactionCalculationsQuery(
  partnerId: string,
  transactionId: string,
  withItems: boolean,
  options?: UseQueryOptions<
    ResTransactionCalculations,
    Types.HttpError,
    ResTransactionCalculations,
    any[]
  >,
) {
  return useQuery(
    [USE_TRANSACTION_CALC_QUERY_KEY, transactionId, withItems],
    () => fetchTransactionCalculations(partnerId, transactionId, withItems),
    {
      enabled: !!transactionId,
      ...options,
    },
  );
}
