import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'features/auth/hooks';
import { PartnerProvider } from 'core/providers/PartnerProvider';

export function PrivateRoute() {
  let { isAuthed } = useAuth();

  if (!isAuthed) {
    return <Navigate to="/login" replace />;
  }

  return (
    <PartnerProvider>
      <Outlet />
    </PartnerProvider>
  );
}

