import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { AvailabilityStatusSwitchProps } from './types';

export const AvailabilityStatusSwitch = ({
  availabilityStatus,
  dispatch,
}: AvailabilityStatusSwitchProps) => {
  const { t } = useTranslation();

  const updateConnectionStatus = (
    status: AvailabilityStatusSwitchProps['availabilityStatus'],
  ) => {
    dispatch({
      type: 'SET_CONNECTION_STATUS',
      payload: {
        availabilityStatus: status,
      },
    });
  };

  const toggleState = () => {
    switch (availabilityStatus) {
      case 'inactive':
        updateConnectionStatus('all');
        break;
      case 'all':
        updateConnectionStatus('active');
        break;
      case 'active':
        updateConnectionStatus('inactive');
        break;
      default:
        updateConnectionStatus('all');
    }
  };

  const getSwitchText = () => {
    switch (availabilityStatus) {
      case 'active':
        return t('chargepoints.filter.active-status');
      case 'all':
        return t('chargepoints.filter.all-status');
      case 'inactive':
        return t('chargepoints.filter.inactive-status');
      default:
        return '';
    }
  };

  return (
    <div className={styles.input}>
      <label htmlFor="availability-status">
        {t('chargepoints.filter.availability-status')}:
      </label>
      <div className={styles.switch}>
        <Switch
          id="availability-status"
          checked={availabilityStatus === 'active'}
          onClick={toggleState}
          className={
            availabilityStatus === 'all' ? styles.anyStatus : undefined
          }
        />
        <span>{getSwitchText()}</span>
      </div>
    </div>
  );
};
