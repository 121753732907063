import { Alert, Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { usePartner } from 'core/providers/PartnerProvider';
import { usePartnerChangePasswordMutation } from 'features/partners/queries/usePartnerChangePasswordMutation';
import { useState } from 'react';

export interface ResetPasswordFormProps {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

export function ResetPasswordForm({
  isOpen,
  onCancel,
  onSuccess,
}: ResetPasswordFormProps) {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const partner = usePartner();
  const mutatePasswordChange = usePartnerChangePasswordMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  if (!partner) {
    return null;
  }

  const handleUpdate = (values: any) => {
    mutatePasswordChange.mutate(
      [
        partner.id,
        {
          password: values.oldPassword,
          newPassword: values.newPassword,
          username: partner.contacts.email.contact,
        },
      ],
      {
        onSuccess,
        onError: (error) => {
          setErrorMessage(errorT(error?.translationKey, error.message));
        },
        onSettled: () => {
          form.resetFields();
        },
      },
    );
  };

  return (
    <Modal
      open={isOpen}
      title={t('profile.titles.reset-password')}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} type={'default'} onClick={onCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          htmlType={'submit'}
          form="reset-password-form"
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id={'reset-password-form'}
        layout={'vertical'}
        onFinish={handleUpdate}
        onChange={() => setErrorMessage('')}
      >
        <Form.Item
          name={['oldPassword']}
          label={`${t('profile.labels.old-password')}:`}
          rules={[
            { required: true, message: t('profile.rules.password-required') },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name={['newPassword']}
          label={`${t('profile.labels.new-password')}:`}
          rules={[
            { required: true, message: t('profile.rules.password-required') },
            { min: 6, message: t('profile.rules.new-password-length') },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name={['confirmPassword']}
          label={`${t('profile.labels.confirm-password')}:`}
          rules={[
            { required: true, message: t('profile.rules.password-required') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('profile.rules.password-match')),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        {errorMessage && (
          <Form.Item>
            <Alert type={'error'} description={errorMessage} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
