import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Client } from 'features/clients/types/Client';

export const Description = ({
  client,
  amount,
  currency,
}: {
  client: Client;
  amount: number;
  currency: string;
}) => {
  const { t } = useTranslation();

  return (
    <Descriptions layout="vertical">
      <Descriptions.Item label={t('clients.labels.status')}>
        {client.status ?? '-'}
      </Descriptions.Item>
      <Descriptions.Item label={t('clients.labels.phone')}>
        {client.phone ?? '-'}
      </Descriptions.Item>
      <Descriptions.Item label={t('clients.labels.birthdate')}>
        {client.birthDate ? dayjs(client.birthDate).format('DD.MM.YYYY') : '-'}
      </Descriptions.Item>
      <Descriptions.Item label={t('clients.labels.balance')}>
        {amount} {currency}
      </Descriptions.Item>
    </Descriptions>
  );
};
