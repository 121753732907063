import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { SubpartnersInvitations } from '../types';

export async function fetchSubpartnersInvitations(partnerId: string) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<SubpartnersInvitations>(
      `/partners/${partnerId}/sub-partners/invitations`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
