import { useQueryClient } from '@tanstack/react-query';
import { Button, Form, Select, Modal, message, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { usePartnerId } from 'core/providers/PartnerProvider';
import {
  USE_CHARGE_POINT_CONFIGS_QUERY_KEY,
  useChargePointsSendConfigsMutation,
} from 'features/charge-points/queries';
import { ChargePointConfigItem } from 'features/charge-points/types/ChargePointConfigItem';
import { ChargePointPartnerItem } from 'features/charge-points/types/ChargePointPartnerItem';
import { HttpError } from 'core/types';

export interface ChargePointTransactionFormProps {
  configs: ChargePointConfigItem[];
  isOpen?: boolean;
  chargePoint: ChargePointPartnerItem;
  onCancel: () => void;
}

export function ChargePointSendConfigurationForm({
  configs,
  isOpen,
  chargePoint,
  onCancel,
}: ChargePointTransactionFormProps) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const partnerId = usePartnerId();
  const send = useChargePointsSendConfigsMutation();
  const currentKey = Form.useWatch('key', form);
  const config = (configs ?? []).find((config) => config.key === currentKey);

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const handleClose = () => {
    send.reset();
    onCancel();
  };

  const handleStart = (values: {
    key: string;
    value_int: number;
    value_boolean: boolean;
  }) => {
    const data = {
      key: values.key,
      value:
        config!.dataType === 'int'
          ? values.value_int.toString()
          : config!.dataType === 'boolean'
          ? values.value_boolean.toString()
          : '',
    };

    if (!data.value) {
      messageApi.open({
        type: 'error',
        content: t('chargepoints.status.unexpected-data-type'),
      });
      return;
    }

    send
      .mutateAsync([partnerId, chargePoint.id, { items: [data] }])
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_POINT_CONFIGS_QUERY_KEY]);
        messageApi.open({
          type: 'success',
          content: t('common.status.success'),
        });
        onCancel();
      })
      .catch((e: HttpError) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title={t('chargepoints.titles.send-configuration')}
        onCancel={handleClose}
        footer={[
          <Button key={'cancel'} onClick={handleClose}>
            {t('common.actions.cancel')}
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            htmlType={'submit'}
            form={'send-configuration-form'}
            loading={send.isLoading}
          >
            {t('common.actions.send')}
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          layout={'vertical'}
          id={'send-configuration-form'}
          onFinish={handleStart}
        >
          <Form.Item
            label={`${t('chargepoints.labels.key')}:`}
            name={'key'}
            rules={[
              { required: true, message: t('chargepoints.rules.choose-key') },
            ]}
          >
            <Select>
              {(configs ?? [])
                .filter(
                  (config) =>
                    !config.readonly &&
                    config.dataType &&
                    ['int', 'boolean'].includes(config.dataType),
                )
                .map((config) => (
                  <Select.Option key={config.key} value={config.key}>
                    {config.key}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {currentKey && config!.dataType === 'int' && (
            <Form.Item
              label={`${t('chargepoints.labels.value')}:`}
              name={'value_int'}
              rules={[
                {
                  required: true,
                  message: t('chargepoints.rules.value-required'),
                },
              ]}
            >
              <Input type="number" min={0} max={999999} />
            </Form.Item>
          )}
          {currentKey && config!.dataType === 'boolean' && (
            <Form.Item
              label={`${t('chargepoints.labels.value')}:`}
              name={'value_boolean'}
              rules={[
                {
                  required: true,
                  message: t('chargepoints.rules.value-required'),
                },
              ]}
            >
              <Select>
                <Select.Option value={true}>
                  {t('chargepoints.text.true')}
                </Select.Option>
                <Select.Option value={false}>
                  {t('chargepoints.text.false')}
                </Select.Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}
