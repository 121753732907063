import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagPartner } from '../fetches';
import { ResChargeTagPartner } from '../types/responses';

export const USE_CHARGE_TAG_PARTNER_QUERY_KEY = 'charge.tags.partners';

export function useChargeTagPartnerQuery(
  args: Parameters<typeof fetchChargeTagPartner>,
  options?: UseQueryOptions<
    ResChargeTagPartner,
    Types.HttpError,
    ResChargeTagPartner,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_TAG_PARTNER_QUERY_KEY, args],
    () => fetchChargeTagPartner(...args),
    options,
  );
}
