import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchGenerateReport } from '../fetches';
import type { GenerateReportRequest, GenerateReportResponse } from '../types';

export function useGenerateReportMutation(
  options?: UseMutationOptions<
    GenerateReportResponse,
    Types.HttpError,
    GenerateReportRequest
  >,
) {
  return useMutation(fetchGenerateReport, {
    retry: 0,
    ...options,
  });
}
