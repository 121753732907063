import { useRef } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './AdditionalInfo.module.css';
import { LogoUpload } from './LogoUpload';
import { LinkInput } from './LinkInput';

export type AdditionalInfoFormValues = {
  website: string;
  ocpiCode: string;
};

type AdditionalInfoProps = {
  onSubmit: (data: AdditionalInfoFormValues) => void;
  initialValues?: AdditionalInfoFormValues;
  onSuccess(message?: string): void;
  onError(message?: string): void;
};

export const AdditionalInfo = ({
  onSubmit,
  initialValues,
  onSuccess,
  onError,
}: AdditionalInfoProps) => {
  const isTouched = useRef(false);

  const { t } = useTranslation();

  const onFormSubmit = (formValues: AdditionalInfoFormValues) => {
    if (Object.values(formValues).every((value) => !value)) {
      return;
    }

    if (!isTouched.current) {
      return;
    }

    onSubmit(formValues);
  };

  return (
    <Form
      className={styles.container}
      layout="vertical"
      onFinish={onFormSubmit}
      initialValues={initialValues}
      style={{ paddingTop: 8 }}
      onChange={(e) => {
        isTouched.current = true;
      }}
    >
      <div className={styles.item}>
        <LinkInput
          className={styles.input}
          label={`${t('profile.additional_info.website-address')}:`}
          name="website"
        />
      </div>
      <div className={styles.item}>
        <Form.Item
          label={`${t('profile.additional_info.ocpi-code')}:`}
          name="ocpiCode"
          className={styles.input}
          rules={[
            {
              pattern: /^[a-zA-Z]{3}$/,
              message: t('common.rules.ocpi'),
            },
          ]}
          normalize={(value) => value?.toUpperCase()}
        >
          <Input placeholder="ABC" />
        </Form.Item>
      </div>
      <div className={styles.item}>
        <LogoUpload onLoadSuccess={onSuccess} onLoadError={onError} />
      </div>
      <div className={styles.action}>
        <Button type="primary" htmlType="submit">
          {t('common.actions.save')}
        </Button>
      </div>
    </Form>
  );
};
