import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchSubpartnersCancelInvite } from '../fetches';

export function useSubpartnersCancelInviteMutation(
  options?: UseMutationOptions<
    { status: string },
    Types.HttpError,
    Parameters<typeof fetchSubpartnersCancelInvite>
  >,
) {
  return useMutation((args) => fetchSubpartnersCancelInvite(...args), {
    retry: 0,
    ...options,
  });
}
