import { useMemo, useState } from 'react';
import { Button, Table, Typography, Flex, Spin, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { Pagination } from 'components/Pagination';
import { PhoneInput } from 'components/PhoneInput';

import {
  mePartnerId,
  usePartnerId,
} from '../../core/providers/PartnerProvider';
import { usePartnersClientsQuery } from '../../features/partners/queries';

import styles from './Clients.module.css';
import { useClientsTableColumns } from './useClientsTableColumns';

const PAGE_SIZE = 15;

export const Clients = () => {
  const [index, setIndex] = useState(1);
  const partnerId = usePartnerId();
  const [phoneValue, setPhoneValue] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [error, setError] = useState('');

  const clientsTableColumns = useClientsTableColumns();

  const { t } = useTranslation();

  const { data, isLoading, nextPage, prevPage } = usePartnersClientsQuery(
    partnerId,
    { withAvailability: true, clientPhone },
    { index, size: PAGE_SIZE },
    { enabled: partnerId !== mePartnerId },
  );

  const { clients: list } = data || {};
  const dataSource = useMemo(() => {
    return (list ?? []).map((t) => ({
      key: t.id,
      ...t,
    }));
  }, [list]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setError('');
    setPhoneValue(value);

    if (!value) {
      setClientPhone('');
    }
  };

  const handleSearch = () => {
    if (!phoneValue) {
      return;
    }

    if (/\D/.test(phoneValue)) {
      setError(t('client.rules.phone-numbers'));
      return;
    }
    const phoneStr = phoneValue.replace(/\D+/g, '');
    if (phoneStr.length >= 4) {
      setClientPhone(phoneStr);
    } else {
      setError(t('client.rules.phone-four-digits'));
    }

    setIndex(1);
  };

  /** we don't need to show pagination if theres in now data at all */
  const showPagination = dataSource.length > 0 || index > 1;

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('clients.titles.clients')}
        </Typography.Title>
      </Padding>
      <div className={styles.content}>
        <Form onFinish={handleSearch}>
          <Flex gap="middle">
            <Form.Item style={{ flex: 1, maxWidth: 360, marginBottom: 0 }}>
              <PhoneInput
                placeholder={t('clients.placeholders.phone')}
                value={phoneValue}
                onChange={handlePhoneChange}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              {t('common.actions.search')}
            </Button>
          </Flex>
        </Form>
        {error && <Typography.Text type="danger">{error}</Typography.Text>}
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <Table
              size="small"
              columns={clientsTableColumns}
              dataSource={dataSource}
              scroll={{ x: 500 }}
              pagination={false}
            />
            {showPagination && (
              <div style={{ paddingBlock: '16px' }}>
                <Pagination
                  page={index}
                  hasNext={nextPage}
                  hasPrev={prevPage}
                  onChange={(_, index) => setIndex(index)}
                >
                  <Typography>{`${t(
                    'common.pagination.page',
                  )}: ${index}`}</Typography>
                </Pagination>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
