import { TFunction } from 'i18next';
import { WebTranslateKey } from 'i18n/types';

import {
  ChargePointTariff,
  ChargePointTransaction,
  ConnectorsTab,
  DescriptionTab,
  DiagnosticTab,
  LocationTab,
  SettingsTab,
  TechInfoTab,
  TransactionsTab,
} from './tabs';
import { ImagesTab } from './tabs/ImagesTab';
import { ConfigurationTab } from './tabs/ConfigurationTab';

const hiddenTabsForExternalResource = [
  'settings',
  'techInfo',
  'transaction',
  'configuration',
  'diagnostic',
];

type TabsItem = {
  translationKey: WebTranslateKey;
  key: string;
  children: JSX.Element;
};

const fullTabsList: TabsItem[] = [
  {
    translationKey: 'chargepoints.tabs.description',
    key: 'description',
    children: <DescriptionTab />,
  },
  {
    translationKey: 'chargepoints.tabs.settings',
    key: 'settings',
    children: <SettingsTab />,
  },
  {
    translationKey: 'chargepoints.tabs.tariff',
    key: 'tariff',
    children: <ChargePointTariff />,
  },
  {
    translationKey: 'chargepoints.tabs.connectors',
    key: 'connectors',
    children: <ConnectorsTab />,
  },
  {
    translationKey: 'chargepoints.tabs.location',
    key: 'info',
    children: <LocationTab />,
  },
  {
    translationKey: 'chargepoints.tabs.tech-info',
    key: 'techInfo',
    children: <TechInfoTab />,
  },
  {
    translationKey: 'chargepoints.tabs.transactions',
    key: 'transactions',
    children: <TransactionsTab />,
  },
  {
    translationKey: 'chargepoints.tabs.images',
    key: 'images',
    children: <ImagesTab />,
  },
  {
    translationKey: 'chargepoints.tabs.service',
    key: 'transaction',
    children: <ChargePointTransaction />,
  },
  {
    translationKey: 'chargepoints.tabs.configuration',
    key: 'configuration',
    children: <ConfigurationTab />,
  },
  {
    translationKey: 'chargepoints.tabs.diagnostic',
    key: 'diagnostic',
    children: <DiagnosticTab />,
  },
];

export const getChargePointTabs = (
  t: TFunction<'web', undefined>,
  isExternalResource: boolean,
) => {
  const tabsList = isExternalResource
    ? fullTabsList.filter(
        (tab) => !hiddenTabsForExternalResource.includes(tab.key),
      )
    : fullTabsList;

  return tabsList.map((tab) => ({
    label: t(tab.translationKey),
    ...tab,
  }));
};
