import { Country } from 'core/types/Country';
import { Currency } from 'core/types/Currency';
import { SelectOptions } from 'core/types/SelectOptions';
import { flatten, uniqBy } from 'lodash-es';

export const getCountriesSelectOptions = (
  countries: Country[],
): SelectOptions[] =>
  countries.map(({ alfa3, nameEng }) => ({
    value: alfa3,
    label: nameEng,
  }));

export const getCountriesTimezonesDict = (countries: Country[]) =>
  countries.reduce(
    (dict, country) => ({
      ...dict,
      [country.alfa3]: country.timeZones,
    }),
    {},
  );

export const defaultCurrency = 'RUB';

export const getCurrenciesFromCountries = (countries: Country[]) =>
  uniqBy<Currency>(
    flatten(countries.map((country) => country.currencies)),
    (val) => val.isoCode,
  );

export const getTimezonesSelectOptions = (
  timezones: string[],
): SelectOptions[] =>
  timezones.map((tz) => ({
    value: tz,
    label: tz,
  }));

export const getCurrenciesSelectOptions = (
  currencies: Currency[],
): SelectOptions[] =>
  currencies.map((currency) => {
    const key = `${currency.isoCode}`;
    const label = `${currency.isoCode} (${currency.nameEng})`;

    return {
      value: currency.isoCode,
      label,
      key,
    };
  });

export const getPurposesSelectOptions = (purposes: string[]): SelectOptions[] =>
  purposes.map((item) => ({
    value: item,
    label: item,
  }));

