import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnerUser } from '../fetches';
import { Responses } from '../types';

export const USE_PARTNER_USER_QUERY_KEY = 'partner.user';

export function usePartnerUserQuery(
  userId?: string,
  options?: UseQueryOptions<
    Responses.ResPartnerUser,
    Types.HttpError,
    Responses.ResPartnerUser,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_USER_QUERY_KEY, userId],
    () => fetchPartnerUser(userId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
