import { FC } from 'react';
import cn from 'classnames';
import { Outlet } from 'react-router-dom';
import { Padding } from 'components/Padding';
import { PricingBreadcrumbs } from 'pages/Pricing/PricingBreadcrumbs';

import s from './Pricing.module.css';

type Props = {
  className?: string;
};

const Pricing: FC<Props> = ({ className }) => {
  return (
    <div className={cn(s.wrapper, className)}>
      <Padding>
        <PricingBreadcrumbs />
      </Padding>
      <Outlet />
    </div>
  );
};

export default Pricing;
