import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupTariffCreate } from '../fetches';
import { ResPartnerTariffGroupUpdate } from '../types/responses';

export function usePartnerTariffGroupTariffCreateMutation(
  options?: UseMutationOptions<
    ResPartnerTariffGroupUpdate,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupTariffCreate>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupTariffCreate(...args), {
    retry: 0,
    ...options,
  });
}
