import { Spin } from 'antd';

export function Splash() {
  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        justifyContent: 'center',
      }}
    >
      <Spin />
    </div>
  );
}
