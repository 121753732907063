import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { ErrorTranslateKey } from './types';

export const useErrorTranslation = () => {
  const { i18n, t: webT } = useTranslation();
  const { getFixedT, exists } = i18n;

  const errorT = getFixedT(i18n.languages[0], 'errors');

  const t = useCallback(
    (key: ErrorTranslateKey | string, fallback: string = '') => {
      if (exists(key, { ns: 'errors' })) {
        return errorT(key as ErrorTranslateKey);
      }

      return fallback || webT('common.errors.unknown-error');
    },
    [errorT, exists, webT],
  );

  return { t, i18n };
};
