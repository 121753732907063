import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupTariffArchive } from '../fetches';
import { ResPartnerTariffGroupUpdate } from '../types/responses';

export function usePartnerTariffGroupTariffArchiveMutation(
  options?: UseMutationOptions<
    ResPartnerTariffGroupUpdate,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupTariffArchive>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupTariffArchive(...args), {
    retry: 0,
    ...options,
  });
}
