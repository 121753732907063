import * as Services from 'core/services';
import * as Types from 'core/types';

import { ResCountries } from '../types/responses';

export async function fetchCountries<
  TResponse = ResCountries,
  TError = Types.HttpError,
>() {
  try {
    const response = await Services.apiService.get<TResponse>(
      `catalog/countries`,
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
