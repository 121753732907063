import { useReducer } from 'react';

import { ChargePointsFilterAction, ChargePointsFilterState } from './types';

const filterInitialState: ChargePointsFilterState = {
  textSearch: '',
  ownerPartnerId: null,
  hashtags: [],
  availabilityStatus: 'all',
};

const chargePointsFilterReducer = (
  state: ChargePointsFilterState,
  action: ChargePointsFilterAction,
): ChargePointsFilterState => {
  switch (action.type) {
    case 'SET_FULL_TEXT_SEARCH': {
      return {
        ...state,
        textSearch: action.payload.textSearch,
      };
    }
    case 'SET_OWNER_PARTNER_ID': {
      return {
        ...state,
        ownerPartnerId: action.payload.ownerPartnerId,
      };
    }
    case 'SET_HASHTAGS': {
      return {
        ...state,
        hashtags: action.payload.hashtags,
      };
    }
    case 'SET_CONNECTION_STATUS': {
      return {
        ...state,
        availabilityStatus: action.payload.availabilityStatus,
      };
    }
    default: {
      return state;
    }
  }
};

export const useChargePointsFilter = () => {
  const [state, dispatch] = useReducer(
    chargePointsFilterReducer,
    filterInitialState,
  );

  return [state, dispatch] as const;
};
