import React, { useState } from 'react';
import { useAuth } from 'features/auth/hooks';
import {
  useChargePointsPartnerAssignMutation,
  useChargePointsSearchQuery,
} from 'features/charge-points/queries';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Select,
  Space,
  Typography,
} from 'antd';
import { usePartnerUserQuery } from 'features/partners/queries';
import { LoadingOutlined } from '@ant-design/icons';
import { usePartnerId } from 'core/providers/PartnerProvider';

const searchParams = [
  {
    value: 'deviceSn',
    label: 'Serial number',
  },
  {
    value: 'deviceId',
    label: 'Device ID',
  },
];

const ChargePointsSearch = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const { data: chargePointPartner, isLoading } = usePartnerUserQuery(user?.id);

  const [searchValue, setSearchValue] = useState('');
  const [selectedSearchParam, setSelectedSearchParam] = useState(
    searchParams[0].value,
  );

  const [selectedChargePoint, setSelectedChargePoint] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const partnerId = usePartnerId();

  const { data, refetch } = useChargePointsSearchQuery(
    partnerId,
    { [selectedSearchParam]: searchValue || undefined, partnerSet: false },
    {
      enabled: !!searchValue,
    },
  );
  const assignMutation = useChargePointsPartnerAssignMutation();

  const handleSearch = () => {
    refetch();
  };

  const showError = (msg: string) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  const handleAssignChargePoint = (chargePoint: any) => {
    assignMutation.mutate(
      {
        deviceId: chargePoint?.device?.deviceId,
        deviceSn: chargePoint?.device?.chargePointSerialNumber,
        // @ts-ignore
        partnerId: chargePointPartner?.id,
      },
      {
        onSuccess: () => {
          navigate('/points');
        },
        onError: (err) => showError(err.message),
      },
    );
  };

  const handleChangeSearchParam = (value: any) => {
    setSelectedSearchParam(value);
  };

  if (isLoading) {
    return <LoadingOutlined />;
  }

  return (
    <div>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 24px 0',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Search charge point
        </Typography.Title>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={
              selectedSearchParam === 'deviceSn' ? 'Serial number' : 'Device ID'
            }
          />
          <Select
            defaultValue="deviceSn"
            style={{ width: 120 }}
            onChange={handleChangeSearchParam}
            options={searchParams}
          />
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '0 24px 24px',
        }}
      >
        {data?.items && data?.items?.length > 0 ? (
          data?.items?.map((chargePoint: any) => (
            <Card key={chargePoint?.id}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '24px',
                  alignItems: 'center',
                }}
              >
                <Space direction="vertical">
                  <Typography.Text type="secondary">Name</Typography.Text>
                  <Typography.Text>{chargePoint?.name ?? '-'}</Typography.Text>
                </Space>
                <Space direction="vertical">
                  <Typography.Text type="secondary">Status</Typography.Text>
                  <Typography.Text>
                    {chargePoint?.status ?? '-'}
                  </Typography.Text>
                </Space>
                <Space direction="vertical">
                  <Typography.Text type="secondary">Model</Typography.Text>
                  <Typography.Text>
                    {chargePoint?.device?.chargePointModel ?? '-'}
                  </Typography.Text>
                </Space>
                <Space direction="vertical">
                  <Typography.Text type="secondary">
                    Serial number
                  </Typography.Text>
                  <Typography.Text>
                    {chargePoint?.device?.chargePointSerialNumber ?? '-'}
                  </Typography.Text>
                </Space>
                <Space direction="vertical">
                  <Typography.Text type="secondary">Device ID</Typography.Text>
                  <Typography.Text>
                    {chargePoint?.device?.deviceId ?? '-'}
                  </Typography.Text>
                </Space>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedChargePoint(chargePoint);
                      setOpenConfirm(true);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </div>
      <Modal
        title="Confirm addition charge point"
        open={openConfirm}
        onOk={() => handleAssignChargePoint(selectedChargePoint)}
        onCancel={() => setOpenConfirm(false)}
      >
        <Typography.Text>
          Are you sure you want to add charge point?
        </Typography.Text>
      </Modal>
    </div>
  );
};

export default ChargePointsSearch;
