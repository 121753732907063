import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffCopy } from '../fetches';
import { PartnerTariffGroup } from '../types';

export function usePartnerTariffCopyMutation(
  options?: UseMutationOptions<
    PartnerTariffGroup,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffCopy>
  >,
) {
  return useMutation((args) => fetchPartnerTariffCopy(...args), {
    retry: 0,
    ...options,
  });
}
