import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnerHashTags } from '../fetches';
import { ResPartnerHashTags } from '../types/responses';

export const USE_HASHTAGS_PARTNER_QUERY_KEY = 'partner.hashtags';

export function usePartnerHashtagsQuery(
  args: Parameters<typeof fetchPartnerHashTags>,
  options?: UseQueryOptions<
    ResPartnerHashTags,
    Types.HttpError,
    ResPartnerHashTags,
    any[]
  >,
) {
  return useQuery(
    [USE_HASHTAGS_PARTNER_QUERY_KEY, args],
    () => fetchPartnerHashTags(...args),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
