import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { ResLoadBalancingProfilesSearch } from '../types/responses';
import { fetchLoadBalancingProfilesSearch } from '../fetches';

export const USE_PARTNER_BALANCING_PROFILES_QUERY_KEY =
  'partner.balancing-profiles';

export function useLoadBalancingProfilesSearchQuery(
  args: Parameters<typeof fetchLoadBalancingProfilesSearch>,
  options?: UseQueryOptions<
    ResLoadBalancingProfilesSearch,
    Types.HttpError,
    ResLoadBalancingProfilesSearch,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_BALANCING_PROFILES_QUERY_KEY, args],
    () => fetchLoadBalancingProfilesSearch(...args),
    options,
  );
}
