import { Switch, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { ColumnsType } from 'antd/es/table';
import { useChargePointsChangeStatusMutation } from 'features/charge-points/queries';
import { HttpError } from 'core/types';
import { Connector } from 'core/types/Connector';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import { EmergencyIcon } from 'components/EmergencyIcon';
import { useIsSubpartner } from 'core/providers/PartnerProvider';

import { ChargePointsTableColumnsProps } from './types';

export const useChargePointsTableColumns = ({
  messageApi,
  partnerId,
  onSuccess,
}: ChargePointsTableColumnsProps): ColumnsType<Record<string, any>> => {
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const changeStatusMutation = useChargePointsChangeStatusMutation();
  const isReadOnly = useIsSubpartner();

  const showError = (error: HttpError) => {
    messageApi.open({
      type: 'error',
      content: errorT(error?.translationKey, error.message),
    });
  };

  return [
    {
      title: t('chargepoints.columns.name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (text: string, item: any) => {
        if (item.emergencyButtonPressed) {
          return (
            <Tooltip
              title={t('chargepoints.text.emergency-button-pressed')}
              placement="topLeft"
            >
              <Typography.Text
                type="danger"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <EmergencyIcon style={{ fontSize: 18 }} />
                <Link to={`/points/${item.key}/details`}>{text}</Link>
              </Typography.Text>
            </Tooltip>
          );
        } else {
          return <Link to={`/points/${item.key}/details`}>{text}</Link>;
        }
      },
    },
    {
      title: t('chargepoints.columns.ocpp-version'),
      dataIndex: 'ocppVersion',
      key: 'ocppVersion',
      fixed: 'left',
    },
    {
      title: t('chargepoints.columns.show-map'),
      key: 'operation',
      fixed: 'left',
      render: (chargePoint: any) => {
        let handleChange = () => {
          changeStatusMutation.mutate(
            {
              id: chargePoint?.key,
              status: chargePoint.status === 'active' ? 'inactive' : 'active',
              partnerId,
            },
            {
              onSuccess,
              onError: (err) => showError(err),
            },
          );
        };

        return (
          <>
            <Switch
              onChange={handleChange}
              checked={chargePoint.status === 'active'}
              size="small"
              disabled={isReadOnly}
            />
          </>
        );
      },
    },
    {
      title: t('chargepoints.columns.connectors'),
      dataIndex: 'connectors',
      key: 'connectors',
      render: (connectorsDict?: Record<number, Connector>) => {
        const connectors = connectorsDict && Object.values(connectorsDict);
        return connectors && connectors.length > 0
          ? connectors.map((item, idx) => (
              <ConnectorLabel
                key={idx}
                type={item.type}
                status={item.availability}
              />
            ))
          : 'None';
      },
    },
    {
      title: t('chargepoints.columns.device-id'),
      dataIndex: 'deviceId',
      key: 'deviceId',
      render: (deviceId: string) => (
        <Typography.Paragraph copyable style={{ marginBottom: 0 }}>
          {deviceId}
        </Typography.Paragraph>
      ),
    },

    {
      title: t('chargepoints.columns.address'),
      dataIndex: 'address',
      key: 'address',
    },

    {
      title: t('chargepoints.columns.vendor'),
      dataIndex: 'vendor',
      key: 'vendor',
    },
    {
      title: t('chargepoints.columns.currency'),
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: t('chargepoints.columns.tariff'),
      dataIndex: 'tariffGroup',
      key: 'tariffGroup',
      render: (item: any) => (
        <Typography.Paragraph copyable style={{ marginBottom: 0 }}>
          <Link
            target="_blank"
            to={`/partners/${partnerId}/tariffs/${item.id}/details`}
          >
            {item.name}
          </Link>
        </Typography.Paragraph>
      ),
    },
    {
      title: t('chargepoints.columns.owner'),
      dataIndex: 'ownerPartnerName',
      key: 'ownerPartnerName',
    },
    {
      title: t('chargepoints.columns.groups'),
      dataIndex: 'hashTags',
      key: 'hashTags',
      render: (hashTags: string[]) => (hashTags ?? []).join(', '),
    },
  ];
};
