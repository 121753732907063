import { ConnectorType } from 'features/charge-points/types';
import { Dispatch } from 'react';

export enum TransactionStatus {
  REQUEST = 'request',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  FINISHED_OK = 'ok',
  FAILED = 'failed',
  STOPPED_BY_CP = 'stopped',
  CANCELLED_REQUEST = 'cancelled_request',
  CANCELLED_ACTIVE = 'cancelled_active',
}

export enum TransactionStatusGroup {
  ACTIVE = 'Only active',
  SUCCESSFUL = 'Successful',
  CANCELLED = 'Cancelled',
}

export const AMOUNT_FROM = 'amountFrom';
export const AMOUNT_TO = 'amountTo';

type TransactionConnecterType = ConnectorType;

type EnumRecord<KeyType extends string, ValueType> = {
  [key in KeyType]: ValueType;
};

export type TransactionsFilterState = {
  statusGroups: EnumRecord<TransactionStatusGroup, boolean>;
  // statuses: EnumRecord<TransactionStatus, boolean>;
  statuses: TransactionStatus[];
  hideZeroAmounts: boolean;
  connectorTypes?: TransactionConnecterType[];
  [AMOUNT_FROM]: number | null;
  [AMOUNT_TO]: number | null;
  startDateFrom?: string;
  startDateTo?: string;
};

export type TransactionsFilterAction =
  | {
      type: 'SELECT_STATUS_GROUP';
      payload: {
        statusGroup: TransactionStatusGroup;
        isSelected: boolean;
      };
    }
  | {
      type: 'SELECT_STATUS';
      payload: {
        statuses: TransactionStatus[];
      };
    }
  | {
      type: 'HIDE_ZERO_AMOUNTS';
    }
  | {
      type: 'SELECT_CONNECTOR_TYPE';
      payload: {
        connectorTypes: TransactionConnecterType[];
      };
    }
  | {
      type: 'UPDATE_AMOUNT';
      payload: {
        [AMOUNT_FROM]: number | null;
        [AMOUNT_TO]: number | null;
      };
    }
  | {
      type: 'START_DATE_FROM';
      payload: string;
    }
  | {
      type: 'START_DATE_TO';
      payload: string;
    };

export type TransactionsFilterProps = {
  filterState: TransactionsFilterState;
  dispatch: Dispatch<TransactionsFilterAction>;
};

export type AmountFormValues = { [AMOUNT_FROM]: number; [AMOUNT_TO]: number };
