import { WebTranslateKey } from 'i18n/types';

export enum Facilities {
  FacilityHotel = 1,
  FacilityRestaurant = 2,
  FacilityCafe = 3,
  FacilityMall = 4,
  FacilitySupermarket = 5,
  FacilitySport = 6,
  FacilityRecreationArea = 7,
  FacilityNature = 8,
  FacilityMuseum = 9,
  FacilityBikeSharing = 10,
  FacilityBusStop = 11,
  FacilityTaxiStand = 12,
  FacilityTramStop = 13,
  FacilityMetroStation = 14,
  FacilityTrainStation = 15,
  FacilityAirport = 16,
  FacilityParkingLot = 17,
  FacilityCarpoolParking = 18,
  FacilityFuelStation = 19,
  FacilityWifi = 20,
}

export const facilityLabels: Record<Facilities, WebTranslateKey> = {
  [Facilities.FacilityHotel]: 'chargepoints.facilities.hotel',
  [Facilities.FacilityRestaurant]: 'chargepoints.facilities.restaurant',
  [Facilities.FacilityCafe]: 'chargepoints.facilities.cafe',
  [Facilities.FacilityMall]: 'chargepoints.facilities.mall',
  [Facilities.FacilitySupermarket]: 'chargepoints.facilities.supermarket',
  [Facilities.FacilitySport]: 'chargepoints.facilities.sport',
  [Facilities.FacilityRecreationArea]:
    'chargepoints.facilities.recreation-area',
  [Facilities.FacilityNature]: 'chargepoints.facilities.nature',
  [Facilities.FacilityMuseum]: 'chargepoints.facilities.museum',
  [Facilities.FacilityBikeSharing]: 'chargepoints.facilities.bike-sharing',
  [Facilities.FacilityBusStop]: 'chargepoints.facilities.bus-stop',
  [Facilities.FacilityTaxiStand]: 'chargepoints.facilities.taxi-stand',
  [Facilities.FacilityTramStop]: 'chargepoints.facilities.tram-stop',
  [Facilities.FacilityMetroStation]: 'chargepoints.facilities.metro-station',
  [Facilities.FacilityTrainStation]: 'chargepoints.facilities.train-station',
  [Facilities.FacilityAirport]: 'chargepoints.facilities.airport',
  [Facilities.FacilityParkingLot]: 'chargepoints.facilities.parking-lot',
  [Facilities.FacilityCarpoolParking]:
    'chargepoints.facilities.carpool-parking',
  [Facilities.FacilityFuelStation]: 'chargepoints.facilities.fuel-station',
  [Facilities.FacilityWifi]: 'chargepoints.facilities.wifi',
};
