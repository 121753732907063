import { useMemo } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash-es';

import styles from './styles.module.css';
import { TextSearchProps } from './types';

const { Search } = Input;

export const TextSearch = ({ dispatch, isLoading }: TextSearchProps) => {
  const { t } = useTranslation();

  const debouncedTextSearchUpdate = useMemo(
    () =>
      debounce((textSearch: string) => {
        dispatch({
          type: 'SET_FULL_TEXT_SEARCH',
          payload: {
            textSearch,
          },
        });
      }, 1000),
    [dispatch],
  );

  const onTextSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debouncedTextSearchUpdate(event.target.value);
  };

  const onSearchSubmit = (value: string) => {
    dispatch({
      type: 'SET_FULL_TEXT_SEARCH',
      payload: {
        textSearch: value,
      },
    });
  };

  return (
    <div className={styles.input}>
      <Search
        onChange={onTextSearchInputChange}
        enterButton={t('common.actions.search')}
        placeholder={t('chargepoints.filter.search-text')}
        onSearch={onSearchSubmit}
        allowClear
        loading={isLoading}
      />
    </div>
  );
};
