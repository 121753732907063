import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchChargePointDiagnosticFiles } from '../fetches';
import { Responses } from '../types';

export const USE_CHARGE_POINT_DIAGNOSTIC_FILES_QUERY_KEY =
  'charge.point.diagnostic.logs';

export function useChargePointDiagnosticFilesQuery(
  partnerId: string,
  chargePointId: string,
  options?: UseQueryOptions<
    Responses.ResChargePointsDiagnosticFiles,
    Types.HttpError,
    Responses.ResChargePointsDiagnosticFiles,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_POINT_DIAGNOSTIC_FILES_QUERY_KEY, partnerId, chargePointId],
    () => fetchChargePointDiagnosticFiles(partnerId, chargePointId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
