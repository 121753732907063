import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';
import * as Helpers from 'core/helpers';

import { Responses } from '../../partners/types';
import { SubpartnersInviteRequest } from '../types';

/**
 * Register a subpartner
 */
export async function fetchSubpartnersRegister<
  TResponse = Responses.ResPartner,
  TError = Types.HttpError,
>(data: SubpartnersInviteRequest) {
  try {
    const response = await Services.apiService.post<TResponse>(
      `partners/sub-partners`,
      data,
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
