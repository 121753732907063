import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';

import { ChargePointsTypes } from '..';

export async function fetchChargePointsChangeStatus(
  params: ChargePointsTypes.Requests.ReqChargePointsChangeStatusParams,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response =
      await Services.apiService.post<ChargePointsTypes.Responses.ResChargePointsPartnerAssign>(
        `partners/${params.partnerId}/charging/charge-points/${params?.id}/status`,
        undefined,
        {
          params: { status: params?.status },
          headers: { ...header },
        },
      );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data);
  }
}
