import { Button, Popconfirm } from 'antd';
import { ReportTaskPeriod, ReportType } from 'features/reports';
import { reportPeriodLabels, reportTypeLabels } from 'pages/Reports/helpers/formOptions';
import { useTranslation } from 'react-i18next';

import { RegularReportTableData } from './types';

export const useRegularReportsColumns = (
  handleDelete: (itemId: RegularReportTableData['id']) => Promise<void>,
) => {
  const { t } = useTranslation();

  return [
    {
      title: t('reports.columns.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: ReportType) => {
        return t(reportTypeLabels[type]);
      },
    },
    {
      title: t('reports.columns.period'),
      dataIndex: 'period',
      key: 'period',
      render: (period: ReportTaskPeriod) => {
        return t(reportPeriodLabels[period]);
      },
    },
    {
      title: t('reports.columns.actions'),
      render: (_: unknown, item: RegularReportTableData) => (
        <Popconfirm
          title={t('reports.title.delete-task')}
          description={t('reports.text.delete-task-confirmation')}
          okText={t('reports.text.yes')}
          cancelText={t('reports.text.no')}
          onConfirm={() => handleDelete(item.id)}
        >
          <Button>{t('common.actions.delete')}</Button>
        </Popconfirm>
      ),
    },
  ];
};
