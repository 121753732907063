import { useState } from 'react';
import { Padding } from 'components/Padding';
import { Button, Descriptions, DescriptionsProps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BusinessDetailsKeys } from 'features/charge-points/types/ChargePointPartnerItem';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';

import { useBusinessDetailsLabels } from './useBusinessDetailsLabels';
import { BusinessDetailsUpdateForm } from './BusinessDetailsUpdateForm';

export const BusinessDetails = () => {
  const { chargePoint } = useChargePointsDetailsContext();
  const businessDetailsLabels = useBusinessDetailsLabels();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const showBusinessDetailsPopup = () => {
    setIsPopupVisible(true);
  };

  const closeBusinessDetailsPopup = () => {
    setIsPopupVisible(false);
  };

  const { t } = useTranslation();

  let businessDetailsItems: DescriptionsProps['items'] = [];

  for (const item in chargePoint.businessDetails) {
    businessDetailsItems.push({
      key: item,
      label: businessDetailsLabels[item as BusinessDetailsKeys] + ': ',
      children: chargePoint?.businessDetails[item as BusinessDetailsKeys],
    });
  }

  return (
    <>
      <Padding isHorizontal noStart>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {t('chargepoints.titles.business-details')}
        </Typography.Title>
        <Button type="primary" onClick={showBusinessDetailsPopup}>
          {t('common.actions.update')}
        </Button>
      </Padding>
      {businessDetailsItems.length > 0 && (
        <Descriptions
          bordered
          items={businessDetailsItems}
          size="small"
          style={{ marginBottom: 24 }}
        />
      )}

      <BusinessDetailsUpdateForm
        isOpen={isPopupVisible}
        onCancel={closeBusinessDetailsPopup}
        onSuccess={closeBusinessDetailsPopup}
      />
    </>
  );
};
