import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { AuthFetches } from '..';
import { SessionToken } from '../types';

export function useAuthTokenRefreshMutation(
  options?: UseMutationOptions<SessionToken, Types.HttpError>,
) {
  return useMutation(AuthFetches.fetchAuthTokenRefresh, {
    retry: 0,
    ...options,
  });
}
