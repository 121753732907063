import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';
import { fetchPartnersBillingClients } from 'features/partners/fetches/fetchPartnersBillingClints';

import { Responses } from '../types';

export const USE_PARTNERS_BILLING_CLIENTS = 'partners.billing.clients';

export function usePartnersBillingClientsBalance(
  partnerId: string,
  clientId: string,
  options?: UseQueryOptions<
    Responses.ResPartnerBillingClient,
    Types.HttpError,
    Responses.ResPartnerBillingClient,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNERS_BILLING_CLIENTS, partnerId, clientId],
    () => fetchPartnersBillingClients(partnerId, clientId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
