import { FC, memo, useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { Breadcrumb } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isDefined } from 'core/utils/typeguards';

import s from './PricingBreadcrumbs.module.css';

type Props = {
  className?: string;
};

const PricingBreadcrumbs: FC<Props> = ({ className }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const root = useMemo(
    () => ({
      title: <Link to={'/pricing'}>{t('pricing.breadcrumbs.pricing')}</Link>,
    }),
    [t],
  );

  const [breadcrumbs, setBreadcrumbs] = useState([root]);

  useEffect(() => {
    let group;
    let tariff;

    const groupMatch = location.pathname.match(/group\/([-0-9a-f]|new)+/);
    if (groupMatch && groupMatch[1]) {
      group = {
        title: (
          <Link to={`/pricing/${groupMatch[0]}`}>
            {t('pricing.breadcrumbs.tariff-group')}
          </Link>
        ),
      };

      const tariffMatch = location.pathname.match(/tariff\/([-0-9a-f]|new)+/);
      if (tariffMatch && tariffMatch[1]) {
        if (groupMatch && groupMatch[1]) {
          tariff = {
            title: (
              <Link to={`/pricing/${groupMatch[0]}/${tariffMatch[0]}`}>
                {t('pricing.breadcrumbs.tariff')}
              </Link>
            ),
          };
        }
      }
    }

    const nextBreadcrumbs = [root, group, tariff].filter(isDefined);

    setBreadcrumbs(nextBreadcrumbs);
  }, [location.pathname, root, t]);

  return (
    <div className={cn(s.wrapper, className)}>
      <Breadcrumb items={breadcrumbs} />
    </div>
  );
};

export default memo(PricingBreadcrumbs);
