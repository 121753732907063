import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerFileUpload } from '../fetches';
import { ResPartnerFileUpload } from '../types';

export function usePartnerFileUploadMutation(
  options?: UseMutationOptions<
    ResPartnerFileUpload,
    Types.HttpError,
    Parameters<typeof fetchPartnerFileUpload>
  >,
) {
  return useMutation((args) => fetchPartnerFileUpload(...args), {
    retry: 0,
    ...options,
  });
}
