import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchTransactionStop } from '../fetches';
import { ResTransactionStop } from '../types/responses';

export function useTransactionStopMutation(
  options?: UseMutationOptions<
    ResTransactionStop,
    Types.HttpError,
    Parameters<typeof fetchTransactionStop>
  >,
) {
  return useMutation((args) => fetchTransactionStop(...args), {
    retry: 0,
    ...options,
  });
}
