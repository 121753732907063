import { Button } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Pagination.module.css';
import type { ArrowPaginationProps } from './types';

const DefaultFirstButton = (params: any) => {
  const { t } = useTranslation();

  return <Button {...params}>{t('common.pagination.first-page')}</Button>;
};

const DefaultNextButton = (params: any) => {
  const { t } = useTranslation();

  return (
    <Button type={'primary'} {...params}>
      {t('common.pagination.next')}
    </Button>
  );
};

const DefaultPrevButton = (params: any) => {
  const { t } = useTranslation();

  return (
    <Button type={'primary'} {...params}>
      {t('common.pagination.previous')}
    </Button>
  );
};

export function Pagination({
  page,
  firstPage = 1,
  hasPrev,
  hasNext,
  renderFirstButton = DefaultFirstButton,
  renderNextButton = DefaultNextButton,
  renderPrevButton = DefaultPrevButton,
  children,
  onChange,
}: PropsWithChildren<ArrowPaginationProps>) {
  const enableFirstPage = firstPage < page;
  const enablePrevPage = enableFirstPage || hasPrev;
  const enableNextPage = hasNext;

  return (
    <div className={styles.pagination_container}>
      <div className={styles.pagination_left}>
        <div style={{ display: enableFirstPage ? 'block' : 'none' }}>
          {renderFirstButton({
            onClick: (event) => onChange(event, firstPage),
          })}
        </div>
        <div>
          {renderPrevButton({
            disabled: !enablePrevPage,
            onClick: (event) => onChange(event, page - 1),
          })}
        </div>
      </div>
      <div>{children}</div>
      <div>
        {renderNextButton({
          disabled: !enableNextPage,
          onClick: (event) => onChange(event, page + 1),
        })}
      </div>
    </div>
  );
}
