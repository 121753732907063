import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import type { ResChargePointsUnlockConnector } from '../types/responses';
import type { ReqChargePointsUnlockConnector } from '../types/requests';

export async function fetchChargePointsUnlockConnector<
  TResponse = ResChargePointsUnlockConnector,
  TError = Types.HttpError,
>(
  partnerId: string,
  chargePointId: string,
  params: ReqChargePointsUnlockConnector,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `/partners/${partnerId}/charging/charge-points/${chargePointId}/unlock`,
      null,
      {
        headers: { ...header },
        params: {
          connId: params.connectorId
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}

