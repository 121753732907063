import { ExclamationCircleFilled } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, message, Modal, Space } from 'antd';
import { ChargePointPartnerItem } from 'features/charge-points/types/ChargePointPartnerItem';
import {
  useTransactionsSearchQuery,
  useTransactionStopMutation,
  USE_TRANSACTIONS_SEARCH_QUERY_KEY,
} from 'features/transactions/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

export interface ActionsProps {
  chargePoint?: ChargePointPartnerItem;
  disabled?: boolean;
}

export function Actions({ chargePoint, disabled = false }: ActionsProps) {
  const [messageApi] = message.useMessage();
  const queryClient = useQueryClient();

  const partnerId = usePartnerId();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const {
    data: transactions,
    isLoading,
    refetch,
  } = useTransactionsSearchQuery({
    statuses: 'active',
    cpId: chargePoint?.id,
    partnerId,
    deviceId: chargePoint?.device.deviceId,
    size: 100000000,
  });

  const stop = useTransactionStopMutation({
    onError: (error) => {
      messageApi.open({
        type: 'error',
        content: errorT(error?.translationKey, error.message),
      });
    },
  });

  const handleStopAll = () => {
    if (!chargePoint || !transactions?.items?.length) {
      return;
    }

    Modal.confirm({
      title: t('transactions.stop_modal.title'),
      icon: <ExclamationCircleFilled />,
      cancelText: t('common.actions.cancel'),
      async onOk() {
        for (const trans of transactions?.items) {
          await stop.mutateAsync([partnerId, chargePoint.id, trans.id]);
        }

        // FIXME
        // TODO potential bug here, refetch is called before the mutation is completed
        setTimeout(() => {
          refetch();
          queryClient.invalidateQueries([USE_TRANSACTIONS_SEARCH_QUERY_KEY]);
        }, 1000);
      },
    });
  };

  const hasActive = !!transactions?.items?.length;

  return (
    <Space>
      <Button
        disabled={isLoading || !hasActive || disabled}
        loading={stop.isLoading}
        onClick={handleStopAll}
      >
        {t('transactions.actions.stop-all')} ({transactions?.items?.length ?? 0}
        )
      </Button>
    </Space>
  );
}
