import { BasePartner } from 'features/partners/types';
import { useTranslation } from 'react-i18next';

import { Description } from './Description';

export const LimitsDescription = ({
  data,
}: {
  data: BasePartner['limits'];
}) => {
  const { t } = useTranslation();
  return (
    <Description
      title={t('profile.titles.limits')}
      data={[
        {
          label: t('profile.limits.currency'),
          value: data?.clientDebt?.currency ?? '-',
        },
        {
          label: t('profile.limits.max-amount'),
          value: data?.clientDebt?.maxDebtAmount ?? '-',
        },
        {
          label: t('profile.limits.enabled'),
          value:
            data?.clientDebt?.enabled !== undefined
              ? data?.clientDebt?.enabled
                ? t('profile.limits.enabled-yes')
                : t('profile.limits.enabled-no')
              : '-',
        },
      ]}
    />
  );
};
