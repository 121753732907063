import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';
import { fetchPartnersClients } from 'features/partners/fetches/fetchPartnersClients';
import * as Helpers from 'core/helpers';

import { Requests, Responses } from '../types';

export const USE_PARTNERS_CLIENTS_SEARCH_QUERY_KEY = 'partners.clients.search';

export function usePartnersClientsQuery(
  partnerId: string,
  params?: Requests.ReqPartnersClientsQueryParams,
  pagination?: Helpers.RequestPagination,
  options?: UseQueryOptions<
    Responses.ResPartnerUser,
    Types.HttpError,
    Responses.ResPartnerUser,
    any[]
  >,
) {
  const queryPagination = { index: 1, size: 20, ...pagination };
  const query = useQuery(
    [
      USE_PARTNERS_CLIENTS_SEARCH_QUERY_KEY,
      partnerId,
      params,
      queryPagination,
    ],
    () => fetchPartnersClients(partnerId, params, queryPagination),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );

  const nextPage= query.data?.clients?.length === queryPagination?.size;
  const prevPage = queryPagination.index > 1;

  return {
    ...query,
    nextPage,
    prevPage,
  };
}
