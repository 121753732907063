import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCountries } from '../fetches';
import { Requests, Responses } from '../types';

export const USE_COUNTRIES_QUERY_KEY = 'catalog.countries';

export function useCountries(
  options?: UseQueryOptions<
    Requests.ReqCountries,
    Types.HttpError,
    Responses.ResCountries,
    any[]
  >,
) {
  return useQuery([USE_COUNTRIES_QUERY_KEY], () => fetchCountries(), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    ...options,
  });
}
