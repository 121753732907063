import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagUnassignClient } from '../fetches';
import { ReqChargeTagUnassignClient } from '../types/requests';
import { ResChargeTagUnassignClient } from '../types/responses';

export function useChargeTagUnassignClientMutation(
  options?: UseMutationOptions<
    ResChargeTagUnassignClient,
    Types.HttpError,
    ReqChargeTagUnassignClient
  >,
) {
  return useMutation(fetchChargeTagUnassignClient, {
    retry: 0,
    ...options,
  });
}
