import i18next, { SupportedLanguages } from 'i18n/config';
import dayjs from 'dayjs';
import { setDateLocale } from 'i18n/dateLocales';

export const weekDays = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
] as const;

export type WeekDay = (typeof weekDays)[number];

const getWeekDayName = (date: number, language: SupportedLanguages = 'en') => {
  setDateLocale(language);

  return dayjs(date).format('dddd');
};

export const getWeekDayLabel = (day: WeekDay): string => {
  const idx = weekDays.findIndex((d) => d === day);

  return getWeekDayName(
    Date.UTC(2021, 5, idx),
    i18next.languages[0] as SupportedLanguages,
  );
};
