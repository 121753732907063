import { theme } from 'antd';
import { useIsSubpartner, usePartner } from 'core/providers/PartnerProvider';
import { WebTranslateKey } from 'i18n/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  {
    key: '0',
    translationKey: 'navigation.dashboard',
    iconPath: '/dashboard.png',
    path: '/',
    subpartnerAllowed: true,
  },
  {
    key: '1',
    translationKey: 'navigation.chargepoints',
    iconPath: '/station.png',
    path: '/points',
    subpartnerAllowed: true,
  },
  {
    key: '2',
    translationKey: 'navigation.balancing-profiles',
    iconPath: '/balance.png',
    path: '/balancing-profiles',
  },
  {
    key: '3',
    translationKey: 'navigation.rfid-cards',
    iconPath: '/rifd-card.png',
    path: '/tags',
  },
  {
    key: '4',
    translationKey: 'navigation.transactions',
    iconPath: '/report.png',
    path: '/transactions',
    subpartnerAllowed: true,
  },
  {
    key: '5',
    translationKey: 'navigation.pricing',
    iconPath: '/pricing.png',
    path: '/pricing',
  },
  {
    key: '6',
    translationKey: 'navigation.clients',
    iconPath: '/clients.svg',
    path: '/clients',
  },
  {
    key: '7',
    translationKey: 'navigation.reports',
    iconPath: '/reports.svg',
    path: '/reports',
  },
  {
    key: '8',
    translationKey: 'navigation.subpartners',
    iconPath: '/subpartners.svg',
    path: '/sub-partners',
  },
];

export const useMenuItems = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const menuItemColor = token.Menu?.itemColor;

  const isSubpartner = useIsSubpartner();

  const { t } = useTranslation();

  const iconStyle = menuItemColor === '#fff' ? { filter: 'invert(1)' } : {};

  const items = menuItems
    .filter((item) => {
      if (!isSubpartner) return true;

      if (item.subpartnerAllowed) {
        return true;
      }

      return false;
    })
    .map((item) => {
      return {
        key: item.key,
        label: t(item.translationKey as WebTranslateKey),
        onClick: () => navigate(item.path),
        icon: <img src={item.iconPath} height="20px" style={iconStyle} />,
      };
    });

  return items;
};
