import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ResChargeTagUnassignClient } from '../types/responses';
import { ReqChargeTagUnassignClient } from '../types/requests';

export async function fetchChargeTagUnassignClient<
  TResponse = ResChargeTagUnassignClient,
  TError = Types.HttpError,
>(data: ReqChargeTagUnassignClient) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.delete<TResponse>(
      `partners/${data.partnerId}/charging/tags/${data.tagId}/assign`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
