import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { ChargePointsTypes } from '..';
import { fetchChargePointsChangeStatus } from '../fetches';

export function useChargePointsChangeStatusMutation(
  options?: UseMutationOptions<
    ChargePointsTypes.Responses.ResChargePointsPartnerAssign,
    Types.HttpError,
    ChargePointsTypes.Requests.ReqChargePointsChangeStatusParams
  >,
) {
  return useMutation(fetchChargePointsChangeStatus, {
    retry: 0,
    ...options,
  });
}
