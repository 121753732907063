import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Typography } from 'antd';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { ReportStatus, ReportTaskPeriod, ReportType } from 'features/reports';
import { downloadExcelFile } from 'pages/Reports/helpers/downloadExcelFile';
import {
  REPORT_STATUS,
  reportPeriodLabels,
  reportTypeLabels,
} from 'pages/Reports/helpers/formOptions';

import { ReportFilesTableData } from './types';

export const useReportFilesColumns = () => {
  const partnerId = usePartnerId();

  const { t } = useTranslation();

  return [
    {
      title: t('reports.columns.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: ReportType) => {
        return t(reportTypeLabels[type]);
      },
    },
    {
      title: t('reports.columns.period'),
      dataIndex: 'period',
      key: 'period',
      render: (period: ReportTaskPeriod) => {
        return t(reportPeriodLabels[period]);
      },
    },
    {
      title: t('reports.columns.file-name'),
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: t('reports.columns.date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('reports.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: ReportStatus, item: ReportFilesTableData) => {
        const { color, translateKey } = REPORT_STATUS[status];
        const label = t(translateKey);
        
        return (
          <>
            <Typography.Text style={{ color }}>{label}</Typography.Text>
            {item.error && (
              <Tooltip title={item.error}>
                <InfoCircleOutlined
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: t('reports.columns.actions'),
      render: (_: unknown, item: ReportFilesTableData) => (
        <Button
          disabled={item.status !== 'ok'}
          onClick={() =>
            downloadExcelFile({
              partnerId,
              fileId: item.fileId,
              fileName: `${item.fileName}.xlsx`,
            })
          }
        >
          {t('common.actions.download')}
        </Button>
      ),
    },
  ];
};
