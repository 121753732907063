import { useTranslation } from 'react-i18next';
import { ResPartner } from 'features/partners/types/responses';

import { Description } from './Description';

export const InfoDescription = ({ data }: { data: ResPartner }) => {
  const { t } = useTranslation();
  return (
    <Description
      title={t('profile.general.info')}
      data={[
        {
          label: t('profile.general.name'),
          value: data?.legalDetails?.name ?? '-',
        },
        {
          label: t('profile.general.form'),
          value: data?.legalDetails?.form ?? '-',
        },
        {
          label: t('profile.general.tax-number'),
          value: data?.taxNo ?? '-',
        },
      ]}
    />
  );
};
