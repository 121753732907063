import { memo, Fragment } from 'react';
import {
  TariffPriceType,
  TariffPrice,
} from 'features/partners-tariffs-groups/types';
import { isDefined } from 'core/utils/typeguards';

import s from './PricesView.module.css';

const PricesView = (props: {
  prices: PartialRecord<TariffPriceType, TariffPrice>;
  currencySymbol: string;
}) => {
  const { currencySymbol, prices } = props;
  const entries = Object.entries(prices) as [TariffPriceType, TariffPrice][];

  return (
    <dl className={s.grid}>
      {entries.map(([type, price]) => (
        <Fragment key={type}>
          <dt>{type}</dt>
          <dd>
            {price.price ?? '0'} {currencySymbol}
            {isDefined(price.vatPercent) && <>/ {price.vatPercent}%</>}
          </dd>
        </Fragment>
      ))}
    </dl>
  );
};

export default memo(PricesView);
