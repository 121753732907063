import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqPartnerNaturalUpdateData } from '../types/requests';
import { ResPartnerUpdate } from '../types/responses/ResPartnerUpdate';

export async function fetchPartnerNaturalUpdate<
  TResponse = ResPartnerUpdate,
  TError = Types.HttpError,
>(partnerId: string, data: ReqPartnerNaturalUpdateData) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.put<TResponse>(
      `/partners/natural/${partnerId}`,
      data,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
