import {
  AutoComplete,
  Button,
  Form,
  Row,
  Col,
  Select,
  Input,
  Divider,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useChargePointsUpdateMutation } from 'features/charge-points/queries';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { useCountries } from 'features/catalog';
import {
  getCountriesSelectOptions,
  getCurrenciesSelectOptions,
  getTimezonesSelectOptions,
} from 'core/utils/countryUtils';
import { parkingTypesLabels } from 'core/types/ParkingType.enum';
import { SelectOptions } from 'core/types/SelectOptions';
import { useIsSubpartner, usePartner } from 'core/providers/PartnerProvider';
import { Address } from 'pages/ChargePointsDetails/tabs/LocationTab/Address';
import { facilityLabels } from 'components/view/Facility';

type FormValues = {
  lat: number;
  lon: number;
  address: string;
  countryCode: string;
  city: string;
  postalCode: string;
  state: string;
  timeZone: string;
  currency: string;
};

const LocationTab = () => {
  const partner = usePartner();
  const partnerCountryCode = partner?.countryCode || '';
  const { chargePoint, refetch, isExternalResource } =
    useChargePointsDetailsContext();

  const { t } = useTranslation();

  const isSubpartner = useIsSubpartner();

  const { location: savedLocation, currency: savedCurrency } = chargePoint;

  const initialFormValues: FormValues = {
    countryCode: partnerCountryCode,
    currency: savedCurrency,
    ...savedLocation,
  };

  const [form] = Form.useForm<FormValues>();
  const countryCode = Form.useWatch('countryCode', form);
  const currency = Form.useWatch('currency', form);
  const timezone = Form.useWatch('timeZone', form);

  const mutation = useChargePointsUpdateMutation();

  const [isLoading, setLoading] = useState(false);

  const { data: countries } = useCountries();
  const countrySelectOptions = getCountriesSelectOptions(countries || []);
  const [timezones, setTimezones] = useState<SelectOptions[]>([]);
  const [currencies, setCurrencies] = useState<SelectOptions[]>([]);

  useEffect(() => {
    setTimezones(
      getTimezonesSelectOptions(
        countries?.find((c) => c.alfa3 === countryCode)?.timeZones || [],
      ),
    );
    const country = countries?.find((c) => c.alfa3 === countryCode);
    if (country) {
      setCurrencies(getCurrenciesSelectOptions(country.currencies));
    }
  }, [countries, countryCode]);

  useEffect(() => {
    if (currencies.length) {
      const isCurrencyAvailable = !!currencies.find(
        (c) => c.value === currency,
      );

      if (!isCurrencyAvailable) {
        form.setFieldValue('currency', currencies[0].value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, form]);

  useEffect(() => {
    if (timezones.length) {
      const isTimezoneAvailable = !!timezones.find((c) => c.value === timezone);

      if (!isTimezoneAvailable) {
        form.setFieldValue('timeZone', timezones[0].value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones, form]);

  const handleSubmit = (values: FormValues) => {
    const { currency } = values;

    const data = {
      id: chargePoint.id,
      location: values,
      deviceId: chargePoint.device?.deviceId,
      partnerId: chargePoint.partner?.id,
      currency,
    };
    mutation.mutate(data, {
      onSuccess: () => refetch(),
    });
  };

  if (!countries) {
    return <Spin />;
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      name="location"
      disabled={isLoading || isExternalResource || isSubpartner}
      initialValues={initialFormValues}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={10}>
          <Row>
            <Form.Item
              name="countryCode"
              label={`${t('chargepoints.labels.country')}:`}
              style={{ width: '100%' }}
            >
              <Select options={countrySelectOptions} />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="postalCode"
              label={`${t('chargepoints.labels.postal-code')}:`}
            >
              <Input />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="currency"
              label={`${t('chargepoints.labels.currency')}:`}
              style={{ width: '100%' }}
            >
              <Select options={currencies} />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="state"
              label={`${t('chargepoints.labels.state')}:`}
              style={{ width: '100%' }}
            >
              <Input />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="city"
              label={`${t('chargepoints.labels.city')}:`}
              style={{ width: '100%' }}
            >
              <Input />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="timeZone"
              label={`${t('chargepoints.labels.time-zone')}:`}
              style={{ width: '100%' }}
            >
              <AutoComplete options={timezones} />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="facilities"
              label={`${t('chargepoints.labels.facilities')}:`}
              style={{ width: '100%' }}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder={t('chargepoints.placeholders.select-facilities')}
                options={Object.entries(facilityLabels).map(([key, value]) => ({
                  value: Number(key),
                  label: t(value),
                }))}
              />
            </Form.Item>
          </Row>

          <Divider />

          <Row>
            <Form.Item
              name="parkingType"
              label={`${t('chargepoints.labels.parking-type')}:`}
              style={{ width: '100%' }}
            >
              <Select
                options={Object.entries(parkingTypesLabels).map(
                  ([key, value]) => ({
                    value: key,
                    label: t(value),
                  }),
                )}
              />
            </Form.Item>
          </Row>
        </Col>

        <Address
          form={form}
          onLoading={setLoading}
          savedLocation={savedLocation}
        />
      </Row>

      <Divider />

      <Button type="primary" htmlType="submit" loading={mutation.isLoading}>
        {t('common.actions.save-changes')}
      </Button>
    </Form>
  );
};

export default LocationTab;
