import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqChargeTagBlockUnblock } from '../types/requests';
import { ResChargeTagBlockUnblock } from '../types/responses';

export async function fetchChargeTagUnblock<
  TResponse = ResChargeTagBlockUnblock,
  TError = Types.HttpError,
>(data: ReqChargeTagBlockUnblock) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.delete<TResponse>(
      `partners/${data.partnerId}/charging/tags/${data.tagId}/lock`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
