import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Padding } from 'components/Padding';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import { GenerateReportSection } from './components/GenerateReportSection';
import { RegularReportsSection } from './components/RegularReportsSection';
import { ReportFilesSection } from './components/ReportFilesSection';

export const Reports = () => {
  const { t } = useTranslation();

  return (
    <>
      <Padding isHorizontal>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('reports.titles.reports')}
        </Typography.Title>
      </Padding>

      <Padding isHorizontal>
        <GenerateReportSection />
      </Padding>

      <Padding isHorizontal>
        <RegularReportsSection />
      </Padding>

      <Padding isHorizontal>
        <ReportFilesSection />
      </Padding>
    </>
  );
};
