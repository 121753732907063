import dayjs from 'dayjs';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { useIsSubpartner } from 'core/providers/PartnerProvider';

export const TechInfoTab = () => {
  const { chargePoint } = useChargePointsDetailsContext();

  const { t } = useTranslation();

  const isSubpartner = useIsSubpartner();

  return (
    <div>
      <Form
        layout={'vertical'}
        name="tech-info-form"
        className="tech-info-form"
        disabled={isSubpartner}
        initialValues={{
          lastConnectionTime: chargePoint.lastConnectionTime
            ? dayjs(chargePoint.lastConnectionTime).format('DD/MM/YYYY HH:mm')
            : '-',
          status: chargePoint.status ?? '-',
          firmwareVersion: chargePoint.device?.firmwareVersion ?? '-',
          iccid: chargePoint.device?.iccId ?? '-',
          imsi: chargePoint.device?.imsi ?? '-',
          meterSerialNumber: chargePoint.device?.meterSerialNumber ?? '-',
          meterType: chargePoint.device?.meterType ?? '-',
        }}
      >
        <Form.Item
          name="lastConnectionTime"
          label={`${t('chargepoints.labels.last-connection-time')}:`}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="status" label={`${t('chargepoints.labels.status')}:`}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="firmwareVersion"
          label={`${t('chargepoints.labels.firmware-version')}:`}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="iccid" label={'ICCID: '}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="imsi" label={'IMSI: '}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="meterSerialNumber"
          label={`${t('chargepoints.labels.meter-serial-number')}:`}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="meterType"
          label={`${t('chargepoints.labels.meter-type')}:`}
        >
          <Input disabled />
        </Form.Item>
      </Form>
    </div>
  );
};
