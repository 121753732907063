import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

export async function fetchSubpartnersCancelInvite<
  TResponse = { status: string },
  TError = Types.HttpError,
>(partnerId: string, token: string) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.delete<TResponse>(
      `/partners/${partnerId}/sub-partners/invitations/${token}`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
