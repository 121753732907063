import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagActivate } from '../fetches';
import { ReqChargeTagActiveData } from '../types/requests';
import { ResChargeTagActivate } from '../types/responses';

export function useChargeTagActivateMutation(
  options?: UseMutationOptions<
    ResChargeTagActivate,
    Types.HttpError,
    ReqChargeTagActiveData
  >,
) {
  return useMutation(fetchChargeTagActivate, {
    retry: 0,
    ...options,
  });
}
