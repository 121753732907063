import { AuthHelpers } from 'features/auth';
import * as Services from 'core/services';
import * as Types from 'core/types';

import { Responses } from '../types';

export async function fetchPartnersBillingClients<
  TResponse = Responses.ResPartnerBillingClient,
  TError = Types.HttpError,
>(
  partnerId: string,
  clientId: string,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}/billing/clients/${clientId}/balances/consumer`,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
