import { theme } from 'antd';
import { Outlet } from 'react-router-dom';

export function LayoutContent() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div style={{ backgroundColor: colorBgContainer, borderRadius: 8 }}>
      <Outlet />
    </div>
  );
}
