import { ColumnsType } from 'antd/es/table';
import { secsToTime } from 'core/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const useTopBySpendColumns = (): ColumnsType<Record<string, any>> => {
  const { t } = useTranslation();

  return [
    {
      fixed: 'left',
      title: t('dashboard.columns.user-phone'),
      dataIndex: 'clientPhone',
      render: (clientPhone: string, record) => {
        return <Link to={`/clients/${record.clientId}`}>{clientPhone}</Link>;
      },
      key: 'clientPhone',
    },
    {
      title: t('dashboard.columns.user-name'),
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: t('dashboard.columns.sessions-count'),
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: t('dashboard.columns.payments-received'),
      dataIndex: 'amountEur',
      key: 'amountEur',
    },
    {
      title: t('dashboard.columns.total-time'),
      dataIndex: 'totalTimeSec',
      render: (v: number) => secsToTime(v),
      key: 'totalTimeSec',
    },
    {
      title: t('dashboard.columns.total-kwh'),
      dataIndex: 'totalKwh',
      key: 'totalKwh',
    },
  ];
};
