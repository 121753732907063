import { Typography } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatCard } from 'components/StatCard';
import { useChargePointsPartnerItemsQuery } from 'features/charge-points/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';

import styles from './ChargePointsStats.module.css';

export function ChargePointsStats() {
  const partnerId = usePartnerId();

  const { t } = useTranslation();

  const { data } = useChargePointsPartnerItemsQuery(
    partnerId,
    { withAvailability: true },
    { index: 1, size: 999999 },
  );

  const stats = useMemo(() => {
    return {
      total: data?.items?.length ?? 0,
      active: data?.items?.filter((i) => i.status === 'active')?.length ?? 0,
    };
  }, [data]);

  return (
    <div className={styles.root}>
      <div>
        <Typography.Title level={4}>
          {t('dashboard.titles.chargepoints')}
        </Typography.Title>
      </div>
      <div className={styles.grid}>
        <StatCard title={t('dashboard.titles.total-chargepoints')}>
          {stats.total}
        </StatCard>
        <StatCard title={t('dashboard.titles.active-chargepoints')}>
          {stats.active}
        </StatCard>
        <StatCard title={t('dashboard.titles.inactive-chargepoints')}>
          {stats.total - stats.active}
        </StatCard>
      </div>
    </div>
  );
}
