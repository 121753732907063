import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useQueryClient } from '@tanstack/react-query';
import {
  message,
  Button,
  Descriptions,
  Table,
  DatePicker,
  Spin,
  Empty,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { formatBytes } from 'core/utils';
import {
  useChargePointDiagnosticActiveQuery,
  useChargePointsDiagnosticFileMutation,
  useChargePointDiagnosticFilesQuery,
  USE_CHARGE_POINT_DIAGNOSTIC_ACTIVE_QUERY_KEY,
} from 'features/charge-points/queries';
import { fetchPartnerFileLink } from 'features/files/fetches';
import { HttpError } from 'core/types';

export const DiagnosticTab = () => {
  const queryClient = useQueryClient();
  const { chargePoint } = useChargePointsDetailsContext();
  const [messageApi, contextHolder] = message.useMessage();
  const [dates, setDates] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);

  const partnerId = usePartnerId();
  const isSubpartner = useIsSubpartner();

  const filesQuery = useChargePointDiagnosticFilesQuery(
    partnerId,
    chargePoint.id,
    {
      enabled: !!partnerId && !!chargePoint.id && !isSubpartner,
    },
  );
  const activeQuery = useChargePointDiagnosticActiveQuery(
    partnerId,
    chargePoint.id,
    { enabled: !!partnerId && !!chargePoint.id && !isSubpartner },
  );

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const uploadFile = useChargePointsDiagnosticFileMutation();

  if (filesQuery.isInitialLoading || activeQuery.isInitialLoading) {
    return <Spin />;
  }

  if (filesQuery.isError) {
    return <div>{t('common.status.error')}...</div>;
  }

  if (isSubpartner) {
    return <Empty />;
  }

  const handleDateFromChange = (date: any) => {
    if (!date) {
      return;
    }

    setDates([date, dates[1]]);
  };

  const handleDateToChange = (date: any) => {
    if (!date) {
      return;
    }

    setDates([dates[0], date]);
  };

  const handleRequestDownload = async () => {
    await uploadFile
      .mutateAsync([
        partnerId,
        chargePoint.id,
        dates[0].toISOString(),
        dates[1].toISOString(),
      ])
      .then(() => {
        queryClient.invalidateQueries([
          USE_CHARGE_POINT_DIAGNOSTIC_ACTIVE_QUERY_KEY,
        ]);
        messageApi.open({
          type: 'success',
          content: t('common.status.success'),
        });
      })
      .catch((e: HttpError) => {
        messageApi.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      });
  };

  const handleDownload = async (fileId: string) => {
    try {
      const url = await fetchPartnerFileLink(partnerId, fileId);
      window.location.assign(url);
    } catch (error) {
      if ((error as HttpError).translationKey) {
        messageApi.open({
          type: 'error',
          content: errorT((error as HttpError).translationKey),
        });
      } else {
        messageApi.open({
          type: 'error',
          content: t('common.errors.unknown-error'),
        });
      }
    }
  };

  const columns = [
    {
      title: t('chargepoints.columns.file-name'),
      key: 'fileName',
      dataIndex: 'fileName',
    },
    {
      title: t('chargepoints.columns.size'),
      key: 'size',
      dataIndex: 'size',
      render: (size: number) => formatBytes(size),
    },
    {
      title: t('chargepoints.columns.last-modified'),
      key: 'lastModified',
      dataIndex: 'lastModified',
      render: (date: string) =>
        date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: '',
      width: '50px',
      align: 'center' as 'center',
      dataIndex: 'fileId',
      key: 'fileId',
      render: (fileId: string) => {
        return (
          <Button type="default" onClick={() => handleDownload(fileId)}>
            {t('chargepoints.actions.download')}
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      {contextHolder}
      <div
        style={{
          marginBottom: '32px',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <DatePicker
          placeholder={t('chargepoints.placeholders.date-from')}
          value={dates[0]}
          onChange={handleDateFromChange}
          disabledDate={(current) => current > dates[1]}
          format="DD-MM-YYYY"
          allowClear={false}
        />
        -
        <DatePicker
          placeholder={t('chargepoints.placeholders.date-to')}
          value={dates[1]}
          onChange={handleDateToChange}
          disabledDate={(current) => current > dayjs()}
          format="DD-MM-YYYY"
          allowClear={false}
        />
        <Button type="primary" onClick={handleRequestDownload}>
          {t('chargepoints.actions.run-diagnostic')}
        </Button>
      </div>
      {!!activeQuery.data && (
        <div style={{ marginBottom: '32px' }}>
          <Descriptions
            title={t('chargepoints.titles.active-diagnostic')}
            bordered
          >
            <Descriptions.Item label={t('chargepoints.labels.id')}>
              {activeQuery.data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.chargepoint-id')}>
              {activeQuery.data?.cpId}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('chargepoints.labels.chargepoint-device-id')}
            >
              {activeQuery.data?.cpDeviceId}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.accepted-at')}>
              {activeQuery.data?.acceptedAt}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.status')}>
              {activeQuery.data?.status}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.requested-at')}>
              {activeQuery.data?.requestedAt}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.period-from')}>
              {activeQuery.data?.periodFrom}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.period-to')}>
              {activeQuery.data?.periodTo}
            </Descriptions.Item>
            <Descriptions.Item label={t('chargepoints.labels.file-name')}>
              {activeQuery.data?.filename}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
      <div>
        <Descriptions title={t('chargepoints.titles.files')}></Descriptions>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={filesQuery.data}
        />
      </div>
    </div>
  );
};
