import { createContext, useContext } from 'react';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import type { HttpError } from 'core/types';
import type { ChargePointPartnerItem } from 'features/charge-points/types/ChargePointPartnerItem';

export type ChargePointsDetailsContextType = {
  chargePoint: ChargePointPartnerItem;
  isExternalResource: boolean;
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<ChargePointPartnerItem, HttpError>>;
  updateCache: (newChargePointData: ChargePointPartnerItem) => void;
};

export const ChargePointsDetailsContext =
  createContext<ChargePointsDetailsContextType | null>(null);

export const useChargePointsDetailsContext = () => {
  const data = useContext(ChargePointsDetailsContext);

  if (!data) {
    throw new Error(
      'useChargePointsDetailsContext must be used within a ChargePointsDetailsContext.Provider',
    );
  }
  return data;
};
