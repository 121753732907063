import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { AuthFetches } from '..';

export function useAuthLogoutMutation(
  options?: UseMutationOptions<unknown, Types.HttpError>,
) {
  return useMutation(AuthFetches.fetchAuthLogout, { retry: 0, ...options });
}
