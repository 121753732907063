import 'antd/dist/reset.css';
import { Splash } from 'components/Splash/Splash';
import { useAuth } from 'features/auth/hooks';
import { AppRoutes } from 'AppRoutes';
import { useSetupTranslations } from 'i18n/useSetupTranslations';

function App() {
  const auth = useAuth();
  const { isLoading: isLanguageLoading } = useSetupTranslations();

  const isLoading =
    isLanguageLoading ||
    !auth.isStorageSynced ||
    auth.isSessionRefreshing ||
    (auth.isAuthed && !auth.user?.id);

  return isLoading ? <Splash /> : <AppRoutes />;
}

export default App;
