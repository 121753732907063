import { SubpartnersInvitations } from 'features/subpartners';

export enum PartnerType {
  LEGAL = 'legal',
  NATURAL = 'natural',
}

export type InviteFormField = {
  email: string;
  type: PartnerType;
  name?: string;
  firstName?: string;
  lastName?: string;
};

export type InviteFormProps = {
  onInvitationSent: () => void;
};

export type PendingInvitationsProps = {
  invitations: SubpartnersInvitations;
  cancelInvitation: (token: string) => void;
};
