import {
  Alert,
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'components/PhoneInput';
import { useIsSubpartner, usePartner } from 'core/providers/PartnerProvider';
import { getCountriesSelectOptions } from 'core/utils';
import { useCountries } from 'features/catalog';

import { useProfileData } from './useProfileUpdate';

export interface ProfileUpdateFormProps {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
}

export function ProfileUpdateForm({
  isOpen,
  onCancel,
  onSuccess,
  onError,
}: ProfileUpdateFormProps) {
  const [form] = Form.useForm();
  const partner = usePartner();

  const { t } = useTranslation();

  const { data: countries } = useCountries();
  const alpha3SelectOptions = getCountriesSelectOptions(countries || []);

  const [updateProfile, { isLoading }] = useProfileData({ partner });

  const handleUpdate = (values: any) => {
    if (!partner) {
      return;
    }

    const data = {
      ...values,
      contacts: {
        email: !!values.contacts.email.contact
          ? {
              ...partner.contacts.email,
              ...values.contacts.email,
            }
          : undefined,
        phone: !!values.contacts.phone.contact
          ? {
              ...(partner.contacts?.phone || {}),
              ...values.contacts.phone,
            }
          : undefined,
      },
      taxNo: values.taxNo,
      details: {
        ...(partner.type === 'legal'
          ? partner.legalDetails
          : partner.naturalDetails),
        ...values.details,
        birthDate:
          partner?.type === 'legal'
            ? values.details?.birthDate?.toISOString()
            : undefined,
      },
    };

    updateProfile(data)
      .then(() => {
        onSuccess();
      })
      .catch(onError);
  };

  const renderForm = () => {
    if (!partner) {
      return <Empty />;
    }

    return (
      <Form
        form={form}
        id={'profile-form'}
        layout={'vertical'}
        initialValues={{
          ...partner,
          details: {
            name: partner.name,
            form: partner?.legalDetails?.form,
          },
        }}
        onFinish={handleUpdate}
      >
        {partner.type === 'legal' && (
          <>
            <Form.Item
              label={`${t('profile.general.name')}:`}
              name={['details', 'name']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={`${t('profile.general.form')}:`}
              name={['details', 'form']}
            >
              <Input />
            </Form.Item>
          </>
        )}
        {partner.type === 'natural' && (
          <>
            <Form.Item
              label={`${t('profile.general.first-name')}:`}
              name={['details', 'firstName']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={`${t('profile.general.last-name')}:`}
              name={['details', 'lastName']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={`${t('profile.general.middle-name')}:`}
              name={['details', 'middleName']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={`${t('profile.general.birthdate')}:`}
              name={['details', 'birthDate']}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label={`${t('profile.general.identification')}:`}
              name={['details', 'identification']}
            >
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item label={`${t('profile.general.tax-number')}:`} name="taxNo">
          <Input inputMode="numeric" />
        </Form.Item>
        <Form.Item
          label={`${t('profile.general.email')}:`}
          name={['contacts', 'email', 'contact']}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="countryCode"
          label={`${t('profile.general.country')}:`}
          rules={[
            {
              required: true,
              message: t('common.rules.country'),
            },
          ]}
        >
          <Select
            options={alpha3SelectOptions}
            placeholder={t('profile.general.select-country')}
          />
        </Form.Item>
        <Form.Item
          label={`${t('profile.general.phone')}:`}
          name={['contacts', 'phone', 'contact']}
          rules={[
            {
              pattern: /^\d{8,12}$/g,
              message: t('common.rules.phone'),
            },
          ]}
        >
          <PhoneInput />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={isOpen}
      title={t('profile.general.update-profile')}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} type={'default'} onClick={onCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          htmlType={'submit'}
          form={'profile-form'}
          loading={isLoading}
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      {renderForm()}
    </Modal>
  );
}
