import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ResPartnerTariffGroupUpdate } from '../types/responses';

export async function fetchPartnerTariffGroupTariffArchive<
  TResponse = ResPartnerTariffGroupUpdate,
  TError = Types.HttpError,
>(params: { partnerId: string; groupId: string; tariffId: string }) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}/tariffs/${params.tariffId}/archive`,
      undefined,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
