import { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { Typography, Button, Spin, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { TariffGroupsList } from 'pages/Pricing/TariffGroups/TariffGroupsList';
import {
  usePartnerTariffGroupDeleteMutation,
  usePartnerTariffGroupsQuery,
} from 'features/partners-tariffs-groups/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';

import s from './TariffGroups.module.css';

type Props = {
  className?: string;
};

const TariffGroups: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const deleteTariffGroupMutation = usePartnerTariffGroupDeleteMutation();

  const partnerId = usePartnerId();

  const {
    data: tariffGroups,
    isLoading,
    refetch: refetchGroups,
  } = usePartnerTariffGroupsQuery({ partnerId });

  const handleDelete = (groupId: string) => {
    deleteTariffGroupMutation.mutate(
      [
        {
          partnerId,
          groupId,
        },
      ],
      {
        onSuccess: () => refetchGroups(),
      },
    );
  };

  if (isLoading) {
    return <Spin />;
  }

  if (!tariffGroups) {
    return <Empty />;
  }

  return (
    <div className={cn(s.wrapper, className)}>
      <>
        <Padding isHorizontal>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t('pricing.titles.tariff-groups')}
          </Typography.Title>
          <Link to={'/pricing/group/new'}>
            <Button type={'primary'}>
              {t('pricing.actions.add-tariff-group')}
            </Button>
          </Link>
        </Padding>

        <Padding noStart>
          <TariffGroupsList
            tariffGroups={tariffGroups}
            onDelete={handleDelete}
          />
        </Padding>
      </>
    </div>
  );
};

export default TariffGroups;
