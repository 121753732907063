import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Form, Input, FormInstance } from 'antd';

import s from './TariffGroupForm.module.css';

type TariffGroup = { name: string };

type Props = {
  data?: TariffGroup;
  form: FormInstance;
  onSubmit: (data: TariffGroup) => void;
};

const TariffGroupForm: FC<Props> = ({ data, form, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(s.wrapper)}>
      <Form
        form={form}
        layout={'vertical'}
        initialValues={data}
        onFinish={onSubmit}
      >
        <Form.Item name="name" label={`${t('pricing.labels.tariff-name')}:`}>
          <Input
            placeholder={t('pricing.placeholders.tariff-group-name')}
            maxLength={120}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(TariffGroupForm);
