import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';
import * as Helpers from 'core/helpers';

import { Responses } from '../../partners/types';
import { fetchSubpartners } from '../fetches/fetchSubpartners';

export const USE_SUBPARTNERS_QUERY_KEY = 'partner.subpartners';

export function useSubpartnersQuery(
  partnerId: string,
  pagination?: Helpers.RequestPagination,
  options?: UseQueryOptions<
    Responses.ResPartnerUser[],
    Types.HttpError,
    Responses.ResPartnerUser[],
    any[]
  >,
) {
  const queryPagination = { index: 1, size: 20, ...pagination };
  const query = useQuery(
    [USE_SUBPARTNERS_QUERY_KEY, partnerId, queryPagination],
    () => fetchSubpartners(partnerId, queryPagination),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );

  const nextPage = query.data?.length === queryPagination?.size;
  const prevPage = queryPagination.index > 1;

  return {
    ...query,
    nextPage,
    prevPage,
  };
}
