import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';

import { RegularReportTask } from '../types';

/**
 * Delete regular report task by id
 */
export async function fetchDeleteRegularReportTask(
  partnerId: string,
  reportId: string,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.delete<{ status: string }>(
      `partners/${partnerId}/reports/scheduled/${reportId}`,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
