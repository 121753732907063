import { AuthHelpers } from 'features/auth';
import * as Services from 'core/services';
import * as Types from 'core/types';
import * as Helpers from 'core/helpers';

import { Requests, Responses } from '../types';


export async function fetchPartnersClients<
  TResponse = Responses.ResPartner,
  TError = Types.HttpError,
>(
  partnerId?: string,
  params?: Requests.ReqPartnersClientsQueryParams,
  pagination?: Helpers.RequestPagination,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}/clients/search/query`,
      {
        params: { ...params, ...Helpers.requestPagination(pagination) },
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
