import { AuthHelpers } from 'features/auth';
import * as Services from 'core/services';
import * as Types from 'core/types';

import { ChargePointsTypes } from '..';

/**
 * Search clients.
 *
 * - Method: GET
 * - [Swagger Docs](https://ev2go.secreate.dev/swagger/index.html#/charging/get_charging_charge_points_search_query)
 *
 * @param partnerId - partner id
 * @param params - Params
 *
 * @template TResponse - API response data type
 * @template TError - API error response data type
 */
export async function fetchChargePointsSearch<
  TResponse = ChargePointsTypes.Responses.ResChargePointsPartnerItemsParams,
  TError = Types.HttpError,
>(
  partnerId?: string,
  params?: ChargePointsTypes.Requests.ReqChargePointsPartnerItemsParams,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${partnerId}/charging/charge-points`,
      {
        params,
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
