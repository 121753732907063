import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin, Table } from 'antd';
import { useChargePointConfigsQuery } from 'features/charge-points/queries';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { ColumnsType } from 'antd/es/table';
import { ChargePointConfigItem } from 'features/charge-points/types/ChargePointConfigItem';
import { ChargePointSendConfigurationForm } from 'pages/ChargePointsDetails/Forms/ChargePointSendConfigurationForm';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';

import styles from './ConfigurationTab.module.css';

export const ConfigurationTab = () => {
  const [isModalOpened, setModalOpened] = useState(false);
  const partnerId = usePartnerId();
  const { chargePoint } = useChargePointsDetailsContext();
  const { data, isLoading, isError } = useChargePointConfigsQuery(
    partnerId,
    chargePoint.id,
  );

  const { t } = useTranslation();

  const isSubpartner = useIsSubpartner();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return (
      <div className={styles.configuration}>{t('common.status.failed')}</div>
    );
  }

  const columns: ColumnsType<ChargePointConfigItem> = [
    {
      title: t('chargepoints.columns.code'),
      dataIndex: 'code',
      key: 'code',
    },
    { title: t('chargepoints.columns.key'), dataIndex: 'key', key: 'key' },
    {
      title: t('chargepoints.columns.ocpp-version'),
      dataIndex: 'ocppVer',
      key: 'ocppVer',
    },
    {
      title: t('chargepoints.columns.readOnly'),
      dataIndex: 'readonly',
      key: 'readonly',
      render: (readonly: boolean) => (readonly ? 'Yes' : 'No'),
    },
    {
      title: t('chargepoints.columns.desc-trans-key'),
      dataIndex: 'descTransKey',
      key: 'descTransKey',
    },
    {
      title: t('chargepoints.columns.supported'),
      dataIndex: 'supported',
      key: 'supported',
      render: (supported: boolean) => (supported ? 'Yes' : 'No'),
    },
    {
      title: t('chargepoints.columns.value'),
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <div className={styles.configuration}>
      <ChargePointSendConfigurationForm
        configs={data ?? []}
        chargePoint={chargePoint}
        isOpen={isModalOpened}
        onCancel={() => setModalOpened(false)}
      />

      {!!data && (
        <div className={styles.configuration__header}>
          <Button
            type="primary"
            disabled={isSubpartner}
            onClick={() => setModalOpened(true)}
          >
            {t('chargepoints.actions.send-new-configuration')}
          </Button>
        </div>
      )}
      <div className={styles.configuration__body}>
        <Table
          columns={columns}
          size={'small'}
          pagination={false}
          dataSource={data || []}
        />
      </div>
    </div>
  );
};
