import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';
import { omit } from 'lodash-es';

import {
  RegularReportTask,
  CreateRegularReportTaskRequest,
} from '../types';

/**
 * Create regular report task
 */
export async function fetchCreateRegularReportTask(
  data: CreateRegularReportTaskRequest,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response =
      await Services.apiService.post<RegularReportTask>(
        `partners/${data.partnerId}/reports/scheduled`,
        omit(data, 'partnerId'),
        {
          headers: { ...header },
        },
      );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
