import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Layout } from 'antd';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Logo } from 'views/Logo';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useAuth, useAuthLogin } from 'features/auth/hooks';

import styles from './Login.module.css';

export function Login() {
  const { isAuthed } = useAuth();
  const login = useAuthLogin();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  if (isAuthed) {
    return <Navigate to={'/points'} />;
  }

  return (
    <Layout className={styles.login_container}>
      <div className={styles.login_content}>
        <div className={styles.login_logo}>
          <Logo />
        </div>
        <Form
          layout={'vertical'}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={login.login}
        >
          <Form.Item
            name="username"
            label={`${t('login.labels.email-username')}:`}
            rules={[
              {
                required: true,
                message: t('login.rules.enter-username'),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t('login.placeholders.email-username')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={`${t('login.labels.password')}:`}
            rules={[
              {
                required: true,
                message: t('login.rules.enter-password'),
              },
            ]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder={t('login.placeholders.password')}
            />
          </Form.Item>
          {!!login.error && (
            <Form.Item>
              <Alert
                type={'error'}
                description={t(
                  login.error.translationKey,
                  login.error?.message,
                )}
              />
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={login.isLoading}
              className="login-form-button"
            >
              {t('login.actions.login')}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <div style={{ textAlign: 'center' }}>
          <Link to="/register">{t('login.actions.register-link')}</Link>
        </div>
      </div>
    </Layout>
  );
}
