import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';
import * as Helpers from 'core/helpers';

import {
  GetGeneRatedReportsParams,
  GetGeneratedReportsResponse,
} from '../types';
import { fetchGetGeneratedReports } from '../fetches';

export const USE_GET_GENERATED_REPORTS_QUERY_KEY = 'partner.reports.generated';

export function useGetGeneratedReportsQuery(
  partnerId: string,
  params?: GetGeneRatedReportsParams,
  pagination?: Helpers.RequestPagination,
  options?: UseQueryOptions<
    GetGeneratedReportsResponse,
    Types.HttpError,
    GetGeneratedReportsResponse,
    any[]
  >,
) {
  const queryPagination = { index: 1, size: 20, ...pagination };
  const query = useQuery(
    [USE_GET_GENERATED_REPORTS_QUERY_KEY, partnerId, params, pagination],
    () => fetchGetGeneratedReports(partnerId, params, pagination),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );

  const hasNextPage = query.data?.items?.length === queryPagination?.size;
  const hasPrevPage = queryPagination.index > 1;

  return {
    ...query,
    hasNextPage,
    hasPrevPage,
  };
}
