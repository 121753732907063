import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { ResLoadBalancingProfileUpdate } from '../types/responses';
import { fetchLoadBalancingProfileUpdate } from '../fetches';

export function useLoadBalancingProfileUpdateMutation(
  options?: UseMutationOptions<
    ResLoadBalancingProfileUpdate,
    Types.HttpError,
    Parameters<typeof fetchLoadBalancingProfileUpdate>
  >,
) {
  return useMutation((args) => fetchLoadBalancingProfileUpdate(...args), {
    retry: 0,
    ...options,
  });
}
