import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargePointsUnlockConnector } from '../fetches';
import { ResChargePointsUnlockConnector } from '../types/responses';

export function useChargePointUnlockConnector(
  options?: UseMutationOptions<
    ResChargePointsUnlockConnector,
    Types.HttpError,
    Parameters<typeof fetchChargePointsUnlockConnector>
  >,
) {
  return useMutation((args) => fetchChargePointsUnlockConnector(...args), {
    retry: 0,
    ...options,
  });
}
