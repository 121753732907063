import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ResTransactionStop } from '../types/responses';

export async function fetchTransactionStop<
  TResponse = ResTransactionStop,
  TError = Types.HttpError,
>(partnerId: string, chargePointId: string, transactionId: string) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `partners/${partnerId}/charging/charge-points/${chargePointId}/transactions/${transactionId}/stop`,
      undefined,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
