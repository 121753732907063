import { Button, Drawer, Spin, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChargePointsPartnerItemsQuery } from 'features/charge-points/queries';
import { Padding } from 'components/Padding';
import { Pagination } from 'components/Pagination';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { ChargePointsAdd } from 'pages/ChargePoints/ChargePointAdd';

import { ChargePointsTable } from './ChargePointsTable';
import {
  ChargePointsFilter,
  useChargePointsFilter,
} from './ChargePointsFilter';

const PAGE_SIZE = 10;

export function ChargePoints() {
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const closeDrawer = () => setDrawerOpen(false);
  const openDrawer = () => setDrawerOpen(true);

  const partnerId = usePartnerId();

  const [filterState, dispatch] = useChargePointsFilter();

  /** read only access for subpartners */
  const isReadOnly = useIsSubpartner();

  const searchParams = useMemo(() => {
    return {
      fts: filterState.textSearch,
      ownerPartnerId: filterState.ownerPartnerId,
      hashtags: filterState.hashtags.join(','),
      statuses:
        filterState.availabilityStatus === 'all'
          ? undefined
          : filterState.availabilityStatus,
    };
  }, [filterState]);

  const { data, refetch, isLoading, hasNextPage, hasPrevPage } =
    useChargePointsPartnerItemsQuery(
      partnerId,
      {
        withAvailability: true,
        withEmergencyButtonTime: true,
        withLastConnectionTime: true,
        ...searchParams,
      },
      { index: page, size: PAGE_SIZE },
      { enabled: true },
    );

  const chargePoints = data?.items;

  /** we don't need to show pagination if theres in now data at all */
  const showPagination = (chargePoints && chargePoints.length > 0) || page > 1;

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('chargepoints.titles.chargepoints')}
        </Typography.Title>
        <Button type={'primary'} onClick={openDrawer} disabled={isReadOnly}>
          {t('chargepoints.actions.add-chargepoints')}
        </Button>
      </Padding>

      <Padding>
        <ChargePointsFilter
          filterState={filterState}
          dispatch={dispatch}
          isLoading={isLoading}
        />
      </Padding>

      <Padding noStart>
        <ChargePointsTable
          isLoading={isLoading}
          onSuccess={refetch}
          chargePoints={chargePoints}
          partnerId={partnerId}
        />
      </Padding>

      {showPagination && (
        <Padding>
          <Pagination
            page={page}
            hasNext={hasNextPage}
            hasPrev={hasPrevPage}
            onChange={(_, page) => setPage(page)}
          />
        </Padding>
      )}

      <Drawer
        title={t('chargepoints.titles.add-chargepoint')}
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={isDrawerOpen}
      >
        <ChargePointsAdd onClose={closeDrawer} />
      </Drawer>
    </>
  );
}
