import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Table, Typography } from 'antd';
import {
  usePartnerTariffGroupDeleteMutation,
  usePartnerTariffGroupsQuery,
} from 'features/partners-tariffs-groups/queries';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Padding } from 'components/Padding';
import { usePartnerId } from 'core/providers/PartnerProvider';

export const Tariff = () => {
  const navigate = useNavigate();

  const deleteTariffGroupMutation = usePartnerTariffGroupDeleteMutation();

  const partnerId = usePartnerId();

  const {
    data: tariffGroups,
    isLoading,
    refetch: refetchGroups,
  } = usePartnerTariffGroupsQuery({ partnerId });

  const onDelete = (item: any) => {
    Modal.confirm({
      title: 'Do you want to delete tariff group?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        deleteTariffGroupMutation.mutate(
          [
            {
              partnerId,
              groupId: item.id,
            },
          ],
          {
            onSuccess: () => refetchGroups(),
          },
        );
      },
    });
  };

  if (!tariffGroups) {
    return null;
  }

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (name: string, item: any) => (
        <Link to={`/partners/${item.partnerId}/tariffs/${item.key}/details`}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {name}
          </Typography.Title>
        </Link>
      ),
    },
    {
      dataIndex: 'item',
      key: 'item',
      render: (item: any) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '8px',
            }}
          >
            <Button
              onClick={() =>
                navigate(
                  `/partners/${item.partnerId}/tariffs/${item.id}/details`,
                )
              }
            >
              Detail
            </Button>
            <Button
              onClick={() =>
                navigate(
                  `/partners/${item.partnerId}/tariffs/groups/${item.id}/update`,
                )
              }
            >
              Update
            </Button>
            <Button onClick={() => onDelete(item)}>Delete</Button>
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    return <LoadingOutlined />;
  }

  return (
    <>
      <Padding isHorizontal>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Tariff groups
        </Typography.Title>
        <Button
          type={'primary'}
          onClick={() =>
            navigate(`/partners/${partnerId}/tariffs/groups/create`)
          }
        >
          Add tariff group
        </Button>
      </Padding>

      <Padding noStart>
        <Table
          // @ts-ignore
          size="small"
          columns={columns}
          dataSource={tariffGroups?.map((item: any) => ({
            key: item.id,
            name: item?.name,
            partnerId: item?.partnerId,
            isDefault: item?.isDefault,
            item,
          }))}
          scroll={{ x: 500 }}
          pagination={false}
        />
      </Padding>
    </>
  );
};
