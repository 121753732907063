import { useState } from 'react';
import { Alert, Button, Typography, Form, Input, Layout, message } from 'antd';
import { Logo } from 'views/Logo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useSubpartnersRegisterMutation } from 'features/subpartners';

import styles from './SubpartnerInvitation.module.css';

const SubpartnerInvitation = () => {
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const { mutate: mutateSubpartnerRegister } = useSubpartnersRegisterMutation();

  const handleRegistration = (values: any) => {
    mutateSubpartnerRegister(
      [
        {
          password: values.password,
          token: values.token,
        },
      ],
      {
        onSuccess: () => {
          message.success('Registration successful');
          navigate('/login');
        },
        onError: (error) => {
          setErrorMessage(errorT(error?.translationKey, error.message));
        },
        onSettled: () => {
          form.resetFields();
        },
      },
    );
  };

  if (!token) {
    return (
      <Layout className={styles.invitation__container}>
        <div
          className={styles.invitation__content}
          style={{ textAlign: 'center' }}
        >
          <Typography.Paragraph strong type="warning">
            You were not supposed to get here.
          </Typography.Paragraph>
          <Typography.Paragraph strong type="warning">
            Please close the page quickly.
          </Typography.Paragraph>
        </div>
      </Layout>
    );
  }

  return (
    <Layout className={styles.invitation__container}>
      <div className={styles.invitation__content}>
        <div className={styles.invitation__logo}>
          <Logo />
        </div>

        <Typography.Paragraph strong>
          Please complete you registration as a subpartner:
        </Typography.Paragraph>

        <Form form={form} layout={'vertical'} onFinish={handleRegistration}>
          <Form.Item
            name="password"
            label={'Password:'}
            rules={[
              { required: true, message: 'Password field is required' },
              { min: 6, message: 'Password minimal length is 6 symbols' },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={'Confirm password:'}
            rules={[
              { required: true, message: 'Password field is required' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name="token" initialValue={token} hidden>
            <Input />
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <Alert type={'error'} description={errorMessage} />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Register
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default SubpartnerInvitation;
