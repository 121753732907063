import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchPartnerNaturalUpdate } from '../fetches';
import { ResPartnerUpdate } from '../types/responses/ResPartnerUpdate';

export function usePartnerNaturalUpdateMutation(
  options?: UseMutationOptions<
    ResPartnerUpdate,
    Types.HttpError,
    Parameters<typeof fetchPartnerNaturalUpdate>
  >,
) {
  return useMutation((args) => fetchPartnerNaturalUpdate(...args), {
    retry: 0,
    ...options,
  });
}
