import { useRef } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'antd-phone-input';
import { PhoneNumber } from 'antd-phone-input/types';

import styles from './SupportContacts.module.css';
import { LinkInput } from './LinkInput';

export type SupportContactsFormValues = {
  email: string;
  chat: string;
  phone: PhoneNumber | string;
};

type SupportContactsProps = {
  onSubmit: (data: SupportContactsFormValues) => void;
  initialValues?: SupportContactsFormValues;
};

export const SupportContacts = ({
  onSubmit,
  initialValues,
}: SupportContactsProps) => {
  const isTouched = useRef(false);

  const { t } = useTranslation();

  const onFormSubmit = (formValues: SupportContactsFormValues) => {
    if (Object.values(formValues).every((value) => !value)) {
      return;
    }

    if (!isTouched.current) {
      return;
    }

    onSubmit(formValues);
  };

  return (
    <Form
      className={styles.container}
      layout="vertical"
      onFinish={onFormSubmit}
      initialValues={initialValues}
      style={{ paddingTop: 8 }}
      onChange={(e) => {
        isTouched.current = true;
      }}
    >
      <div className={styles.item}>
        <Form.Item
          className={styles.input}
          label={`${t('profile.support_contacts.support-email')}:`}
          name="email"
        >
          <Input type="email" />
        </Form.Item>
      </div>
      <div className={styles.item}>
        <LinkInput
          className={styles.input}
          label={`${t('profile.support_contacts.support-chat')}:`}
          name="chat"
          placeholder="t.me/chat"
        />
      </div>
      <div className={styles.item}>
        <Form.Item
          className={styles.input}
          label={`${t('profile.support_contacts.support-phone')}:`}
          name="phone"
          rules={[
            {
              validator: (_, { valid }) => {
                if (valid()) return Promise.resolve();
                return Promise.reject(
                  t('errors.app.cl.002', { ns: 'errors' }),
                );
              },
            },
          ]}
        >
          <PhoneInput enableSearch />
        </Form.Item>
      </div>
      <div className={styles.action}>
        <Button type="primary" htmlType="submit">
          {t('common.actions.save')}
        </Button>
      </div>
    </Form>
  );
};
