import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchTranslations } from '../fetches';
import { Translations } from '../types';

export const USE_TRANSLATIONS_QUERY_KEY =
  'catalog.translations.category.web';

export function useLoadTranslations(
  language = 'en',
  options?: UseQueryOptions<
    Translations,
    Types.HttpError,
    Translations,
    string[]
  >,
) {
  return useQuery(
    [USE_TRANSLATIONS_QUERY_KEY, language],
    () => fetchTranslations(language),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
