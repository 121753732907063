import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqTransactionsSearchParams } from '../types/requests';
import { ResTransactionsSearch } from '../types/responses';

export async function fetchTransactionsSearch<
  TResponse = ResTransactionsSearch,
  TError = Types.HttpError,
>(params?: ReqTransactionsSearchParams) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<TResponse>(
      `partners/${params?.partnerId}/charging/transactions/search/query`,
      {
        params,
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}

