import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';

import { ChargePointsTypes } from '..';

export async function fetchChargePointsSendConfigs<
  TResponse = {},
  TError = Types.HttpError,
>(
  partnerId: string,
  cpId: string,
  data: ChargePointsTypes.Requests.ReqChangePointsSendConfigs,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `partners/${partnerId}/charging/charge-points/${cpId}/configs`,
      data,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
