import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PartnerTariffGroupDetails } from 'views/PartnerTariffGroup';

export function PartnersTariffsDetails() {
  const navigate = useNavigate();
  const { groupId, partnerId } = useParams();

  return (
    <PartnerTariffGroupDetails
      partnerId={partnerId}
      tariffGroupId={groupId}
      rightAction={
        <Button
          type={'primary'}
          onClick={() =>
            navigate(`/partners/${partnerId}/tariffs/groups/${groupId}/update`)
          }
        >
          Update
        </Button>
      }
    />
  );
}
