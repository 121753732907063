import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useClientsPaymentHistoryQuery } from 'features/clients/queries/useClientsPaymentHistoryQuery';
import { Pagination } from 'components/Pagination';

import { usePaymentHistoryTableColumns } from './usePaymentHistoryTableColumns';

const PAGE_SIZE = 15;

export const PaymentHistory = () => {
  const { clientId = '' } = useParams<{ clientId?: string }>();
  const partnerId = usePartnerId();
  const [page, setPage] = useState(1);

  const { isError, isLoading, data } = useClientsPaymentHistoryQuery({
    partnerId,
    clientId,
    index: page,
    size: PAGE_SIZE,
  });

  const { t } = useTranslation();

  const columns = usePaymentHistoryTableColumns();

  const dataSource = useMemo(() => {
    return data?.paymentOrders ?? [];
  }, [data?.paymentOrders]);

  /** we don't need to show pagination if theres in now data at all */
  const showPagination = dataSource.length > 0 || page > 1;

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div>{t('common.status.failed')}</div>;
  }

  return (
    <>
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
      {showPagination && (
        <div style={{ paddingBlock: '16px' }}>
          <Pagination
            page={page}
            hasNext={dataSource.length === PAGE_SIZE}
            hasPrev={page > 1}
            onChange={(_, index) => setPage(index)}
          >
            <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
          </Pagination>
        </div>
      )}
    </>
  );
};
