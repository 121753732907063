import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchChargeTagsPartner } from '../fetches';
import { ResChargeTagsPartner } from '../types/responses';

export const USE_CHARGE_TAGS_PARTNER_QUERY_KEY = 'charge.tags.partners';

export function useChargeTagsPartnerQuery(
  args: Parameters<typeof fetchChargeTagsPartner>,
  options?: UseQueryOptions<
    ResChargeTagsPartner,
    Types.HttpError,
    ResChargeTagsPartner,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_TAGS_PARTNER_QUERY_KEY, args],
    () => fetchChargeTagsPartner(...args),
    options,
  );
}
