import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchChargePointUnbind } from '../fetches';
import { Responses } from '../types';

export function useChargePointUnbindMutation(
  options?: UseMutationOptions<
    Responses.ResChargePoint,
    Types.HttpError,
    Parameters<typeof fetchChargePointUnbind>
  >,
) {
  return useMutation((args) => fetchChargePointUnbind(...args), {
    retry: 0,
    ...options,
  });
}
