import { FC, memo, PropsWithChildren } from 'react';
import cn from 'classnames';

import s from './ModalFooter.module.css';

type Props = {
  className?: string;
  border?: boolean;
};

const ModalFooter: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  border = false,
}) => {
  return (
    <div className={cn(s.wrapper, className, { [s.border]: border })}>
      {children}
    </div>
  );
};

export default memo(ModalFooter);
