import {
  Button,
  DatePicker,
  Form,
  FormProps,
  message,
  Modal,
  Select,
} from 'antd';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useTranslation } from 'react-i18next';
import {
  GenerateReportRequest,
  useGenerateReportMutation,
} from 'features/reports';
import dayjs from 'dayjs';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { HttpError } from 'core/types';
import { reportTypeOptions } from 'pages/Reports/helpers/formOptions';
import { downloadExcelFile } from 'pages/Reports/helpers/downloadExcelFile';

import { GenerateReportFormField, GenerateReportModalProps } from './types';

const { RangePicker } = DatePicker;

export const GenerateReportModal = ({
  isOpen,
  onCancel,
}: GenerateReportModalProps) => {
  const [form] = Form.useForm();
  const partnerId = usePartnerId();

  const { mutateAsync, isLoading } = useGenerateReportMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const [messageApi, contextHolder] = message.useMessage();

  const showError = (error: HttpError) => {
    messageApi.open({
      type: 'error',
      content: errorT(error?.translationKey, error.message),
    });
  };

  const submitForm: FormProps<GenerateReportFormField>['onFinish'] = async (
    values,
  ) => {
    const data: GenerateReportRequest = {
      reportType: values.reportType,
      format: 'excel',
      from: dayjs(values.dateRange[0]).toISOString(),
      to: dayjs(values.dateRange[1]).toISOString(),
      partnerId,
    };

    try {
      const { fileId } = await mutateAsync(data);
      messageApi.success(t('reports.status.report-generated'));

      await downloadExcelFile({
        partnerId,
        fileId,
        fileName: `report-${dayjs().format('DD-MM-YYYY')}.xlsx`,
      });

      form.resetFields();
      onCancel();
    } catch (error) {
      showError(error as HttpError);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={onCancel}
        title={t('reports.titles.generate-report')}
        footer={[
          <Button key={'cancel'} onClick={onCancel}>
            {t('common.actions.cancel')}
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            htmlType={'submit'}
            form={'generate-report-form'}
            loading={isLoading}
          >
            {t('common.actions.create')}
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          id={'generate-report-form'}
          onFinish={submitForm}
        >
          <Form.Item<GenerateReportFormField>
            label={`${t('reports.labels.date-range')}:`}
            name="dateRange"
            rules={[{ required: true, message: t('reports.rules.date-range') }]}
          >
            <RangePicker
              format={'DD/MM/YYYY'}
              placeholder={[
                t('reports.placeholders.start-date'),
                t('reports.placeholders.end-date'),
              ]}
            />
          </Form.Item>
          <Form.Item<GenerateReportFormField>
            label={`${t('reports.labels.report-type')}:`}
            name="reportType"
            rules={[
              { required: true, message: t('reports.rules.report-type') },
            ]}
          >
            <Select
              placeholder={t('reports.placeholders.report-type')}
              options={reportTypeOptions.map(({ value, translateKey }) => ({
                value,
                label: t(translateKey),
              }))}
            ></Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
