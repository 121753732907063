import * as Services from 'core/services';
import { AuthHelpers } from 'features/auth';
import { Types } from 'core';

import { RegularReportTask } from '../types';

/**
 * Get all regular report tasks
 */
export async function fetchGetRegularReportTasks(partnerId: string) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<RegularReportTask[]>(
      `partners/${partnerId}/reports/scheduled`,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
