import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { ResLoadBalancingProfile } from '../types/responses';
import { fetchLoadBalancingProfile } from '../fetches';

export const USE_PARTNER_BALANCING_PROFILE_QUERY_KEY =
  'partner.balancing-profiles';

export function useLoadBalancingProfileQuery(
  args: Parameters<typeof fetchLoadBalancingProfile>,
  options?: UseQueryOptions<
    ResLoadBalancingProfile,
    Types.HttpError,
    ResLoadBalancingProfile,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_BALANCING_PROFILE_QUERY_KEY, args],
    () => fetchLoadBalancingProfile(...args),
    options,
  );
}
