import { ColumnsType } from 'antd/es/table';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import { secsToTime } from 'core/utils';
import { ConnectorType } from 'features/charge-points/types';
import { useTranslation } from 'react-i18next';

export const useConnectorTypesColumns = (): ColumnsType<Record<string, any>> => {
  const { t } = useTranslation();

  return [
    {
      title: t('dashboard.columns.connector-type'),
      dataIndex: 'connectorType',
      render: (type: ConnectorType) => <ConnectorLabel type={type} />,
    },
    {
      dataIndex: 'count',
      title: t('dashboard.columns.sessions-count'),
    },
    {
      title: t('dashboard.columns.payments-received'),
      dataIndex: 'amountEur',
    },
    {
      title: t('dashboard.columns.max-time'),
      dataIndex: 'maxTimeSec',
      render: (v: number) => secsToTime(v),
    },
    {
      title: t('dashboard.columns.min-time'),
      dataIndex: 'minTimeSec',
      render: (v: number) => secsToTime(v),
    },
    {
      title: t('dashboard.columns.total-time'),
      dataIndex: 'totalTimeSec',
      render: (v: number) => secsToTime(v),
    },
    {
      title: t('dashboard.columns.max-kwh'),
      dataIndex: 'maxKwh',
      key: 'maxKwh',
    },
    {
      title: t('dashboard.columns.min-kwh'),
      dataIndex: 'minKwh',
      key: 'minKwh',
    },
    {
      title: t('dashboard.columns.total-kwh'),
      dataIndex: 'totalKwh',
    },
  ];
};
