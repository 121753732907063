import { BasePartner } from 'features/partners/types';
import { useTranslation } from 'react-i18next';

import { Description } from './Description';

export const ContactsDescription = ({
  data,
  actions,
}: {
  data: BasePartner['contacts'];
  actions?: JSX.Element;
}) => {
  const { t } = useTranslation();
  return (
    <Description
      title={t("profile.general.contact")}
      data={[
        {
          label: t('profile.general.email'),
          value: data?.email?.contact ?? '-',
        },
        {
          label: t('profile.general.phone'),
          value: data?.phone?.contact ?? '-',
        },
      ]}
      actions={actions}
    />
  );
};
