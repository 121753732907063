import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchChargePoint } from '../fetches';
import { Requests, Responses } from '../types';

export const USE_CHARGE_POINT_QUERY_KEY = 'charge.point';

export function useChargePoint(
  partnerId: string,
  chargePointId: string,
  params?: Requests.ReqChargePointsPartnerItemsParams,
  options?: UseQueryOptions<
    Responses.ResChargePoint,
    Types.HttpError,
    Responses.ResChargePoint,
    any[]
  >,
) {
  return useQuery(
    [USE_CHARGE_POINT_QUERY_KEY, chargePointId],
    () => fetchChargePoint(partnerId, chargePointId, params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
