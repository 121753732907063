import { memo } from 'react';
import { Restriction } from 'features/partners-tariffs-groups/types';
import { hasValue } from 'core/utils/typeguards';
import { useTranslation } from 'react-i18next';
import { connectorsLabels } from 'components/view/ConnectorLabel/connectors-labels.const';
import { formatDateTimeShort } from 'core/utils';

import s from './RestrictionView.module.css';

const RestrictionView = (props: { restriction: Restriction }) => {
  const { t } = useTranslation();

  const { restriction } = props;
  const {
    connectorTypes,
    daysOfWeek,
    endDate,
    maxCurrent,
    maxDuration,
    maxKwh,
    maxPower,
    minCurrent,
    minDuration,
    minKwh,
    minPower,
    reservationType,
    startDate,
    time,
    cpHashTags,
    tagHashTags,
  } = restriction;
  return (
    <dl className={s.restriction}>
      {connectorTypes && connectorTypes.length > 0 && (
        <>
          <dt>{t('pricing.terms.connectors')}</dt>
          <dd>{connectorTypes.map((t) => connectorsLabels[t]).join(', ')}</dd>
        </>
      )}

      {(startDate || endDate) && (
        <>
          <dt>{t('pricing.terms.dates')}</dt>
          <dd>
            {startDate ? formatDateTimeShort(startDate) : '?'}
            {' - '}
            {endDate ? formatDateTimeShort(endDate) : '?'}
          </dd>
        </>
      )}

      {daysOfWeek && daysOfWeek.length > 0 && (
        <>
          <dt>{t('pricing.terms.week-days')}</dt>
          <dd>{daysOfWeek.join(', ')}</dd>
        </>
      )}

      {(time?.from || time?.to) && (
        <>
          <dt>{t('pricing.terms.time')}</dt>
          <dd>
            {time.from}-{time.to}
          </dd>
        </>
      )}

      {(hasValue(minCurrent) || hasValue(maxCurrent)) && (
        <>
          <dt>{t('pricing.terms.current')}</dt>
          <dd>
            {minCurrent}-{maxCurrent}
          </dd>
        </>
      )}

      {(hasValue(minPower) || hasValue(maxPower)) && (
        <>
          <dt>{t('pricing.terms.power')}</dt>
          <dd>
            {minPower}-{maxPower}
          </dd>
        </>
      )}

      {(hasValue(minKwh) || hasValue(maxKwh)) && (
        <>
          <dt>{t('pricing.terms.kwh')}</dt>
          <dd>
            {minKwh}-{maxKwh}
          </dd>
        </>
      )}

      {(hasValue(minDuration) || hasValue(maxDuration)) && (
        <>
          <dt>{t('pricing.terms.duration')}</dt>
          <dd>
            {minDuration}-{maxDuration}
          </dd>
        </>
      )}

      {hasValue(reservationType) && (
        <>
          <dt>{t('pricing.terms.reservation')}</dt>
          <dd>{reservationType}</dd>
        </>
      )}

      {cpHashTags && cpHashTags.length > 0 && (
        <>
          <dt>{t('pricing.terms.cp-groups')}</dt>
          <dd>{cpHashTags.join(', ')}</dd>
        </>
      )}

      {tagHashTags && tagHashTags.length > 0 && (
        <>
          <dt>{t('pricing.terms.tag-groups')}</dt>
          <dd>{tagHashTags.join(', ')}</dd>
        </>
      )}
    </dl>
  );
};

export default memo(RestrictionView);
