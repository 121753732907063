import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupDelete } from '../fetches';
import { ResPartnerTariffGroupDelete } from '../types/responses';

export function usePartnerTariffGroupDeleteMutation(
  options?: UseMutationOptions<
    ResPartnerTariffGroupDelete,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupDelete>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupDelete(...args), {
    retry: 0,
    ...options,
  });
}
