import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerTariffGroupDetails } from 'views/PartnerTariffGroup';
import { useIsSubpartner } from 'core/providers/PartnerProvider';

import { ChargePointTariffUpdateForm } from '../Forms/ChargePointTariffUpdateForm';
import { useChargePointsDetailsContext } from '../ChargePointsDetailsContext';

export function ChargePointTariff() {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { chargePoint, isExternalResource } = useChargePointsDetailsContext();
  const isSubpartner = useIsSubpartner();

  const { t } = useTranslation();

  return (
    <div>
      <PartnerTariffGroupDetails
        partnerId={chargePoint.partner.id}
        tariffGroupId={chargePoint.tariffGroupId}
        rightAction={
          <Button
            type={'primary'}
            disabled={isExternalResource || isSubpartner}
            onClick={() => setIsOpenUpdate(true)}
          >
            {t('common.actions.update')}
          </Button>
        }
        showDetails={false}
      />
      {!isSubpartner && (
        <ChargePointTariffUpdateForm
          isOpen={isOpenUpdate}
          chargePoint={chargePoint}
          onCancel={() => setIsOpenUpdate(false)}
          onSuccess={() => setIsOpenUpdate(false)}
        />
      )}
    </div>
  );
}
