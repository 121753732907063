import { PropsWithChildren } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, message } from 'antd';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import {
  USE_CHARGE_TAGS_PARTNER_QUERY_KEY,
  useChargeTagAssignClientMutation,
  useChargeTagUnassignClientMutation,
} from 'features/charge-tags/queries';
import { HttpError } from 'core/types';

import styles from './TagsAssignClient.module.css';
import { TagsAssignClientProps } from './types';

export function TagsAssignClient(
  props: PropsWithChildren<TagsAssignClientProps>,
) {
  const opened = !!props.tagId;

  const { t } = useTranslation();

  return (
    <Modal
      title={t('rfid_tags.titles.assign-client')}
      open={opened}
      onCancel={props.onClose}
      footer={null}
      destroyOnClose
    >
      <AssignClientForm {...props} />
    </Modal>
  );
}

function AssignClientForm({
  tagId,
  onClose,
}: PropsWithChildren<TagsAssignClientProps>) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const partnerId = usePartnerId();

  const assignClient = useChargeTagAssignClientMutation();
  const unassignClient = useChargeTagUnassignClientMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const assign = (values: { clientPhone: string }) => {
    if (!tagId) {
      return;
    }

    const { clientPhone } = values;

    assignClient
      .mutateAsync({
        partnerId,
        tagId,
        clientPhone,
      })
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_TAGS_PARTNER_QUERY_KEY]);
        onClose();
      })
      .catch((e: HttpError) => {
        message.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      });
  };

  const unassign = () => {
    if (!tagId) {
      return;
    }

    unassignClient
      .mutateAsync({ partnerId, tagId })
      .then(() => {
        queryClient.invalidateQueries([USE_CHARGE_TAGS_PARTNER_QUERY_KEY]);
        onClose();
      })
      .catch((e: HttpError) => {
        message.open({
          type: 'error',
          content: errorT(e?.translationKey, e.message),
        });
      });
  };

  return (
    <div className={styles.assign_form_content}>
      <Form form={form} layout={'vertical'} onFinish={assign}>
        <Form.Item
          name="clientPhone"
          label={t('rfid_tags.labels.client-phone')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item className={styles.assign_form_buttons}>
          <Button
            type="primary"
            htmlType="submit"
            loading={assignClient.isLoading}
          >
            {t('rfid_tags.actions.assign-client')}
          </Button>
        </Form.Item>
        <Form.Item className={styles.assign_form_buttons}>
          <Button
            type="link"
            loading={unassignClient.isLoading}
            onClick={unassign}
            size="small"
          >
            {t('rfid_tags.actions.delete-assignment')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
