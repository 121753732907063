import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const handleCopy = (content: string) => {
  try {
    navigator.clipboard.writeText(content);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

export const useChargePointTransactionTableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('chargepoints.columns.created-at'),
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: t('chargepoints.columns.connector-id'),
      key: 'connectorId',
      dataIndex: 'connectorId',
    },
    {
      title: t('chargepoints.columns.event'),
      key: 'event',
      dataIndex: 'event',
    },
    {
      title: t('chargepoints.columns.event-code'),
      key: 'eventCode',
      dataIndex: 'eventCode',
    },
    {
      title: t('chargepoints.columns.body'),
      key: 'body',
      dataIndex: 'body',
      render: (content: any) => {
        if (!content) {
          return '-';
        }
        const body = JSON.stringify(content, null, 2);
        return (
          <Button title={body} onClick={() => handleCopy(body)}>
            ...
          </Button>
        );
      },
    },
    {
      title: t('chargepoints.columns.err-code'),
      key: 'errCode',
      dataIndex: 'errCode',
    },
    {
      title: t('chargepoints.columns.err-msg'),
      key: 'errMsg',
      dataIndex: 'errMsg',
    },
    {
      title: t('chargepoints.columns.request-id'),
      key: 'requestId',
      dataIndex: 'requestId',
    },
    {
      title: t('chargepoints.columns.severity'),
      key: 'severity',
      dataIndex: 'severity',
    },
    {
      title: t('chargepoints.columns.tag-id'),
      key: 'tagId',
      dataIndex: 'tagId',
    },
    {
      title: t('chargepoints.columns.transaction-id'),
      key: 'transactionId',
      dataIndex: 'transactionId',
      render: (name: string, { id }: any) =>
        // todo without a real id, we are unable to use the link
        id ? <Link to={`transaction/${id}`}>{name}</Link> : name,
    },
    {
      title: t('chargepoints.columns.vendor-err-code'),
      key: 'vendorErrCode',
      dataIndex: 'vendorErrCode',
    },
  ];
};
