import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { ReqChargeTagActiveData } from '../types/requests';
import { ResChargeTagActivate } from '../types/responses';

export async function fetchChargeTagActivate<
  TResponse = ResChargeTagActivate,
  TError = Types.HttpError,
>(data: ReqChargeTagActiveData) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `partners/${data.partnerId}/charging/tags/${data.tagId}/activate`,
      {},
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
